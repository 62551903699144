import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { NavService } from "src/app/layout/nav/nav.service";
import { HelperService } from "src/app/service/helper.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(
    public helper: HelperService,
    public router: Router,
    private navService: NavService
  ) {}

  public linkBlog: boolean = false;

  public linksCloudService = [
    {
      name: "Posture Review (Segurança & Compliance)",
      link: "/solucoes/cloud-services/posture-review",
    },
    {
      name: "Cloud Managed Services (CMS)",
      link: "/solucoes/cloud-services/cms",
    },
    {
      name: "Suporte & Monitoramento",
      link: "/solucoes/cloud-services/suporte-monitoramento",
    },
    {
      name: "Modernização de Infra",
      link: "/solucoes/cloud-services/modernizacao-infra",
    },
    {
      name: "Data & Analytics",
      link: "/solucoes/cloud-services/data-analytics",
    },
    {
      name: "Migração",
      link: "/solucoes/cloud-services/migracao",
    },
    {
      name: "Backup & DR",
      link: "/solucoes/cloud-services/backup-dr",
    },
    {
      name: "IA & ML",
      link: "/solucoes/cloud-services/ia-ml",
    },
    {
      name: "Finops",
      link: "/solucoes/cloud-services/finops",
    },
    {
      name: "Devops & SRE",
      link: "/solucoes/cloud-services/devops-sre",
    },
  ];

  public atuacoes: any[] = [
    {
      id: 1,
      img: "./assets/icons/home/i-industria.svg",
      alt: "industria",
      titulo: "Indústria",
      link: "/verticais/industria",
    },
    {
      id: 2,
      img: "./assets/icons/home/i-logistica.svg",
      alt: "logistica",
      titulo: "Logística",
      link: "/verticais/logistica",
    },
    {
      id: 3,
      img: "./assets/icons/home/i-saude.svg",
      alt: "saude",
      titulo: "Saúde",
      link: "/verticais/saude",
    },
    {
      id: 4,
      img: "./assets/icons/home/i-tecnologia.svg",
      alt: "tecnologia",
      titulo: "Tecnologia",
      link: "/verticais/tecnologia",
    },
    {
      id: 5,
      img: "./assets/icons/home/i-varejo.svg",
      alt: "varejo",
      titulo: "Varejo",
      link: "/verticais/varejo",
    },
  ];

  public qi: any[] = [
    {
      id: 1,
      img: "./assets/images/home/img-qi1.png",
      alt: "qi1",
      titulo: "Ebooks",
    },
    {
      id: 2,
      img: "./assets/images/home/img-qi2.png",
      alt: "qi2",
      titulo: "Webinars",
    },
    {
      id: 3,
      img: "./assets/images/home/img-qi3.png",
      alt: "qi3",
      titulo: "Cases",
    },
    {
      id: 4,
      img: "./assets/images/home/img-qi4.png",
      alt: "qi4",
      titulo: "Blog",
    },
  ];

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Verifica se o scroll está no topo
    this.navService.changeMenu.emit(window.pageYOffset < 100);
  }

  ngOnInit() {
    this.navService.changeMenu.emit(true);
  }

  public solucoes(dados) {
    const imgP = document.getElementById("produtividade") as HTMLImageElement;
    const imgM = document.getElementById("modernizacao") as HTMLImageElement;
    const imgI = document.getElementById("inteligencia") as HTMLImageElement;

    switch (dados) {
      case "produtividade":
        imgP.src = "./assets/icons/home/i-produtividade-azul-home.svg";
        break;
      case "modernizacao":
        imgM.src = "./assets/icons/home/i-modernizacao-azul-home.svg";
        break;

      case "out":
        imgP.src = "./assets/icons/home/i-produtividade-branco-home.svg";
        imgM.src = "./assets/icons/home/i-modernizacao-branco-home.svg";
        break;

      default:
        break;
    }
  }

  public link(link) {
    this.helper.scroll();

    switch (link) {
      case "Ebooks":
        this.router.navigate(["/ebooks"]);
        break;
      case "Webinars":
        this.router.navigate(["/webinars"]);
        break;
      case "Cases":
        this.router.navigate(["/cases"]);
        break;
      case "Blog":
        window.open("https://blog.qinetwork.com.br/", "_blank");
        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    this.navService.changeMenu.emit(false);
  }
}
