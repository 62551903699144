import { Component, OnInit } from '@angular/core';
import { VarejoData } from './dados';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-varejo-pillar-page',
  templateUrl: './varejo-pillar-page.component.html',
  styleUrls: ['./varejo-pillar-page.component.scss']
})
export class VarejoPillarPageComponent implements OnInit {


  public topic = VarejoData;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Como preparar o negócio para a Black Friday no e-commerce');

    this.meta.updateTag({ name: 'description', content: 'A Black Friday para e-commerce é um tópico fundamental e os negócios precisam estar atentos a LGPD no varejo e as ferramentas que podem melhorar a operação.' });

    this.meta.updateTag({ property: 'og:title', content: 'Como preparar o negócio para a Black Friday no e-commerce' });
    this.meta.updateTag({ property: 'og:description', content: 'A Black Friday para e-commerce é um tópico fundamental e os negócios precisam estar atentos a LGPD no varejo e as ferramentas que podem melhorar a operação.' });


    this.meta.updateTag({ name: 'twitter:title', content: 'Como preparar o negócio para a Black Friday no e-commerce' });
    this.meta.updateTag({ name: 'twitter:description', content: 'A Black Friday para e-commerce é um tópico fundamental e os negócios precisam estar atentos a LGPD no varejo e as ferramentas que podem melhorar a operação.' });
  }

  ngOnDestroy() {
    this.title.setTitle('QI Network');
  }

}
