import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-servicos-produtividade',
  templateUrl: './servicos-produtividade.component.html',
  styleUrls: ['../servicos/servicos.component.scss']
})
export class ServicosProdutividadeComponent implements OnInit {

  public analise: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Nesta fase o Suporte irá receber a demanda e entender que é um chamado de Análise e Orientação Técnica, alocando o Gestor do Suporte para verificar o contexto do chamado e internamente ver o especialista no assunto.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Neste momento é realizada uma videoconferência gravada entre a equipe do cliente e nossa equipe de especialistas no tema.',
      descricaoP2: 'Durante o primeiro momento da reunião a equipe do cliente detalha o desafio e nossa equipe tira todas as dúvidas para que antes de iniciar a orientação, todos tenham o mesmo entendimento quanto ao desafio foco na Análise.'
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Orientação',
      descricao: 'Com o entendimento do desafio inicia-se a cocriação das orientações técnicas de alternativas de solução para os desafios.',
      descricaoP2: 'Mostramos exemplos de soluções criadas relacionadas com o desafio apresentado e fazemos a orientação técnica de alternativas de solução do desafio.'
    }
  ];
  public mapeamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Mapeamento',
      descricao: 'Apresentação do cronograma do projeto, fases do projeto e o que será entregue para o cliente ao final do projeto.',
      descricaoP2: 'Enviamos formulários de entrevistas técnicas para entendimento dos desafios da empresa.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Nosso especialista técnico conduz uma reunião para validar os dados enviados nos formulários e os desafios apresentados.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Documentação',
      descricao: 'Reunião de apresentação do documento final do mapeamento técnico onde nosso especialista entrega o laudo técnico sobre a solução do desafio.',
    }
  ];
  public treinamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Aqui definimos quem participa do treinamento, data, horário e o convite que será enviado aos participantes.',
      descricaoP2: 'Nesta fase definimos também o nível do conteúdo do treinamento, criação da apresentação do treinamento e alocação do instrutor especialista no conteúdo.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Treinamento',
      descricao: 'No dia do treinamento, nós orientamos nas etapas de organização do evento para que seja o mais produtivo possível.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Avaliação',
      descricao: 'Envio do material e certificação para os participantes. Fazemos a avaliação de aprendizagem e treinamento para garantir que houve o máximo de retenção do conteúdo.',
    }
  ];
  public design: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-a.svg',
      numero: './assets/icons/i-um-a.svg',
      texto: 'Entendimento dos Desafios',
      descricao: 'Vamos por meio de pesquisas coletar dados sobre os desafios, classificar e priorizar.',
      descricaoP2: 'Nesta etapa conduzimos uma dinâmica de duas horas onde vamos aprofundar sobre um tema escolhido, seja de segurança ou melhoria de processo.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-a.svg',
      numero: './assets/icons/i-dois-a.svg',
      texto: 'Ideação da Solução',
      descricao: 'Nesta segunda fase facilitamos uma dinâmica de grupo com o objetivo de mapear as ideias idealizadas por todos os envolvidos no desafio, assim como mapeamos os impactos que essas soluções trariam para o negócio da empresa.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-a.svg',
      numero: './assets/icons/i-tres-a.svg',
      texto: 'Validação do Escopo Técnico e de Negócios',
      descricao: 'Nesta fase usamos todas as informações coletadas na ideação da solução e o protótipo sugerido, de forma a esboçar um resumo do processo de construção da solução.',
    },
    {
      id: 4,
      imagem:'./assets/icons/i-check-a.svg',
      numero: './assets/icons/i-quatro-a.svg',
      texto: 'Planejamento da Execução | SOW',
      descricao: 'A fase final do processo é produzido um documento ( SOW ) e proposição de todos os itens do processo. Aqui é apresentado o protótipo criado com uma dinâmica de validação.',
    }
  ];
  public solution: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Desenvolvimento',
      descricao: 'Na fase de Desenvolvimento é quando a solução ganha vida e tudo o que havia sido planejado na Fase anterior de Design de Solução é executado pelos especialistas.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Testes',
      descricao: 'Nesta fase de Testes, o Analista de Testes realiza um teste geral na solução desenvolvida, regras de negócios e funcionalidades previstas no SOW. Após os testes e correções, o Product Owner válida a solução aprova a publicação da solução no ambiente de produção.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Nesta última fase do processo, o Gerente de Projetos junto com o Product Owner, apresentam para o Cliente, a solução desenvolvida e formaliza a entrega da solução.',
    }
  ];
  public implantacao: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Preparação',
      descricao: 'Nesta etapa ambos os times Qi Network e Cliente se alinham para preparar o ambiente para executar a implementação. Nesta fase os times se conhecem através da reunião de Kick-off interna e com o cliente.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Implementação',
      descricao: 'Nesta etapa será executada a implementação das configurações e requisitos de negócio pré-definidas e conferidas por ambas as empresas.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Nesta etapa é realizado a homologação por parte do cliente quanto a todos os entregáveis que foram pré-acordados no plano de trabalho.',
    }
  ];
  public adocao: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Preparação',
      descricao: 'Realizamos o kick-off do projeto para apresentação dos times, do acordo de trabalho e preparação dos pré-requisitos para a execução do projeto.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Comunicação',
      descricao: 'Iniciamos o plano de comunicação com o objetivo de deixar claro para todos os envolvidos o propósito do projeto de adoção da solução, seus benefícios e a agenda de treinamentos.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Formação',
      descricao: 'Nesta etapa transferimos conhecimento sobre o uso da solução através de treinamentos, suporte a dúvidas e apoio local através dos agentes de adoção voluntários.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Monitoramento',
      descricao: 'Realizamos o monitoramento das avaliações de aprendizagens sobre a adoção da solução e resultados que o ganho da adoção trouxe para a empresa.',
    }
  ];
  public care: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-verde.svg',
      numero: './assets/icons/i-um-verde.svg',
      texto: 'Avaliação',
      descricao: 'Nesta etapa recebemos a solicitação do cliente e avaliamos a sua classificação para darmos encaminhamento para o seu atendimento..',
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-verde.svg',
      numero: './assets/icons/i-dois-verde.svg',
      texto: 'Atendimento',
      descricao: 'Executamos o atendimento de suporte técnico conforme a sua classificação e acordo de SLA. Neste atendimento poderá ser simplesmente uma resposta por e-mail como dependendo do tipo de necessidade poderá ser entrado em contato com o cliente para apoiá-lo junto ao problema relatado ou dúvida técnica levantada.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-verde.svg',
      numero: './assets/icons/i-tres-verde.svg',
      texto: 'Monitoramento',
      descricao: 'O monitoramento da solução cloud é realizado através de NOC da Qi Network. O monitoramento é proativo e através dos alertas pré parametrizados conseguimos acompanhar em tempo real a disponibilidade das soluções cloud.',
    }
  ];

  public tiposAnalise: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Implementação do Google Workspace',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Migração de Emails',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Migração de Agenda e Contatos',
    },
    {
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Migração de Documentos e Arquivos',
    },
    {
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Implementação de Políticas de Segurança',
    },
    {
      img: './assets/icons/i-seis-v.svg',
      texto: 'Integração LDAP',
    },
    {
      img: './assets/icons/i-sete-v.svg',
      texto: 'Campanha de Engajamento de Adoção',
    },
    {
      img: './assets/icons/i-oito-v.svg',
      texto: 'Projeto de Adoção de Solução',
    },
    {
      img: './assets/icons/i-nove-v.svg',
      texto: 'Otimização do fluxo de Trabalho',
    },
    {
      img: './assets/icons/i-dez-v.svg',
      texto: 'Automatização de Processos',
    },
    {
      img: './assets/icons/i-onze-v.svg',
      texto: 'Desenvolvimento de Solução de Produtividade',
    }
  ];
  public tiposMapeamento: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Prova de Conceito',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Segurança e Compliance',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Integração de Soluções',
    },
    {
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Comunicação e Colaboração',
    },
    {
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Prototipação de Soluções',
    },
    {
      img: './assets/icons/i-seis-v.svg',
      texto: 'Projeto de Adoção de Solução',
    },
    {
      img: './assets/icons/i-sete-v.svg',
      texto: 'Implementação do Google workspace',
    },
    {
      img: './assets/icons/i-oito-v.svg',
      texto: 'Campanha de Engajamento de Adoção',
    },
    {
      img: './assets/icons/i-nove-v.svg',
      texto: 'Implementação de Políticas de Segurança',
    },
    {
      img: './assets/icons/i-dez-v.svg',
      texto: 'Integração LDAP',
    },
    {
      img: './assets/icons/i-onze-v.svg',
      texto: 'Fluxo de Trabalho',
    },
    {
      img: './assets/icons/i-doze-v.svg',
      texto: 'Migração de E-mails',
    },
    {
      img: './assets/icons/i-treze-v.svg',
      texto: 'Automatização de Processos',
    },
    {
      img: './assets/icons/i-quatorze-v.svg',
      texto: 'Migração de Documentos e Arquivos',
    },
    {
      img: './assets/icons/i-quinze-v.svg',
      texto: 'Desenvolvimento de Solução de Produtividade',
    }
  ];
  public tiposTreinamento: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Comunicação Básica',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Comunicação Avançada',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Colaboração Básica',
    },
    {
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Colaboração Avançada',
    },
    {
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Painel de Controle',
    },
    {
      img: './assets/icons/i-seis-v.svg',
      texto: 'Segurança e Vault',
    },
    {
      img: './assets/icons/i-sete-v.svg',
      texto: 'Google Planilhas Básico',
    },
    {
      img: './assets/icons/i-oito-v.svg',
      texto: 'Google Planilhas Intermediário',
    },
    {
      img: './assets/icons/i-nove-v.svg',
      texto: 'Google Script Básico',
    },
    {
      img: './assets/icons/i-dez-v.svg',
      texto: 'Google Script Avançado',
    }
  ];
  public tiposDesign: any[] = [
    {
      img: './assets/icons/i-um-a.svg',
      texto: 'Migração de E-mails',
    },
    {
      img: './assets/icons/i-dois-a.svg',
      texto: 'Implementação do Google workspace',
    },
    {
      img: './assets/icons/i-tres-a.svg',
      texto: 'Implementação de Políticas de Segurança, Compliance e MDM',
    },
    {
      img: './assets/icons/i-quatro-a.svg',
      texto: 'Integração LDAP',
    },
    {
      img: './assets/icons/i-cinco-a.svg',
      texto: 'Migração de Documentos e Arquivos',
    },
    {
      img: './assets/icons/i-seis-a.svg',
      texto: 'Projeto de Adoção de Solução',
    },
    {
      img: './assets/icons/i-sete-a.svg',
      texto: 'Fluxo de Trabalho',
    },
    {
      img: './assets/icons/i-oito-a.svg',
      texto: 'Automatização de Processos',
    },
    {
      img: './assets/icons/i-nove-a.svg',
      texto: 'Desenvolvimento de Solução de Produtividade',
    },
    {
      img: './assets/icons/i-dez-a.svg',
      texto: 'Campanha de Engajamento de Adoção',
    }
  ];
  public tiposSolution: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Automatização de Processo',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Solução de Produtividade & Colaboração',
    },
    {
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Migração de Dados On-Premises para o GCP',
    }
  ];
  public tiposImplantacao: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Implementação do Google Workspace',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Implementação de Políticas de Segurança, Compliance e MDM',
    },
    {
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Integração LDAP',
    },
    {
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Fluxo de Trabalho',
    },
    {
      img: './assets/icons/i-cinco-azul.svg',
      texto: 'Automatização de Processo',
    },
    {
      img: './assets/icons/i-seis-azul.svg',
      texto: 'Implementação de Solução desenvolvida pela Qi',
    }
  ];
  public tiposAdocao: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Projeto de Adoção das Soluções Google Workspace',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Projeto de Adoção das Soluções Desenvolvidas pela Qi',
    },
    {
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Campanha de Engajamento das Soluções desenvolvidas pela Qi',
    },
    {
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Campanha de Engajamento do Google Workspace',
    }
  ];

  public tiposCloud: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Automatização de Processo',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Solução de Produtividade e Colaboração',
    }
  ];

  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public titleMeta: Title
  ) { }
  
  public tiposModal;
  public title;

  public activeAnalise: any = {};
  public activeDesign: any = {};
  public activeSolution: any = {};
  jornadaConfig = {
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: ``,
          prevArrow: ``,       
          dots: true,   
          autoplay: true,
        }
      }
    ]
  };

  public Slide(slide: any) {
    console.log(slide);
    this.activeAnalise = slide;
    this.activeDesign = slide;
    this.activeSolution = slide;
  }
  ngOnInit() {
    this.activeAnalise = this.analise[0];
    this.activeDesign = this.design[0];
    this.activeSolution = this.solution[0];
    this.helper.hasNav = true;
    this.helper.pagina = 'novos';

    // this.titleMeta.setTitle('Qi Network - Serviços de Jornada de Produtividade e Colaboração');
    // this.meta.updateTag({ name: 'description', content: 'Evolua Processos e Garanta a Segurança da Informação.' });
    // this.meta.updateTag({ name: 'keywords', content: 'qinetwork, lgdp, google cloud, etapas, qi cloud, produtividade'});
    // this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Serviços de Jornada de Produtividade e Colaboração' });
  }
  
  slickInit(e) { console.log('slick initialized'); }
  breakpoint(e) { console.log('breakpoint'); }
  afterChange(e) {
      this.activeAnalise = this.analise[e.currentSlide];
      this.activeDesign = this.design[e.currentSlide];
      this.activeSolution = this.solution[e.currentSlide];
  }
  beforeChange(e) {}

  public openModal(tipo) {
    const h3 = document.getElementById('exampleModalLabel') as HTMLBodyElement;
      switch (tipo) {
        case 'analise':
          this.tiposModal = this.tiposAnalise;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Análise e Orientação Técnica'
          break;
        case 'mapeamento':
            this.tiposModal = this.tiposMapeamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Mapeamento Técnico'
          break;
        case 'treinamento':
            this.tiposModal = this.tiposTreinamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Treinamento Técnico'
          break;
        case 'design':
            this.tiposModal = this.tiposDesign;
          h3.style.color = '#D5A629';
          this.title = 'Tipos de Design de soluções'
          break;
        case 'solution':
            this.tiposModal = this.tiposSolution;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Desenvolvimento de Solução'
          break;
        case 'implementacao':
            this.tiposModal = this.tiposImplantacao;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Implementação de Solução'
          break;
        case 'adocao':
            this.tiposModal = this.tiposAdocao;
            h3.style.color = '#3F9CEF';
            this.title = 'Adoção de Solução'
          break;
      
        default:
          break;
      }
    }
}
