import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-logistica',
  templateUrl: './logistica.component.html',
  styleUrls: ['../verticais.component.scss']
})
export class LogisticaComponent implements OnInit {

  constructor(public helper: HelperService) { }

  public cases: any[] = [
    {
      id:2,
      img: './assets/images/Verticais/img-cases-jamef.png',
      alt: 'jamef',
      link:'/case/21'
    },
    {
      id:3,
      img: './assets/images/Verticais/img-cliente-polyterminais.png',
      alt: 'polyterminais',
      link:'/verticais/logistica'
    }
  ];

  ngOnInit() {
  }

}
