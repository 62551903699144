import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-produtividade-colaboracao',
  templateUrl: './produtividade-colaboracao.component.html',
  styleUrls: ['../solucoes.component.scss']
})
export class ProdutividadeSegurancaComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  public solucoes: any [] = [
    {
      id: 1,
      img: './assets/images/solucoes/produtividade-colaboracao/melhores-praticas-seguranca.jpeg',
      titulo: 'Melhores Práticas e Segurança',
      descricao: 'Implementação de melhores práticas e politicas de Segurança no Workspace.',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/implementacao-melhores-praticas-politicas-seguranca-workspace'
    },
    {
      id: 1,
      img: './assets/images/solucoes/produtividade-colaboracao/migracao-de-dados.jpg',
      titulo: 'Migração de Dados',
      descricao: 'Planejamento, Execução e Garantia de Qualidade na Transição para um Ambiente Digital Integrado.',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/migracao-dados-google-workspace'
    },
    {
      id: 2,
      img: './assets/images/solucoes/produtividade-colaboracao/servicos-especializados.jpg',
      titulo: 'Serviços Especializados',
      descricao: 'Esteja sempre por dentro das melhores práticas do uso do Google Workspace',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/servicos-especializados-google-workspace'
    },
    {
      id: 3,
      img: './assets/images/solucoes/produtividade-colaboracao/suporte-tecnico.jpg',
      titulo: 'Suporte Técnico',
      descricao: 'Segurança, Eficiência e Conformidade para Maximizar o Potencial da Sua Plataforma de Colaboração',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/suporte-tecnico-google-workspace'
    },
    {
      id: 1,
      img: './assets/images/solucoes/produtividade-colaboracao/meu-drive.jpeg',
      titulo: 'Meu Drive/ Drive compartilhado',
      descricao: 'Implementação do Meu drive/ Drive compartilhado',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/implementacao-meu-drive-drive-compartilhado'
    },
    {
      id: 4,
      img: './assets/images/solucoes/produtividade-colaboracao/assinatura-email.jpg',
      titulo: 'Gestor de Assinaturas de Email',
      descricao: 'Automatização no controle de assinaturas',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/gestor-assinaturas-email'
    },
    {
      id: 5,
      img: './assets/images/solucoes/produtividade-colaboracao/enterprise-upgrade.jpg',
      titulo: 'Chrome Enterprise Upgrade',
      descricao: 'Segurança e Eficiência para Empresas',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/chrome-enterprise-upgrade'
    },
    {
      id: 6,
      img: './assets/images/solucoes/produtividade-colaboracao/implementacao-solucao.jpg',
      titulo: 'Implementação de Solução',
      descricao: 'Planejamento e Execução Eficaz para um Ambiente de Produção Bem-Sucedido',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/implementacao-de-solucao'
    },
    {
      id: 7,
      img: './assets/images/solucoes/produtividade-colaboracao/google-meet.jpg',
      titulo: 'Google Meet Hardware',
      descricao: 'Licença e Integração para Videoconferências Eficientes',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/google-meet-hardware'
    },
    {
      id: 8,
      img: './assets/images/solucoes/produtividade-colaboracao/app-sheet.jpg',
      titulo: 'Google AppSheet',
      descricao: 'Acelerando a Produtividade com Integrações Profundas e Sem Código',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/google-appsheet'
    },
    {
      id: 9,
      img: './assets/images/solucoes/produtividade-colaboracao/chrome-os.jpg',
      titulo: 'Chrome OS Flex',
      descricao: 'A Evolução do Sistema Operacional para Macs e PCs',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/chrome-os-flex'
    },
    {
      id: 10,
      img: './assets/images/solucoes/produtividade-colaboracao/gemini.jpg',
      titulo: 'Gemini para Google Workspace',
      descricao: 'Revolucionando a Colaboração e Produtividade',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/gemini-google-workspace'
    },
    {
      id: 11,
      img: './assets/images/solucoes/produtividade-colaboracao/campanha-engajamento.jpg',
      titulo: 'Campanha de Engajamento',
      descricao: 'Estruturando o Compromisso dos Colaboradores',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/campanha-engajamento-google-workspace'
    },
    {
      id: 12,
      img: './assets/images/solucoes/produtividade-colaboracao/adocao-google-workspace.jpg',
      titulo: 'Adoção Google Workspace',
      descricao: 'Aumente o Engajamento e a Adoção das Soluções',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/adocao-google-workspace'
    },
    {
      id: 13,
      img: './assets/images/solucoes/produtividade-colaboracao/google-workspace.jpg',
      titulo: 'Google Workspace',
      descricao: 'As melhores ferramentas de colaboração e produtividade para sua empresa',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/google-workspace'
    },
    {
      id: 14,
      img: './assets/images/solucoes/produtividade-colaboracao/google-voice.jpg',
      titulo: 'Google Voice',
      descricao: 'A Solução Inovadora para Comunicações Empresariais Eficientes e Seguras',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/google-voice'
    },
    {
      id: 15,
      img: './assets/images/solucoes/produtividade-colaboracao/qi-academy.jpg',
      titulo: 'Qi Academy',
      descricao: 'Plataforma de ensino para colaboradores aprenderem como utilizar os aplicativos do Google Workspace.',
      icone: './assets/icons/solucoes/i-seta-solucoes-roxo.svg',
      link: '/solucoes/produtividade-colaboracao/qi-academy'
    },
  ];

  public clientes: any[] = [
    {
      id: 1,
      solucoes: 'infra',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-roxo.svg',
      link: ''
    },
    {
      id: 2,
      solucoes: 'infra',
      logo: './assets/logos/institucional/metropoles.svg',
      alt: 'metropoles',
      tipo: 'Video',
      descricao: 'Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud',
      tempo: '2:52 min',
      icone: './assets/icons/institucional/i-play-roxo.svg',
      link: ''
    },
    {
      id: 3,
      solucoes: 'dados',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-roxo.svg',
      link: ''
    }
  ];

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;
    this.helper.ajudaAzul = true;

  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
