import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-industria',
  templateUrl: './industria.component.html',
  styleUrls: ['../verticais.component.scss']
})
export class IndustriaComponent implements OnInit {

  constructor(public helper: HelperService) { }

  public cases: any[] = [
    {
      id:1,
      img: './assets/images/Verticais/img-cases-seculus.png',
      alt: 'seculus',
      link:'/case/8'
    },
    {
      id:2,
      img: './assets/images/Verticais/img-cliente-condor.png',
      alt: 'condor',
      link:'/verticais/industria'
    },
    {
      id:3,
      img: './assets/images/Verticais/img-cliente-aperam.png',
      alt: 'aperam',
      link:'/verticais/industria'
    }
  ];

  ngOnInit() {
  }

}
