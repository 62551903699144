import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/service/helper.service";
import Data from "./contents/data";
@Component({
  selector: "app-data-analytics",
  templateUrl: "./data-analytics.component.html",
  styleUrls: ["./data-analytics.component.scss"],
})
export class DataAnalyticsComponent implements OnInit {
  constructor(public helper: HelperService, private router: ActivatedRoute) {}
  page!: SolutionInterface;

  ngOnInit() {
    this.helper.jInfra = true;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;

    this.router.params.subscribe((res) => {
      this.page = Data[res.id];
    });
  }
}

export interface SolutionInterface {
  img: string;
  imgMobile: string;
  produto: string;
  titulo: string;
  subTitulo: string;
  cards: {
    titulo: string;
    conteudo: string[];
  };
  destaque: {
    titulo: string;
    topicos: string[];
  };
}
