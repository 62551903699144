import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-tecnologia',
  templateUrl: './tecnologia.component.html',
  styleUrls: ['../verticais.component.scss']
})
export class TecnologiaComponent implements OnInit {

  constructor(public helper: HelperService) { }

  public cases: any[] = [
    {
      id:1,
      img: './assets/images/Verticais/img-cases-autoavaliar.png',
      alt: 'autoavaliar',
      link: '/case/22'
    },
    {
      id:2,
      img: './assets/images/Verticais/img-cases-resultados-digitais.png',
      alt: 'resultados-digitais',
      link: '/case/9'
    },
    {
      id:3,
      img: './assets/images/Verticais/img-cases-microwork.png',
      alt: 'microwork',
      link: '/case/20'
    }
  ];

  ngOnInit() {
  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth", block: "start", inline: "end"});
  }

}
