import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-webinars-workspace',
  templateUrl: './webinars-workspace.component.html',
  styleUrls: ['../webinars/webinars.component.scss']
})
export class WebinarsWorkspaceComponent implements OnInit {

  constructor(public helper: HelperService) { }

  ngOnInit() {
  }

}
