import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-jornada-modernizacao',
  templateUrl: './jornada-modernizacao.component.html',
  styleUrls: ['./jornada-modernizacao.component.scss']
})
export class JornadaModernizacaoComponent implements OnInit {
  public titulo = true;

  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public title: Title
  ) { }

  ngOnInit() {
    this.helper.hasNav = true;

    if (this.helper.isMobile === true) {
      this.titulo = false;
    }
    this.helper.pagina = 'modernizacao';
    
    this.title.setTitle('Qi Network - Jornada de Modernização de Infra');
    this.meta.updateTag({ name: 'description', content: 'A jornada tem como propósito facilitar a migração, modernização e sustentação da infra das empresas na nuvem do Google Cloud' });
    this.meta.updateTag({ name: 'keywords', content: 'qinetwork, modernização, google cloud, modernização, suporte tecnico, qi cloud'});
    this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Jornada de Modernização de Infra' });
  }

}
