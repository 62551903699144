import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-programa-inovacao',
  templateUrl: './programa-inovacao.component.html',
  styleUrls: ['./programa-inovacao.component.scss']
})
export class ProgramaInovacaoComponent implements OnInit {

  constructor(
    public helper: HelperService,
    public meta: Meta,
    public title: Title,
    private el: ElementRef,
  ) { }

  ngOnInit() {
    this.helper.hasNav = true;
    this.title.setTitle('Qi Network - Programa de Inovação');
    this.meta.updateTag({ name: 'description', content: 'Criamos em parceria com o Google Cloud o programa que apoia sua empresa na jornada contínua de Inovação na Nuvem!' });
    this.meta.updateTag({ name: 'keywords', content: 'qinetwork, inovação, google cloud, modernização, inteligência, produtividade'});
    this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Programa de Inovação' });
  }

  public smooth(teste?) {

    document.getElementById(teste).scrollIntoView({behavior:"smooth"});

  }

  scroll(item:string) {
        let el: HTMLElement
        el = this.el.nativeElement.querySelector('#'+item);
        el.scrollIntoView({ block: 'center',  behavior: 'smooth' });
  }

}
