import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-migracao-nuvem',
  templateUrl: './migracao-nuvem.component.html',
  styleUrls: ['./migracao-nuvem.component.scss']
})
export class MigracaoNuvemComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {

    this.helper.jInfra = true;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;

  }

}
