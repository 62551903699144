import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  constructor() { }

  public clientes: any[] = [
    {
      id: 1,
      img: './assets/images/clientes/carrefour.png',
      alt:'carrefour'
    },
    {
      id: 2,
      img: './assets/images/clientes/araujo.png',
      alt:'araujo'
    },
    {
      id: 3,
      img: './assets/images/clientes/endeavor.png',
      alt:'endeavor'
    },
    {
      id: 4,
      img: './assets/images/clientes/sympla.png',
      alt:'sympla'
    },
    {
      id: 5,
      img: './assets/images/clientes/hotmart.png',
      alt:'hotmart'
    },
    {
      id: 6,
      img: './assets/images/clientes/rdstation.png',
      alt:'rdstation'
    }
  ];

  ngOnInit() {
  }

}
