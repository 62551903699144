import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router, public helper: HelperService) { }

  ngOnInit() { }

  public send() {
    this.router.navigate(['/obrigado']);
  }
  public smooth(id?) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }
  
}
