const data = {
  "data-analytics": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Data & Analytics",
    titulo: "Transforme seu negócio com a Engenharia de Dados",
    subTitulo:
      "Extraia insights valiosos dos seus dados com soluções avançadas de análise.",
    cards: {
      titulo: "Descubra o potencial dos dados para impulsionar sua empresa!",
      conteudo: [
        "<p>No <strong>mundo movido por dados</strong>, a chave para o sucesso está na habilidade de interpretar informações valiosas. <strong>Personalizamos a solução para seu desafio:</strong> Cada empresa enfrenta desafios únicos, e é por isso que oferecemos soluções totalmente personalizadas.&nbsp;</p> <p>Seja para <strong>otimizar operações, compreender o comportamento do cliente ou antecipar tendências de mercado</strong>, nossos especialistas em Engenharia de Dados desenvolvem estratégias sob medida para atender às suas necessidades específicas. </p>",
        "<p>Na busca por vantagens competitivas, <strong>nosso serviço de engenharia de dados</strong> é a solução para alcançar o sucesso. Não apenas oferecemos insights, <strong>oferecemos uma visão transformadora</strong> que redefine a forma como sua empresa utiliza os dados.&nbsp;</p> <p>Seja proativo na <strong>gestão e interpretação dos seus recursos mais valiosos</strong>. Entre em contato conosco hoje e descubra como transformar dados em oportunidades reais. Sua jornada para <strong>obter os melhores insights por meios de dados começa aqui</strong>!</p>",
        "<p><strong>Oferecemos uma ampla gama de serviços de Engenharia de Dados:</strong></p> <p>Migração de Dados / Gestão de Dados / Administração de Bancos de Dados / Relatórios / Dashboards / BI / Fundação de Dados / Coleta, Armazenamento, Processamento e Orquestração de Fluxos de Trabalho / Transformação de Dados / Segurança / Privacidade / Análise / Monitoramento / Desempenho / Colaboração / Comunicação</p>",
      ],
    },
  },
  "ia-ml": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "IA & ML",
    titulo: "Revolucione seu Negócio com IA & ML",
    subTitulo:
      "Potencialize suas operações com a inteligência artificial e machine learning.",
    cards: {
      titulo: "Descubra o Potencial Ilimitado da Inovação Digital!",
      conteudo: [
        "<p><strong>Transformamos desafios de negócios em soluções inovadoras com nossos serviços de Inteligência Artificial.</strong></p> <p>Seja através da <strong>inteligência artificial, generative AI e/ou machine learning</strong>, nós te ajudamos a descobrir o potencial ilimitado da inteligência artificial e do aprendizado de máquina com a nossa expertise nesse tipo de solução combinada com análise criteriosa do negócio!</p> <p>Na era digital em constante evolução, a inovação é a chave para o sucesso.</p>",
        "<p><strong>Apresentamos soluções personalizadas em IA</strong><span> que transformam dados em insights valiosos, impulsionando o crescimento e a eficiência dos seus negócios.&nbsp;</span></p> <p><span>A IA não é o futuro dos negócios é o presente. Nossa equipe de especialistas está pronta para guiá-lo através da jornada de implementação, destacando como algoritmos avançados podem </span><strong>aprimorar processos, automatizar tarefas e fornecer uma vantagem competitiva incomparável</strong><span>.</span></p>",
        "<p><span>Seja na </span><strong>busca por insights mais profundos</strong><span>, na automação de processos ou na tomada de decisões estratégicas, IA e Machine Learning são a </span><strong>chave para desbloquear o potencial do seu negócio</strong><span>.</span></p> <p><strong>Aqui na Qi, não apenas acompanhamos as tendências, nós as definimos.</strong><span> Junte-se a nós e embarque na jornada rumo a um futuro mais inteligente e eficiente. Transforme dados em oportunidades. </span><strong>Transforme sua visão em realidade.</strong><span> Entre em contato conosco hoje e descubra o que a inteligência artificial pode fazer por você!</span></p>",
      ],
    },
  },
  migracao: {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Migração",
    titulo: "Migre para a Nuvem e Alcance Novos Patamares",
    subTitulo: "Migre seus sistemas para a nuvem de forma segura e eficiente.",
    cards: {
      titulo: "Redefina a Eficiência e a Inovação no Mundo Digital!",
      conteudo: [
        "<p>No mundo acelerado dos negócios digitais, <strong>a migração para a nuvem é a jornada que redefine a eficiência e a inovação</strong>.&nbsp;</p> <p>Aqui na Qi Network, nossa missão é levar seu negócio para nuvem aproveitando na totalidade os <strong>principais benefícios que a nuvem pode te proporcionar</strong>, trabalhando sempre com esses pilares: <strong>Excelência Operacional, Confiabilidade, Otimização de Custos, Segurança e Performance.</strong></p>",
        "<p><strong>A nuvem não é apenas o destino, é o veículo!</strong> Migrar para a nuvem pode parecer complexo, mas com nossa experiência, <strong>tornamos essa transição suave e livre de preocupações.</strong>&nbsp;</p> <strong>Desde a avaliação inicial até a implementação completa</strong>, nossa equipe dedicada guiará você em cada etapa, <strong>garantindo uma transição eficiente</strong> e minimizando qualquer interrupção nos seus processos operacionais.",
        "<p>Temos escritório de projetos e <strong>especialistas certificados prontos para garantir a tranquilidade de sua migração</strong>!</p> <p>Entre em contato conosco hoje e descubra como podemos contribuir no processo de migração para a nuvem de sua empresa e <strong>tenha todos os benefícios que a computação em nuvem oferece.</strong>&nbsp;</p>",
      ],
    },
  },
  finops: {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Finops",
    titulo: "Otimize seus Custos em Cloud com Finops",
    subTitulo:
      "Otimize seus custos na nuvem com uma gestão financeira eficiente.",
    cards: {
      titulo: "Redefinindo a Gestão Financeira na Nuvem!",
      conteudo: [
        "<p>Na era da computação em nuvem, <strong>otimizar custos é tão crucial quanto a inovação tecnológica</strong>. Na Qi Network, temos um <strong>serviço especializado que redefine a gestão financeira na nuvem</strong>, permitindo que sua empresa alcance eficiência operacional e economia substancial.&nbsp;</p> Combinamos <strong>práticas operacionais, ferramentas certas e cultura para maximizar o valor da nuvem</strong>, garantindo que você <strong>pague apenas pelo que realmente usa</strong>.",
        "<p>Chega de não ter visão, transparência, controle e custos otimizados… <strong>Veja nossos serviços de Finops:&nbsp;</strong></p> <p>Otimização, Visibilidade e Previsibilidade dos Custos / Organização e Atribuição de Centro de Custos / Monitoramento de Custos / Ações Corretivas de Mitigação e Otimização / Análise de Arquitetura com Foco em Redução de Custos / Análise de Capacity / Custo Otimizado sem Perder Performance / Controles Orçamentários / Alertas / Commits e Reservas de Instâncias / Recursos Computacionais / Adoção e Treinamento de Processos / Cultura Finops</p>",
        "<p>Além disso, <strong>oferecemos conformidade financeira</strong> e muito mais para garantir que sua empresa tenha <strong>controle total sobre seus gastos na nuvem.&nbsp;</strong></p> <p>Com nosso <strong>serviço de Finops</strong>, você pode ter a certeza de que está obtendo o máximo valor de sua infraestrutura em nuvem, enquanto <strong>mantém seus custos sob controle e sua operação financeira otimizada.</strong></p>",
      ],
    },
  },
  cms: {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Cloud Managed Services (CMS)",
    titulo: "Excelência e Inovação na Gestão de Cloud",
    subTitulo:
      "Tenha suporte especializado para gerenciar sua infraestrutura na nuvem.",
    cards: {
      titulo: " Levando sua Empresa a Novos Patamares na Nuvem!",
      conteudo: [
        "<p><strong>O Cloud Managed Services</strong> é focado em realizar um atendimento de alto nível de governança, automação, inteligência e expertise dos ambientes em Cloud dos nossos clientes.&nbsp;</p> <p>Realizado por <strong>especialistas e engenheiros Cloud, devops e engenheiros de dados</strong>, o modelo de atendimento tem como intuito estar próximo no dia a dia da operação. Excelência na gestão contínua, <strong>inovação e automação dos ambientes Cloud da sua empresa</strong>.</p>",
        "<p><strong>Serviços gerenciados em Cloud</strong>, garantem de maneira eficiente a gestão contínua de seus recursos de infraestrutura, possibilitando <strong>ambientes cada vez mais modernos, atualizados, escaláveis, otimizados e com a inovação em dia</strong>!</p> <p><strong>Conhecemos seu negócio</strong>, entendemos seus desafios e adequamos as melhores soluções e projetos para seus ambientes de acordo com cada necessidade. <strong>Temos canais de comunicação rápida e diferenciada</strong> para clientes que possuem CMS contratado conosco.</p>",
        "<p><strong>Sobre o Cloud Managed Services:</strong></p> <ul> <li>Advisory e pró-ativo.</li> <li>Prevenir problemas e incidentes futuros.</li> <li>Manter disponibilidade e saúde dos serviços em nuvem.</li> <li>Conhecer com profundidade as ACNs dos clientes.</li> <li>Conhecer a fundo o ambiente de sua infraestrutura.</li> <li>Otimizar custos, segurança, compliance e performance.</li> <li>Automatizar tarefas repetitivas.</li> <li>Governança através de boas práticas e compliance.</li> <li>Atuar na resolução de incidentes e mudanças.</li> <li>Atuação técnica na solução de crises.</li> </ul>",
      ],
    },
  },
  "backup-dr": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Backup & DR",
    titulo: "Backup & Disaster Recovery: Proteção Essencial para seus Dados",
    subTitulo:
      "Proteja seus dados e garanta a continuidade do seu negócio em caso de desastres.",
    cards: {
      titulo: "Garantindo a Continuidade dos Negócios em Qualquer Situação!",
      conteudo: [
        "<p>Em um mundo digital em constante evolução, a <strong>segurança dos seus dados é mais crucial do que nunca</strong> e você sabe o quanto seus dados são essenciais para o funcionamento do seu negócio.</p> <strong>Apresentamos nossa Consultoria e Implementação de Cloud Backup e Disaster Recovery</strong>, uma solução abrangente para garantir a continuidade dos seus negócios, mesmo diante dos imprevistos.",
        "<p>Com nossos serviços especializados, <strong>garantimos que esses ativos valiosos estejam protegidos</strong>, respaldados e prontos para serem recuperados em questão de minutos, não importa o que aconteça.</p> <p>Não é apenas sobre backup, é sobre recuperação. <strong>Nossos serviços garantem uma recuperação rápida e eficiente,</strong> minimizando o tempo de inatividade e permitindo que sua empresa retome suas operações normais sem impacto significativo.</p>",
        "<p><strong>Planejamos e implementamos as políticas mais adequadas para seus ambientes, garantindo segurança dos dados e informações</strong>. Recuperação de Desastres <strong>(DR)</strong>: Que tal ter seu ambiente replicado, pronto para ser promovido para produção caso seu ambiente produtivo sofra um ataque ou tenha um outage em sua região, edge locations, clouds híbridas ou data centers específicos?</p> <p>É isso que fazemos, montamos a melhor estrutura para que seu ambiente produtivo não só seja restaurado, mas que <strong>esteja disponível com alta velocidade e garanta a continuidade de seu negócio/operação</strong>.</p>",
      ],
    },
  },
  "devops-sre": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Devops & SRE",
    titulo: "DevOps & SRE: Impulsionando a Eficiência e a Inovação",
    subTitulo:
      "Acelere o desenvolvimento e a entrega de software com práticas modernas.",
    cards: {
      titulo: "Harmonizando Desenvolvimento e Operações na Nuvem!",
      conteudo: [
        "<p>Na era da inovação e desenvolvimento ágil, a harmonia entre desenvolvimento e operações é essencial.</p> <p><strong>Nosso serviço de DevOps &amp; SRE</strong> consiste em construir uma estratégia para acelerar o desenvolvimento, melhorar a qualidade do software, garantir confiabilidade, observabilidade e eficiência operacional na nuvem.</p>",
        "<p><strong>Sincronia Perfeita:</strong> O <strong>DevOps</strong> é mais do que uma prática, é uma filosofia que integra equipes de desenvolvimento e operações para <strong>impulsionar a colaboração e eficiência.</strong></p> <strong>Nosso serviço visa criar uma sincronia perfeita entre essas duas disciplinas na nuvem</strong><span>, resultando em ciclos de desenvolvimento mais rápidos e entregas mais confiáveis.</span>",
        "<p><strong>Pilares de atuação do nosso serviço de DevOps &amp; SRE:</strong></p> <p>Gestão de Pipelines / Kubernetes / Docker / Orquestração / Microserviços / Automação de Processos / Automação de Configurações / Infrastructure As A Code (IaC) / Continuous Integration (CI) / Continuous Delivery (CD) / Builds Automatizados / Testes Automatizados / Implantações Automatizadas / Rollbacks Automatizados / Observabilidade / Criação de Pipelines / DevSecOps.</p>",
      ],
    },
  },
  "posture-review": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Posture Review (Segurança & Compliance)",
    titulo:
      "Cloud Posture Review: Garantindo Segurança e Conformidade na Nuvem",
    subTitulo:
      "Garanta a conformidade e a segurança dos seus sistemas na nuvem.",
    cards: {
      titulo: "Protegendo sua Presença Digital em um Ambiente Complexo!",
      conteudo: [
        "<p>Em um ambiente digital cada vez mais complexo, a <strong>segurança na nuvem</strong> é uma prioridade inegociável.</p> Nosso serviço de <strong>Cloud Posture Review</strong> é uma abordagem especializada que garante que sua presença na nuvem seja robusta, resiliente e alinhada com as <strong>melhores práticas de segurança do mercado.</strong>",
        "<p><strong>Avaliação Profunda da Postura de Segurança</strong>: A segurança na nuvem vai além de meras configurações.</p> Com nossa <strong>Consultoria de Cloud Posture Review</strong>, realizamos uma avaliação profunda da sua postura de segurança, identificando potenciais vulnerabilidades e garantindo que todos os aspectos da sua infraestrutura na nuvem estejam <strong>devidamente protegidos e em conformidade.</strong>",
        "<p><strong>Implementação de Medidas Corretivas Efetivas:</strong> Identificamos, mas também agimos. Nossa equipe não apenas aponta potenciais problemas de segurança, mas também implementa <strong>medidas corretivas efetivas</strong>.</p> <p><strong>Personalização para seu Ambiente Único:</strong> Nossa abordagem é altamente personalizada para garantir que sua infraestrutura na nuvem esteja constantemente atualizada, segura contra ataques e ajustada às particularidades do seu ambiente, setor e requisitos regulatórios.</p>",
      ],
    },
  },
  "suporte-monitoramento": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Suporte & Monitoramento",
    titulo: "Suporte & Monitoramento Cloud: Mantendo sua Empresa nas Alturas",
    subTitulo:
      "Tenha suporte técnico e monitore o desempenho da sua infraestrutura.",
    cards: {
      titulo:
        " Voando Sempre com Desempenho Otimizado e Tranquilidade na Nuvem!",
      conteudo: [
        "<p><strong>Suporte confiável e monitoramento eficiente na nuvem</strong> é essencial em um ambiente dinâmico e ágil.</p> <p>Nossos serviços especializados de <strong>Suporte e Monitoramento Cloud</strong> garantem que sua presença digital esteja sempre nas alturas, com desempenho otimizado e problemas detectados antes mesmo de afetarem suas operações.</p>",
        "<p>Com nosso <strong>Suporte e Monitoramento de Cloud</strong>, oferecemos as asas que sua empresa precisa para alcançar novos patamares. Mantenha-se nas alturas, enquanto <strong>nós cuidamos da saúde e desempenho da sua infraestrutura na nuvem.</strong></p> <p>Temos a opção de suporte e monitoramento ideal para você! <strong>24x7 ou 8x5</strong>, você escolhe, o mais importante é que estaremos aqui para te apoiar e atender incidentes e requisições, na maioria das vezes <strong>detectadas pró-ativamente</strong> antes que sua operação sinta!</p>",
        "<p><strong>Construímos o melhor cenário de monitoramento para sua empresa</strong> e entregamos um mapa de monitoramento com todos os componentes monitorados, thresholds estabelecidos e ações runbook para cada item.</p> <p><strong>Aqui não tem como o alerta passar despercebido!</strong> Nosso monitoramento é automático e quando implementado abre diretamente um chamado no nosso ITSM, que por sua vez, será tratado pelos especialistas.</p>",
      ],
    },
  },
  "modernizacao-infra": {
    img: "./assets/images/solucoes/img-solucoes-top2.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Modernização de Infra",
    titulo:
      "Modernização de Infraestrutura em Cloud: Rumo à Excelência Operacional",
    subTitulo:
      "Atualize sua infraestrutura para suportar as demandas da transformação digital.",
    cards: {
      titulo: "Transformando Sistemas para o Futuro da Tecnologia!",
      conteudo: [
        "<p><strong>Modernizar a infraestrutura</strong> é o caminho para a excelência operacional na era da inovação.</p> <p><strong>Nossos serviços</strong> de implementação de Modernização de Infraestrutura em Cloud utilizam uma abordagem estratégica que leva seus sistemas ao próximo nível, proporcionando <strong>agilidade, eficiência e escalabilidade</strong>.</p>",
        "<p><strong>Rumo à Modernidade:</strong> Dê um salto em direção à modernidade com nossos serviços especializados.</p> <p><strong>A modernização da infraestrutura</strong> não é apenas uma atualização, é uma transformação que redefine a maneira como seus sistemas operam e se conectam. Prepare-se para um futuro mais ágil e eficiente.</p>",
        "<p><strong>Implementação Sem Disrupções:</strong> A modernização não precisa ser disruptiva. Mantemos seus sistemas operacionais enquanto introduzimos tecnologias avançadas para <strong>impulsionar o desempenho</strong>.</p> <p><strong>Nossa equipe experiente</strong> gerencia a implementação com maestria, garantindo uma <strong>transição suave para a nova infraestrutura na nuvem.</strong></p>",
      ],
    },
  },
};

export default data;
