import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-qi-academy',
  templateUrl: './qi-academy.component.html',
  styleUrls: ['./qi-academy.component.scss']
})
export class QiAcademyComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {

    this.helper.ajudaCinza = true;
    this.helper.ajudaAzul = false;

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;

  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
