import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selos',
  templateUrl: './selos.component.html',
  styleUrls: ['./selos.component.scss']
})
export class SelosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
