import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-varejo',
  templateUrl: './varejo.component.html',
  styleUrls: ['../verticais.component.scss']
})
export class VarejoComponent implements OnInit {

  constructor( public helper: HelperService) { }

  public cases: any[] = [
    {
      id:1,
      img: './assets/images/Verticais/img-cases-carrefour.png',
      alt: 'carrefour',
      link: '/case/6'
    },
    {
      id:2,
      img: './assets/images/Verticais/img-cases-kalzone.png',
      alt: 'kalzone',
      link: '/case/5'
    },
    {
      id:3,
      img: './assets/images/Verticais/img-cases-koerich.png',
      alt: 'koerich',
      link: '/case/25'
    }
  ];

  ngOnInit() {
  }
  
  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth", block: "start", inline: "end"});
  }

}
