import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-inteligencia-dados',
  templateUrl: './inteligencia-dados.component.html',
  styleUrls: ['../solucoes.component.scss']
})
export class InteligenciaDadosComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  public solucoes: any [] = [
    {
      id: 1,
      img: './assets/images/solucoes/img-inteligencia-banco-dados.png',
      titulo: 'Banco de Dados (DBaaS)',
      descricao: 'Execute e gerencie seus bancos de dados em escala global, otimizando seu trabalho para conseguir mais eficiência e ...',
      icone: './assets/icons/solucoes/i-seta-solucoes-laranja.svg',
      link: '/solucoes/inteligencia-dados/banco-dados-googlecloud'
    },
    {
      id: 2,
      img: './assets/images/solucoes/img-inteligencia-data-studio.png',
      titulo: 'Google Data Studio',
      descricao: 'Transforme seus dados em relatórios e painéis informativos, fáceis de ler e de compartilhar e totalmente personalizáveis.',
      icone: './assets/icons/solucoes/i-seta-solucoes-laranja.svg',
      link: '/solucoes/inteligencia-dados/google-data-studio'
    }
  ];

  public clientes: any[] = [
    {
      id: 1,
      solucoes: 'infra',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-laranja.svg',
      link: ''
    },
    {
      id: 2,
      solucoes: 'infra',
      logo: './assets/logos/institucional/metropoles.svg',
      alt: 'metropoles',
      tipo: 'Video',
      descricao: 'Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud',
      tempo: '2:52 min',
      icone: './assets/icons/institucional/i-play-laranja.svg',
      link: ''
    },
    {
      id: 3,
      solucoes: 'dados',
      logo: './assets/logos/institucional/squadra.svg',
      alt: 'squadra',
      tipo: 'Case Study',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-laranja.svg',
      link: ''
    }
  ];

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = true;
    this.helper.ajudaCinza = false;
    this.helper.ajudaAzul = true;

  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }
}
