import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';
import { webinars } from './webinarsData';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {
  webinars: any[] = [];

  constructor(public helper: HelperService) { }

  ngOnInit() {
    this.webinars = webinars;
    this.helper.ajudaCinza = true;
    this.helper.ajudaAzul = false;

  }

  imgChange(play,id) {
    console.log(id);
    const img = document.getElementsByClassName('play')[id] as HTMLImageElement;

    switch (play) {
      case 'enter':
        img.src = './assets/icons/conteudos/i-play-azul.svg'
        break;
        case 'leave':
        img.src = './assets/icons/conteudos/i-play-branco.svg'
        break;
    
      default:
        break;
    }
  }

}
