export const webinars = [
  {
    serie: "Google Workspace",
    color: 'blue',
    playlist: [
      {
        title: "Gemini Week 2024",
        videos: 5,
        link: "https://www.youtube.com/watch?v=sQSZ2mC3o-c&list=PLnhxh_mDcQiSj0tEax15yA623zO4KLgtA&index=5",
      },
      {
        title: "Google Workspace 2024",
        videos: 1,
        link: "https://www.youtube.com/watch?v=6ElYAa2dKoc&list=PLnhxh_mDcQiRYqkJtRKb-KS9FE_aQhJFN",
      },
      {
        title: "Google Workspace na Prática 2023 - H2",
        videos: 5,
        link: "https://www.youtube.com/watch?v=gH5F6adirso&list=PLnhxh_mDcQiRvMUa6A-cCndxkfGT2F6S2",
      },
      {
        title: "Google Workspace na Prática 2023",
        videos: 6,
        link: "https://www.youtube.com/watch?v=Y2hMxbL-dls&list=PLnhxh_mDcQiTf4dpmtvwa0OPRofOloEcx",
      },
      {
        title: "Google Workspace na Prática 2022",
        videos: 8,
        link: "https://www.youtube.com/watch?v=qFJKjzs1u6Q&list=PLnhxh_mDcQiR-2lJtL7P4W8AdB6ajWOEW",
      },
      {
        title: "Google Workspace na Prática",
        videos: 9,
        link: "https://www.youtube.com/watch?v=y26_nvYskLc&list=PLnhxh_mDcQiQFIl8SXK4IOEVW7_BF9aOu",
      },
    ],
  },
  {
    serie: "Cloud Services",
    color: 'red',
    playlist: [
      {
        title: "AI Week 2024",
        videos: 4,
        link: "https://www.youtube.com/watch?v=43z7JL1_wno&list=PLnhxh_mDcQiQfRbGWamE2ukyJWb6xXr2Y",
      },
      {
        title: "Desvendando o Google Cloud 2023 - H2",
        videos: 5,
        link: "https://www.youtube.com/watch?v=AX9hOFjC55k&list=PLnhxh_mDcQiRnlhhi9Fl7asc1RLcweHMZ",
      },
      {
        title: "Desvendando o Google Cloud 2023",
        videos: 6,
        link: "https://www.youtube.com/watch?v=_vZfKFWK34M&list=PLnhxh_mDcQiR9DbeT1Ar-d-K4Lp2qv1ma",
      },
      {
        title: "Segurança na Prática com Google Cloud",
        videos: 2,
        link: "https://www.youtube.com/watch?v=E0oLZkfX2Y4&list=PLnhxh_mDcQiTqkrmxp6W39Beh_Hv8jAAU",
      },
      {
        title: "Desvendando o Google Cloud 2022",
        videos: 9,
        link: "https://www.youtube.com/watch?v=5p-4TMHer0w&list=PLnhxh_mDcQiQyeGyQZx0wazTFpJN81kQ3",
      },
      {
        title: "Desvendando o Google Cloud",
        videos: 9,
        link: "https://www.youtube.com/watch?v=F70iep8-cMY&list=PLnhxh_mDcQiSHuNWMb4vLcTT37SsjRw_f",
      },
    ],
  },
];
