import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-campanha-lgpd',
  templateUrl: './campanha-lgpd.component.html',
  styleUrls: ['./campanha-lgpd.component.scss']
})
export class CampanhaLgpdComponent implements OnInit {

  public  activeModal: any  = {};
  public video_case;
  public depoimentos: any[] = [
    {
      id: 1,
      img: './assets/images/img-depoimento-2.png',
      nome: 'Gabriel Carvalho',
      cargo: 'Executivo Google Cloud',
      video: 'https://www.youtube.com/embed/Cm21I6EvZmA'
    },
    {
      id: 2,
      img: './assets/images/img-depoimento-1.png',
      nome: 'Harlen Duque',
      cargo: 'Presidente da Sucesu',
      video: 'https://www.youtube.com/embed/6p1gF8k2c3E'
    },
    {
      id: 3,
      img: './assets/images/img-depoimento-3.png',
      nome: 'Allan Rabelo',
      cargo: 'Diretor de Tecnologia do Metrópoles',
      video: 'https://www.youtube.com/embed/06KekuJee6c'
    },
    {
      id: 4,
      img: './assets/images/img-depoimento-2.png',
      nome: 'Gabriel Carvalho',
      cargo: 'Executivo Google Cloud',
      video: 'https://www.youtube.com/embed/Cm21I6EvZmA'
    },
    {
      id: 4,
      img: './assets/images/img-depoimento-1.png',
      nome: 'Harlen Duque',
      cargo: 'Presidente da Sucesu',
      video: 'https://www.youtube.com/embed/6p1gF8k2c3E'
    },
    {
      id: 5,
      img: './assets/images/img-depoimento-3.png',
      nome: 'Allan Rabelo',
      cargo: 'Diretor de Tecnologia do Metrópoles',
      video: 'https://www.youtube.com/embed/06KekuJee6c'
    }
  ];

  constructor( public helper: HelperService,private dom: DomSanitizer) { }

  public activeDepoimento: any = {};
  depoConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: ``,
          prevArrow: ``,       
          dots: true,   
          autoplay: true,
        }
      }
    ]
  };

  public Slide(slide: any) {
    console.log(slide);
    this.activeDepoimento = slide;
  }

  ngOnInit() {
    this.helper.hasNav = false;
    this.activeDepoimento = this.depoimentos[0];
    this.helper.pagina = "lgpd";
 }

  public Modal(modal: any) {
    this.activeModal = modal;
    this.video_case = this.dom.bypassSecurityTrustResourceUrl(this.activeModal.video);
  }

  slickInit(e) {
    console.log('slick initialized');
  }
  breakpoint(e) {
      console.log('breakpoint');
  }
  afterChange(e) {
      this.activeDepoimento = this.depoimentos[e.currentSlide];
  }
  beforeChange(e) {}
}
