export const SaudeData: any = {
  title: "Panorama do armazenamento de dados na nuvem na saúde",
  description: `
  <p>O armazenamento de dados na nuvem &eacute; um dos grandes desafios quando pensamos em inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de. No cloud computing, as organiza&ccedil;&otilde;es desse setor conseguem construir infraestruturas mais flex&iacute;veis e escal&aacute;veis diante da quantidade gigantesca de dados.</p>

  <p>Os dados s&atilde;o muitos: diagn&oacute;sticos, interna&ccedil;&otilde;es, exames, tratamentos e at&eacute; mesmo rea&ccedil;&otilde;es a rem&eacute;dios podem ser informa&ccedil;&otilde;es de valor que precisam estar muito bem registradas nos hospitais e ambientes de sa&uacute;de.</p>

  <p>Mas quais s&atilde;o as bases do armazenamento de dados na nuvem? Quais os impactos que essa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de pode trazer para a seguran&ccedil;a de dados? Quais os benef&iacute;cios do armazenamento de dados em hospitais? E at&eacute; mesmo: como fazer essa migra&ccedil;&atilde;o?</p>

  <p>Essas s&atilde;o algumas d&uacute;vidas comuns para os gestores de sa&uacute;de que precisam dar esse passo a favor da tecnologia e praticidade. Confira abaixo um guia completo sobre o tema que ajudar&aacute; voc&ecirc; nesse processo!</p>

  `,
  items: [
    {
      title: "O que é armazenamento de dados na nuvem?",
      description: `
      <p>O armazenamento de dados na nuvem nada mais &eacute; do que um <strong>modelo de computa&ccedil;&atilde;o em nuvem que tem como objetivo armazenar dados e arquivos dentro da internet de forma remota</strong>.</p>

      <p>Assim, <strong>os servi&ccedil;os de armazenamento na nuvem v&atilde;o oferecer bancos de dados organizados e seguros</strong>. No caso da sa&uacute;de, os dados v&atilde;o ser conectados &agrave;s atividades, que podem envolver o tratamento dos pacientes e a gest&atilde;o hospitalar, por exemplo.</p>

      <p>A relev&acirc;ncia aqui est&aacute; no fato de que todas essas informa&ccedil;&otilde;es est&atilde;o reunidas em um s&oacute; lugar, sem a necess&aacute;ria infraestrutura f&iacute;sica para reunir os documentos.</p>

      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-saude/cloud.jpg"
    },
    {
      title: "Como funciona o armazenamento de dados na nuvem?",
      description: `
      <p>Para entendermos ainda melhor esse modelo de computa&ccedil;&atilde;o em nuvem e sua inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de, &eacute; preciso compreender tamb&eacute;m o funcionamento dele.</p>

      <p>E isso come&ccedil;a conhecendo os tipos de armazenamento que existem: p&uacute;blico, privado e h&iacute;brido. A seguir, vamos apresent&aacute;-los.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Armazenamento público",
      description: `
      <p>O armazenamento p&uacute;blico &eacute; aquele em que <strong>o prestador de servi&ccedil;os vai dispor publicamente os recursos para armazenamento de dados e para gerir esse ambiente</strong>. O provedor &eacute; terceirizado e compartilhado com v&aacute;rias organiza&ccedil;&otilde;es que usam a internet p&uacute;blica.</p>

      <p>Isso significa que as empresas v&atilde;o contar com um centro de dados com propriedade virtual, mas sem ser necess&aacute;rio comprar e manter toda a estrutura de equipamentos e softwares que comp&otilde;em essa solu&ccedil;&atilde;o.</p>

      <p>Esse tipo de funcionamento na computa&ccedil;&atilde;o em nuvem &eacute; o mais comum no mercado. Um exemplo disso &eacute; o<strong> Google Cloud</strong>, sua grande vantagem &eacute; o baixo ou nenhum custo.</p>

      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-saude/fundo-de-armazenamento-em-nuvem-design-de-rede-empresarial.jpg"
    },
    {
      title: "Armazenamento privado",
      description: `
      <p>O armazenamento privado &eacute; o tipo em que<strong> os recursos computacionais necess&aacute;rios s&atilde;o &uacute;nicos de uma empresa e, por isso, foca nas necessidades dessa institui&ccedil;&atilde;o</strong>. Basicamente, estamos falando de um cen&aacute;rio de exclusividade.</p>

      <p>Para tanto, &eacute; preciso que o modelo seja hospedado por um provedor de TI terceirizado, ou ent&atilde;o, fisicamente implantado no centro de dados da empresa. Esse armazenamento conta com a virtualiza&ccedil;&atilde;o de recursos, servi&ccedil;os e padroniza&ccedil;&atilde;o da gest&atilde;o.</p>

      <p>O acesso na nuvem privada &eacute; dedicado a um &uacute;nico utilizador, compartilhado entre os usu&aacute;rios. Alguns exemplos de nuvem privada s&atilde;o o VMware, CloudStack, OpenStack, entre outros. Sua grande vantagem &eacute; a ampla possibilidade de customiza&ccedil;&atilde;o.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Armazenamento híbrido",
      description: `
      <p>Por fim, o armazenamento h&iacute;brido &eacute; aquele em que os dois cen&aacute;rios anteriores coexistem. Ou seja, <strong>oferece tanto as funcionalidades do armazenamento p&uacute;blico quanto a possibilidade de fazer customiza&ccedil;&otilde;es &mdash; o que &eacute; poss&iacute;vel na nuvem privada</strong>.</p>


<p>&Eacute; como se fosse um meio termo entre as duas op&ccedil;&otilde;es. Os fornecedores internos e externos mant&ecirc;m a infraestrutura de forma conjunta e os sistemas s&atilde;o implementados permitindo compartilhar dados entre o armazenamento privado e da nuvem p&uacute;blica.</p>


<p>Um exemplo disso seria combinar um data center no local com o Google Cloud. A ideia &eacute; unir os lados positivos das duas op&ccedil;&otilde;es. No geral, quando se entende que somente um modelo talvez n&atilde;o &eacute; suficiente para todas as suas demandas, as empresas optam por essa solu&ccedil;&atilde;o.</p>

      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-saude/cloud-storage.jpg"
    },
    {
      title: "Panorama do armazenamento de dados na nuvem na saúde",
      description: `
      <p>Segundo o relat&oacute;rio <a target="_blank" href="https://info.flexera.com/SLO-REPORT-State-of-Tech-Spend">State of Tech Spend</a> da Flexera em 2022,<strong> 44% dos diretores de TI da &aacute;rea da sa&uacute;de classificaram os servi&ccedil;os de cloud computing entre as tr&ecirc;s principais prioridades de investimento </strong>das institui&ccedil;&otilde;es onde trabalham.</p>

      <p>Ou seja, essa &eacute; uma inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de vista como investimento e, principalmente, algo fundamental para o desenvolvimento das atividades desse setor. E quanto pensamos no valor do mercado, n&atilde;o &eacute; diferente.</p>

      <p>O mercado global de tecnologia de armazenamento de dados na nuvem na &aacute;rea da sa&uacute;de foi estimado em US$26,8 bilh&otilde;es no ano de 2020 e, <strong>at&eacute; 2027, deve atingir um valor estimado de US$ 89,3 bilh&otilde;es</strong>. &Eacute; um crescimento 18,7% anual, <a target="_blank" href="https://www.researchandmarkets.com/reports/3502812/healthcare-cloud-computing-global-market">segundo dados do Research and Markets</a>.</p>

      <p>Outros dados anteriores j&aacute; corroboram para esse cen&aacute;rio positivo, como a <a target="_blank" href="http://www.himssanalytics.org/sites/himssanalytics/files/Cloud%20Study_2017%20Snapshot.pdf">pesquisa da HIMS</a>, em 2017. A nuvem j&aacute; era vista como a op&ccedil;&atilde;o de implanta&ccedil;&atilde;o preferida para<strong> aplicativos de assist&ecirc;ncia m&eacute;dica.</strong></p>

      <p><strong>65% dos entrevistados disseram que suas organiza&ccedil;&otilde;es de sa&uacute;de atualmente utilizam a nuvem ou servi&ccedil;os em nuvem</strong>.</p>

      <p>Esse uso costumava ser direcionado para aplicativos de hospedagem de dados, al&eacute;m de recupera&ccedil;&atilde;o e backup de informa&ccedil;&otilde;es.</p>

      <p>Pensando nisso, nos anos seguintes, o cen&aacute;rio foi se consolidando ainda mais, criando esse tipo de armazenamento como personagem central, at&eacute; chegarmos nos n&uacute;meros de agora.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Inovação na área da saúde: quais os impactos no armazenamento de dados na LGPD?",
      description: `
      <p>O armazenamento de dados em hospitais ou cl&iacute;nicas de sa&uacute;de passa a trazer uma responsabilidade muito mais ampla para essas institui&ccedil;&otilde;es.</p>

<p>Qualquer exposi&ccedil;&atilde;o de informa&ccedil;&otilde;es dos pacientes &mdash; seja por erros ou por invas&atilde;o de terceiros &mdash; passa ser uma responsabilidade deles.</p>

<p>E &eacute; por isso que esse &eacute; um dos impactos que mais merece destaque: <a target="_blank" href="https://blog.qinetwork.com.br/data-loss-prevention/">vazamento de dados</a>. A seguran&ccedil;a cibern&eacute;tica passa a ter uma import&acirc;ncia muito maior e aqui estamos falando n&atilde;o s&oacute; de dados pessoais, mas tamb&eacute;m de informa&ccedil;&otilde;es sens&iacute;veis.</p>

<p>A <a target="_blank" href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm">Lei Geral de Prote&ccedil;&atilde;o de Dados</a> (LGPD), em vigor desde 2020, <strong>tem como objetivo regular todas as atividades de tratamento de dados pessoais e reduzir os impactos negativos dessa coleta</strong>.</p>

<p>Como a &aacute;rea da sa&uacute;de trabalha com informa&ccedil;&otilde;es de pacientes, desde a implementa&ccedil;&atilde;o dessa legisla&ccedil;&atilde;o, as institui&ccedil;&otilde;es desse setor precisam redobrar a responsabilidade sobre a coleta, o armazenamento e uso delas &mdash; que est&atilde;o, inclusive, em nuvem.</p>


      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "",
      description: `
      <p><strong>Antes desse marco, cl&iacute;nicas, hospitais e laborat&oacute;rios n&atilde;o eram obrigadas a ter um controle r&iacute;gido em rela&ccedil;&atilde;o a esses dados</strong>. Agora, independentemente do local que est&atilde;o utilizando um servi&ccedil;o de sa&uacute;de, &eacute; preciso um cuidado muito maior.</p>

      <p>Para ter acesso a esses dados &mdash; que podem ser pessoais, sens&iacute;veis, anonimizados e pseudo-anonimizados &mdash;, &eacute; necess&aacute;rio que o paciente autorize de forma clara e consciente o uso.</p>
      
      <p>Qualquer informa&ccedil;&atilde;o que v&aacute; fazer parte dos dados em nuvem precisa estar autorizada pelo <strong>Termo de Consentimento Livre e Esclarecido</strong>. &Eacute; essencial que a institui&ccedil;&atilde;o de sa&uacute;de informe pontos como:</p>
      
      <ul>
        <li>
        <p>Funcionamento da coleta;</p>
        </li>
        <li>
        <p>Direitos do paciente;</p>
        </li>
        <li>
        <p>Compartilhamento dos dados com parceiros e o motivo;</p>
        </li>
        <li>
        <p>Necessidade da assinatura do aceite.</p>
        </li>
      </ul>
      
      <p>A revoga&ccedil;&atilde;o desse consentimento tamb&eacute;m pode ser feita a qualquer momento. Outro ponto crucial &eacute; que todos os dados do banco em nuvem precisam funcionar com base nos conceitos de confidencialidade, integridade, disponibilidade e autenticidade.</p>
      
      <p>Al&eacute;m disso, &eacute; essencial que essa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de &mdash; a partir do tratamento desses dados armazenados em nuvem &mdash; seja feita para fins leg&iacute;timos, espec&iacute;ficos, expl&iacute;citos, sem possibilidade de tratamento posterior de forma incompat&iacute;vel com a finalidade.</p>
      
      <p>Para as cl&iacute;nicas e institui&ccedil;&otilde;es m&eacute;dicas, ainda, a LGPD &eacute; aplicada por meio da <a target="_blank" href="https://www.gov.br/conarq/pt-br/legislacao-arquivistica/leis-e-decretos-leis/lei-no13-787-de-27-de-dezembro-de-2018#:~:text=Disp%C3%B5e%20sobre%20a%20digitaliza%C3%A7%C3%A3o%20e,manuseio%20de%20prontu%C3%A1rio%20de%20paciente.">Lei 13.787 de dezembro de 2018</a>, mais espec&iacute;fica. Ela regulamenta a utiliza&ccedil;&atilde;o dos Prontu&aacute;rios Eletr&ocirc;nicos do Paciente (PEP).</p>
      

      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-saude/infografia-de-negocios-em-holograma-feita-por-medico.jpg"
    },
    {
      title: "Penalidades para as empresas que desrespeitam as regras da LGPD",
      description: `
      <p>Caso alguma parte da <a target="_blank" href="https://blog.qinetwork.com.br/lgpd-gdpr-compliance-e-seguranca-da-ti/">LGPD</a> n&atilde;o seja respeitada, a institui&ccedil;&atilde;o de sa&uacute;de vai precisar responder por isso. No caso da advert&ecirc;ncia, as organiza&ccedil;&otilde;es precisam regularizar as suas opera&ccedil;&otilde;es.</p>

      <p>Tamb&eacute;m &eacute; poss&iacute;vel sofrer uma multa simples, que pode chegar a 2% sobre o faturamento do neg&oacute;cio, tendo o limite de 50 milh&otilde;es de reais.</p>

      <p>Al&eacute;m disso, h&aacute; tamb&eacute;m a multa di&aacute;ria, com o mesmo valor m&aacute;ximo. Outras multas poss&iacute;veis s&atilde;o a publiciza&ccedil;&atilde;o da infra&ccedil;&atilde;o (empresa torna-se do Estado), bloqueio ou elimina&ccedil;&atilde;o dos dados pessoais do paciente ou respons&aacute;vel.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Quais os benefícios do armazenamento de dados na nuvem para o setor da saúde?",
      description: `
      <p>Se o armazenamento de dados na nuvem &eacute; uma inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de, quais s&atilde;o os benef&iacute;cios pr&aacute;ticos de investir em um armazenamento de dados em hospitais e outras institui&ccedil;&otilde;es de sa&uacute;de? Apenas para destacar os principais, est&atilde;o:</p>

<ol>
	<li>
	<p>Capacidade de armazenamento ampliada;</p>
	</li>
	<li>
	<p>Maior organiza&ccedil;&atilde;o das informa&ccedil;&otilde;es;</p>
	</li>
	<li>
	<p>Redu&ccedil;&atilde;o de custos no setor;</p>
	</li>
	<li>
	<p>Aumento da seguran&ccedil;a de dados;</p>
	</li>
	<li>
	<p>Pr&aacute;tica de maior sustentabilidade;</p>
	</li>
	<li>
	<p>Integra&ccedil;&atilde;o com outros aplicativos;</p>
	</li>
	<li>
	<p>F&aacute;cil colabora&ccedil;&atilde;o e transfer&ecirc;ncia;</p>
	</li>
	<li>
	<p>Crescimento da produtividade.</p>
	</li>
</ol>

<p>Nos pr&oacute;ximos t&oacute;picos, vamos trabalhar mais cada um deles. Acompanhe!</p>

      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-saude/img-campanha.png"
    },
    {
      title: "Capacidade de armazenamento ampliada",
      description: `
      <p>Com o armazenamento de dados na nuvem, <strong>a capacidade de armazenamento &eacute; quase ilimitada</strong>. Desta forma, voc&ecirc; pode armazenar o m&aacute;ximo de informa&ccedil;&otilde;es necess&aacute;rias para o seu hospital, cl&iacute;nica ou laborat&oacute;rio.</p>

<p>Muitas vezes, gerenciar e guardar arquivos pesados pode ser um desafio para o setor, levando muito mais tempo para encontrar um local que realmente esteja dispon&iacute;vel para dados de maior dimens&atilde;o. Ou seja, essa &eacute; uma boa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de.</p>

<p>Com um sistema em nuvem, a capacidade &eacute; gigantesca, tornando qualquer arquivo armazen&aacute;vel. <strong>E quando a institui&ccedil;&atilde;o cresce, a nuvem consegue sempre acompanhar esse volume de dados crescente</strong>.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Maior organização das informações",
      description: `
      <p>O armazenamento de dados na nuvem oferece informa&ccedil;&otilde;es mais consolidadas e hierarquizadas. Isso se d&aacute; porque os dados podem ser organizados de forma autom&aacute;tica, a partir das configura&ccedil;&otilde;es do sistema.</p>

      <p>Dessa forma, &eacute; poss&iacute;vel encontrar informa&ccedil;&otilde;es mais r&aacute;pida e facilmente, afinal, existe um banco de dados geral para tudo que vai ser armazenado.</p>

      <p><strong>A cria&ccedil;&atilde;o de pastas dentro do sistema de nuvem tamb&eacute;m permite uma melhor organiza&ccedil;&atilde;o dos dados dos pacientes</strong>, tornando mais f&aacute;cil encontrar o que voc&ecirc; est&aacute; procurando e manter todas as informa&ccedil;&otilde;es necess&aacute;rias em um lugar.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Redução de custos no setor",
      description: `
      <p>Os gastos para manuten&ccedil;&atilde;o de uma cl&iacute;nica, hospital ou laborat&oacute;rio s&atilde;o altos. Ent&atilde;o, armazenar arquivos e informa&ccedil;&otilde;es de maneira espalhada s&oacute; representa mais custos para essas empresas, principalmente se ela recorre a pap&eacute;is e espa&ccedil;o f&iacute;sico.</p>

<p>Com essa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de,<strong> a institui&ccedil;&atilde;o n&atilde;o precisa comprar e manter sistemas de armazenamento f&iacute;sico, como hardwares e instala&ccedil;&atilde;o de m&aacute;quinas</strong>. Assim, os custos associados com manuten&ccedil;&atilde;o, reparos e at&eacute; mesmo eletricidade podem ser reduzidos.</p>

<p>Para completar, <strong>a computa&ccedil;&atilde;o em nuvem tamb&eacute;m oferece a possibilidade de comprar o espa&ccedil;o sob demanda </strong>(de acordo com sua necessidade), sem precisar gastar al&eacute;m do que &eacute; necess&aacute;rio naquele momento para sua cl&iacute;nica.</p>

      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-saude/contador.jpg"
    },
    {
      title: "Aumento da segurança de dados",
      description: `
      <p>Os dados armazenados na nuvem s&atilde;o muito mais seguros do que aqueles em um ambiente f&iacute;sico. Isso come&ccedil;a pelo fato de que os fornecedores de nuvem t&ecirc;m toda a tecnologia necess&aacute;ria para armazenar dados de sa&uacute;de dessa maneira.</p>

<p><strong>Os servi&ccedil;os de nuvem oferecem protocolos avan&ccedil;ados de criptografia, backups regulares e sofisticados sistemas de autentica&ccedil;&atilde;o para proteger os dados dos clientes</strong> contra acesso n&atilde;o autorizado ou ataques maliciosos.</p>

<p>Al&eacute;m disso, &eacute; poss&iacute;vel monitorar os dados em tempo real. Dessa forma, voc&ecirc; pode ter controle completo sobre quem tem acesso a essa informa&ccedil;&atilde;o, ajudando a manter os dados sens&iacute;veis dos pacientes seguros e protegidos, de acordo com as diretrizes da LGPD.</p>

<p>Por fim, ainda h&aacute; uma vantagem que merece ser lembrada: a seguran&ccedil;a dos dados online evita problemas de perder arquivos f&iacute;sicos de papel.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Prática de maior sustentabilidade",
      description: `
      <p>A nuvem tamb&eacute;m &eacute; uma iniciativa sustent&aacute;vel por um motivo bem simples: <strong>ela incentiva a institui&ccedil;&atilde;o de sa&uacute;de tornar-se sem papel</strong>, evitando juntamente o uso de tinta de impressoras e pastas de pl&aacute;stico. Ou seja, al&eacute;m de ser eficiente, <strong>gera menos lixo para o planeta</strong>.</p>

<p>A digitaliza&ccedil;&atilde;o junto com a centraliza&ccedil;&atilde;o tamb&eacute;m vai fazer com que aplicativos e outros arquivos fiquem mais leves, reduzindo significativamente o uso do dispositivo que vai acessar os dados.</p>

<p>Ou seja, consequentemente, reduz o consumo de energia, que tamb&eacute;m tem impacto no meio ambiente.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Integração com aplicativos",
      description: `
      <p>H&aacute; tamb&eacute;m a vantagem da nuvem enquanto facilitadora da integra&ccedil;&atilde;o. <strong>Os dados m&eacute;dicos podem ser disponibilizados para qualquer aplicativo que tenha uma necessidade comprovada </strong>de visualizar e agir de acordo com as informa&ccedil;&otilde;es.</p>

<p>Ao fazer uso dessa tecnologia, softwares e sistemas de terceiros podem alterar rapidamente os registros eletr&ocirc;nicos de sa&uacute;de ou informa&ccedil;&otilde;es m&eacute;dicas.</p>

<p>Como exemplo, um farmac&ecirc;utico pode inspecionar prescri&ccedil;&otilde;es de um paciente para validar se n&atilde;o h&aacute; intera&ccedil;&otilde;es medicamentosas indesejadas.</p>

<p>Da mesma maneira, um m&eacute;dico pode facilmente transferir o hist&oacute;rico do seu paciente para um especialista cirurgi&atilde;o para um procedimento m&eacute;dico complexo.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Fácil colaboração e transferência",
      description: `
      <p>A nuvem tamb&eacute;m d&aacute; mais<strong> oportunidades para uma colabora&ccedil;&atilde;o e transfer&ecirc;ncia f&aacute;cil entre equipes de sa&uacute;de e provedores</strong>. Ou seja, essa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de pode ajudar a evitar erros, por exemplo.</p>

<p>Como os dados s&atilde;o atualizados em tempo real, <strong>m&eacute;dicos podem sempre estar a par das informa&ccedil;&otilde;es mais recentes para colaborar nas op&ccedil;&otilde;es de tratamento</strong>.</p>

<p>Isso &eacute; especialmente &uacute;til quando existe a necessidade de transfer&ecirc;ncia entre as equipes de sa&uacute;de durante uma mudan&ccedil;a de turno ou de local. O armazenamento de dados na nuvem pode ser vital para dar a melhor continuidade do atendimento.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Crescimento da produtividade",
      description: `
      <p>No fim das contas, o grande ganho do armazenamento de dados na nuvem na &aacute;rea de sa&uacute;de &eacute; a produtividade da equipe e, consequentemente, da empresa.</p>

<p><strong>Quando se tem acesso f&aacute;cil, informa&ccedil;&otilde;es organizadas, maior seguran&ccedil;a, integra&ccedil;&atilde;o com apps e uma boa transfer&ecirc;ncia de dados, todos ganham.</strong> Os profissionais de sa&uacute;de conseguem trabalhar melhor e entregar servi&ccedil;os com maior qualidade para os pacientes.</p>

<p>Ao mesmo tempo, as empresas t&ecirc;m um maior retorno sobre o investimento e s&atilde;o mais eficientes em suas opera&ccedil;&otilde;es. &Eacute; por isso que a nuvem &eacute; uma parte essencial da jornada de transforma&ccedil;&atilde;o digital de qualquer empresa que busca inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de.</p>

<p><strong>Quer entender tamb&eacute;m sobre a </strong><a target="_blank" href="https://sucesso.qinetwork.com.br/arquitetura-nativa-da-nuvem"><strong>arquitetura nativa de nuvem</strong></a><strong>? Leia nosso ebook sobre o tema e descubra as diretrizes necess&aacute;rias!</strong></p>

      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-saude/produtividade.jpg"
    },
    {
      title: "Exemplos do uso de armazenamento de dados na nuvem na saúde",
      description: `
      <p>Mas o que pode ser feito na pr&aacute;tica com o uso da nuvem em hospitais e outros setores? Vamos conhecer 5 exemplos de inova&ccedil;&atilde;o na &aacute;rea de sa&uacute;de que a computa&ccedil;&atilde;o em nuvem proporciona.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Registro eletrônico dos pacientes",
      description: `<p>O armazenamento de dados na nuvem ajuda a <strong>criar um arquivo completo de paciente, com todas as informa&ccedil;&otilde;es necess&aacute;rias atualizadas em tempo real</strong>. Esse &eacute; um uso bem comum da nuvem: fazer o registro digital da evolu&ccedil;&atilde;o das pessoas atendidas.</p>

      <p>O acesso colaborativo desses registros via computa&ccedil;&atilde;o em nuvem permite usar informa&ccedil;&otilde;es como diagn&oacute;sticos, rea&ccedil;&otilde;es a medicamentos, tratamentos realizados e resultados de exames a favor dos pacientes.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Avaliações de casos de reinternações",
      description: `<p>Com base nos dados em nuvem, fica mais f&aacute;cil identificar pacientes em risco com base no hist&oacute;rico de sa&uacute;de. Isso acontece porque dados internos, juntamente com dados externos compartilhados, v&atilde;o oferecer um panorama mais preciso.</p>

      <p>&Eacute; aqui que a f&aacute;cil colabora&ccedil;&atilde;o e transfer&ecirc;ncia aparece. <strong>Se o paciente j&aacute; esteve antes naquele ambiente ou em outro que tenha coletado os dados, isso vai acelerar o atendimento e a solu&ccedil;&atilde;o para o epis&oacute;dio</strong>.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Medicina baseadas em evidências",
      description: `<p>A computa&ccedil;&atilde;o em nuvem tamb&eacute;m permite que voc&ecirc; use ferramentas baseadas em evid&ecirc;ncias m&eacute;dicas. Ao integrar ao sistema interno a pesquisas relevantes, fica mais f&aacute;cil avaliar caso a caso e fazer diagn&oacute;sticos mais assertivos.</p>

      <p><strong>O armazenamento de dados na nuvem ajuda a simplificar o acesso a bancos de dados de pesquisa porque tudo est&aacute; em lugar s&oacute;</strong>. Isso tamb&eacute;m torna mais f&aacute;cil para os profissionais de sa&uacute;de acompanharem as tend&ecirc;ncias atuais e os &uacute;ltimos protocolos.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Gestão de atividades hospitalares",
      description: `<p>O armazenamento de dados em hospitais tamb&eacute;m &eacute; uma inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de que facilita o acompanhamento de atividades dos colaboradores. Isso significa que<strong> informa&ccedil;&otilde;es como hor&aacute;rios, disponibilidade e cargas de trabalho podem ser rastreadas o tempo todo</strong>.</p>

      <p>Ao ter essas informa&ccedil;&otilde;es atualizadas, o hospital pode reagir rapidamente se houver uma falha no servi&ccedil;o ou se o pessoal precisar de ajuda extra devido a uma situa&ccedil;&atilde;o de emerg&ecirc;ncia. Isso, naturalmente, impulsiona a colabora&ccedil;&atilde;o entre a equipe.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Atendimento seguro e virtual",
      description: `<p>O armazenamento de dados na nuvem, a partir de uma solu&ccedil;&atilde;o integrada, tamb&eacute;m pode fazer com que se consiga<strong> oferecer o atendimento virtual e seguro aos pacientes</strong>. Tudo isso integrado com os dados necess&aacute;rios.</p>

      <p>O m&eacute;dico atende de forma remota e segura &mdash; tend&ecirc;ncia da pandemia, mas que ainda faz sentido atualmente &mdash;, mas sem deixar de oferecer um bom servi&ccedil;o. A Amwell &eacute; um exemplo de plataforma de telessa&uacute;de nos EUA que opera a partir do Google Cloud.</p>

      <p><strong>Quer saber como as empresas t&ecirc;m aproveitado a nuvem na &aacute;rea da sa&uacute;de com a QI Network? Veja o <a target="_blank" href="/case/1">case da HCPA!</a></strong></p>

      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-saude/medico.jpg"
    },
    {
      title: "Armazenamento de dados na nuvem: por onde começar?",
      description: `<p>Depois de tudo que falamos, fica a d&uacute;vida: afinal, como fazer parte dessa inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de e contar com os benef&iacute;cios da nuvem na empresa? H&aacute; alguns passos que devem ser feitos para que se possa ter &ecirc;xito nesse investimento. Vamos falar sobre eles a seguir!</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Estabeleça os objetivos para sua migração",
      description: `<p>Antes de qualquer coisa, <strong>a equipe respons&aacute;vel pela migra&ccedil;&atilde;o deve se preocupar em entender quais s&atilde;o os seus objetivos</strong>. Afinal, quais s&atilde;o as expectativas da empresa em tomar essa decis&atilde;o?</p>

      <p>Plataformas como o Google podem ir al&eacute;m disso, permitindo o atendimento virtual nos hospitais, como falamos. Mas seja qual for sua prioridade nesse processo, &eacute; preciso t&ecirc;-la em mente.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Defina a ferramenta em nuvem para armazenamento",
      description: `<p>A segunda parte do processo &eacute; decidir qual solu&ccedil;&atilde;o para armazenamento sua organiza&ccedil;&atilde;o vai usar. Afinal, ela pode ser h&iacute;brida, privada ou p&uacute;blica.</p>

      <p>A melhor escolha vai <strong>depender do seu or&ccedil;amento, o n&iacute;vel de customiza&ccedil;&atilde;o, a reputa&ccedil;&atilde;o da empresa que oferece, e, claro, dos seus objetivos</strong>.</p>

      <p>O <strong>Google Cloud &eacute; uma das solu&ccedil;&otilde;es mais conhecidas, seguras e completas do mercado</strong>. Essa &eacute; uma op&ccedil;&atilde;o que atende diversos setores, como a sa&uacute;de, tendo um uso bastante adapt&aacute;vel.</p>

      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Conte com uma consultoria de migração",
      description: `<p>Levar todo seu hospital, cl&iacute;nica ou laborat&oacute;rio para a nuvem n&atilde;o &eacute; necessariamente uma tarefa simples e r&aacute;pida. Estamos falando de uma inova&ccedil;&atilde;o na &aacute;rea da sa&uacute;de que requer mais cuidado do que se imagina.</p>

      <p>Por isso, <strong>se o seu neg&oacute;cio n&atilde;o possui profissionais de sistemas e infraestrutura de TI qualificados para acompanhar esse processo, procure uma empresa capacitada para isso</strong>.</p>

      <p>Esse &eacute; o caso da QI Network, uma empresa parceira Premier do Google Cloud no Brasil, que j&aacute; apoia a migra&ccedil;&atilde;o de grandes empresas de sa&uacute;de como a Labtest e MaterDei.</p>

      <p>Fornecedores experientes v&atilde;o dar muito mais seguran&ccedil;a no processo e garantir que voc&ecirc; n&atilde;o sofra nenhuma perda durante a migra&ccedil;&atilde;o. Assim, a gest&atilde;o de projetos, arquivos e dados sens&iacute;veis v&atilde;o ser otimizados da melhor forma poss&iacute;vel.</p>

      <p>Levar a transforma&ccedil;&atilde;o digital para a sa&uacute;de &eacute; o grande prop&oacute;sito do armazenamento de dados na nuvem. Mas h&aacute; tamb&eacute;m alguns <a target="_blank" href="https://sucesso.qinetwork.com.br/como-superar-os-desafios-da-migracao">desafios da migra&ccedil;&atilde;o para a nuvem</a> que merecem ser conhecidos para que, assim, sejam superados. Leia nosso material sobre o tema e descubra!</p>

      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-saude/consultoria.jpg"
    },
  ]

}
