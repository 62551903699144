import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-jornada-inteligente',
  templateUrl: './jornada-inteligente.component.html',
  styleUrls: ['./jornada-inteligente.component.scss']
})
export class JornadaInteligenteComponent implements OnInit {
  
  public titulo = true;

  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public title: Title
  ) { }

  ngOnInit() {
    this.helper.hasNav = true;

    if (this.helper.isMobile === true) {
      this.titulo = false;
    }
    this.helper.pagina = 'modernizacao';

    this.title.setTitle('Qi Network - Jornada de Inteligência de Dados');
    this.meta.updateTag({ name: 'description', content: 'A jornada tem como propósito facilitar a implementação de Soluções Cloud de Inteligência de Dados para que sua empresa.' });
    this.meta.updateTag({ name: 'keywords', content: 'qinetwork, inteligência, google cloud, qi cloud, análise, soluções'});
    this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Jornada de Inteligência de Dados' });
  }
}
