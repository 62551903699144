import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseData } from '../cases/case-data';

@Component({
  selector: 'app-case-especifico',
  templateUrl: './case-especifico.component.html',
  styleUrls: ['./case-especifico.component.scss']
})
export class CaseEspecificoComponent implements OnInit {

  public case: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.case = CaseData.find(item => item.id == id);
  }

}
