import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-infinite-scroll",
  templateUrl: "./infinite-scroll.component.html",
  styleUrls: ["./infinite-scroll.component.scss"],
})
export class InfiniteScrollComponent implements OnInit {
  certifications = [
    "../../../../../assets/images/certifications/cloud architect.png",
    "../../../../../assets/images/certifications/Cloud Developer.png",
    "../../../../../assets/images/certifications/cloud digital leader.png",
    "../../../../../assets/images/certifications/cloud engineer.png",
    "../../../../../assets/images/certifications/cloud network engineer.png",
    "../../../../../assets/images/certifications/cloud architect.png",
    "../../../../../assets/images/certifications/Cloud Developer.png",
    "../../../../../assets/images/certifications/cloud digital leader.png",
    "../../../../../assets/images/certifications/cloud engineer.png",
    "../../../../../assets/images/certifications/cloud network engineer.png",
  ];

  certifications2 = [
    "../../../../../assets/images/certifications/cloud security engineer.png",
    "../../../../../assets/images/certifications/data engineer.png",
    "../../../../../assets/images/certifications/google cloud sales.png",
    "../../../../../assets/images/certifications/gws adm.png",
    "../../../../../assets/images/certifications/Machine Learning Engineer.png",
    "../../../../../assets/images/certifications/cloud security engineer.png",
    "../../../../../assets/images/certifications/data engineer.png",
    "../../../../../assets/images/certifications/google cloud sales.png",
    "../../../../../assets/images/certifications/gws adm.png",
    "../../../../../assets/images/certifications/Machine Learning Engineer.png",
  ];

  constructor() {}

  ngOnInit() {}
}
