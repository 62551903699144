import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-campanha-modernizacao',
  templateUrl: './campanha-modernizacao.component.html',
  styleUrls: ['./campanha-modernizacao.component.scss']
})
export class CampanhaModernizacaoComponent implements OnInit {
  public titulo = true;
  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public title: Title
  ) { }

  ngOnInit() {
    this.helper.hasNav = false;

    if (this.helper.isMobile === true) {
      this.titulo = false;
    }
    this.helper.pagina = "modernizacao";

    this.title.setTitle('Qi Network - Campanha de Modernização com Google Cloud');
    this.meta.updateTag({ name: 'description', content: 'Sua infra já está na melhor nuvem do mercado, tenha também os melhores serviços especializados para o Google Cloud!' });
    this.meta.updateTag({ name: 'keywords', content: 'qinetwork, campanha, google cloud, modernização, inteligência, produtividade'});
    this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Campanha de Modernização com Google Cloud' });
  }

}
