import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public navType = 'nav-trasparent';
  public hasFooter = true;
  public hasNav = true;
  public isMobile: boolean;
  public loading = new BehaviorSubject(false);
  public navOpen: boolean;
  public content = 'nav';

  public userToLogin: any;
  public drawer: MatDrawer;

  public pagina;

  public jInfra;
  public jProdutividade;
  public jInteligencia;

  public ajudaCinza;
  public ajudaAzul;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public http: HttpClient,
    public router: Router
  ) {
    this.breakpointObserver.observe(['(min-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
  }

  public scroll() { parent.scroll(0, 0); }

   get isLoading() { return this.loading.getValue(); }

  public link() {
    console.log(this.pagina);
    switch (this.pagina) {
      case 'lgpd':
        window.open('https://sucesso.qinetwork.com.br/lp-lgpd', '_blank');
        break;
      case 'novos':
        window.open('https://sucesso.qinetwork.com.br/lp-lgpd-novos', '_blank');
        break;
      case 'modernizacao':
        window.open('https://sucesso.qinetwork.com.br/lp-gcp', '_blank');
        break;

      default:
        this.router.navigate(['/programa-inovacao']);
        break;
    }
  }
}
