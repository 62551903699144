import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/service/helper.service";
import { webinars } from "../webinars/webinarsData";

@Component({
  selector: "app-webinars-detail",
  templateUrl: "./webinars-detail.component.html",
  styleUrls: ["../webinars/webinars.component.scss"],
})
export class WebinarsDetailComponent implements OnInit {
  constructor(
    public helper: HelperService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  selectedPlaylist: any = {};

  ngOnInit() {
    this.route.params.subscribe({
      next: (res: any) => {
        this.selectedPlaylist = webinars.find(
          (item: any) => item.slug == res.id
        );

        console.log(this.selectedPlaylist);
      },
    });
  }

  videoURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
