import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(private http: HttpClient) {}

  public changeMenu = new EventEmitter();
}
