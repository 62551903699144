import { Component, OnInit } from '@angular/core';
import { SaudeData } from './dados';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-saude-pillar-page',
  templateUrl: './saude-pillar-page.component.html',
  styleUrls: ['./saude-pillar-page.component.scss']
})
export class SaudePillarPageComponent implements OnInit {


  public topic = SaudeData;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Inovação na área da saúde e dados na nuvem: qual a relação?');

    this.meta.updateTag({ name: 'description', content: 'O armazenamento de dados na nuvem é fundamental na inovação na área da saúde. Mas como quais os benefícios e como implementá-lo? Leia o artigo e descubra!' });

    this.meta.updateTag({ property: 'og:title', content: 'Inovação na área da saúde e dados na nuvem: qual a relação?' });
    this.meta.updateTag({ property: 'og:description', content: 'O armazenamento de dados na nuvem é fundamental na inovação na área da saúde. Mas como quais os benefícios e como implementá-lo? Leia o artigo e descubra!' });


    this.meta.updateTag({ name: 'twitter:title', content: 'Inovação na área da saúde e dados na nuvem: qual a relação?' });
    this.meta.updateTag({ name: 'twitter:description', content: 'O armazenamento de dados na nuvem é fundamental na inovação na área da saúde. Mas como quais os benefícios e como implementá-lo? Leia o artigo e descubra!' });
  }

  ngOnDestroy() {
    this.title.setTitle('QI Network');
  }

}
