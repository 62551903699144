import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';
import { CaseData } from './case-data';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit {

  constructor(
    private dom: DomSanitizer,
    public helper: HelperService
  ) { }

  public casesSucesso: any = {};
  public cases: any = {};
  public selectedOption: any = 'todos'

  public depoimentos: any[] = [
    {
      id:1,
      video: this.getLinkVideo('https://www.youtube.com/embed/UPlwrUsKG2U'),
      descricao: '“Bruno Barreto, Head do Google Cloud na América Latina, fala sobre a parceria de quase dez anos entre o Google Cloud e a Qi Network.“',
      nome: 'Bruno Barreto',
      cargo: 'Head do Google Cloud na América Latina'
    },
    {
      id:2,
      video: this.getLinkVideo('https://www.youtube.com/embed/0d4d0Ix0KYE'),
      descricao: '“Gabriel Trevisan, Head of Technology da Endeavor Brasil, fala sobre inovação e a parceria com a Qi Network.“',
      nome: 'Gabriel Trevisan',
      cargo: 'Head of Technology da Endeavor Brasil'
    },
    {
      id:3,
      video: this.getLinkVideo('https://www.youtube.com/embed/kkKccC6mQ2o'),
      descricao: '“Fábio Alberti, Head de Tecnologia da Koerich, fala sobre como a parceria com a Qi Network foi fundamental para a inovação na empresa.“',
      nome: 'Fábio Alberti',
      cargo: 'Head de Tecnologia da Koerich'
    },
    {
      id:4,
      video: this.getLinkVideo('https://www.youtube.com/embed/9dGHavvaA_g'),
      descricao: '“Marcos Barcellos, Collaboration and Productivity Specialist do Google Cloud, fala sobre a Segurança de Dados com o Google Workspace.“',
      nome: 'Marcos Barcellos',
      cargo: 'Collaboration and Productivity Specialist do Google Cloud'
    },
    {
      id:5,
      video: this.getLinkVideo('https://www.youtube.com/embed/Z-S_YRgFpw0'),
      descricao: '“William Pamplona, CTO da Auto Avaliar, fala sobre como a parceria com a Qi Network foi fundamental durante a migração para o Google Workspace.“',
      nome: 'William Pamplona',
      cargo: 'CTO da Auto Avaliar'
    },
    {
      id:6,
      video: this.getLinkVideo('https://www.youtube.com/embed/vkKJy5eGJZs'),
      descricao: '“Rogério Bassete, Diretor de Tecnologia da Microwork, apresenta o case de sucesso de migração para a nuvem do Google Cloud com a parceria e apoio da Qi Network.“',
      nome: 'Rogério Bassete',
      cargo: 'Diretor de Tecnologia da Microwork'
    },
  ];
  public clientesPrimeiro: any[] = [
    {
      id: 1,
      logo: './assets/logos/institucional/carrefour.svg',
      alt: 'carrefour'
    },
    {
      id: 2,
      logo: './assets/logos/institucional/seculus.svg',
      alt: 'seculus'
    },
    {
      id: 3,
      logo: './assets/logos/institucional/resultados-digitais.svg',
      alt: 'resultados-digitais'
    },
    {
      id: 4,
      logo: './assets/logos/institucional/materdei.svg',
      alt: 'materdei'
    },
    {
      id: 5,
      logo: './assets/logos/institucional/santa-casa-bh.svg',
      alt: 'santa-casa-bh'
    }
  ];

  public clientesSegundo: any[] = [
    {
      id: 1,
      logo: './assets/logos/institucional/hospital-felicio-rocha.svg',
      alt: 'hospital-felicio-rocha'
    },
    {
      id: 2,
      logo: './assets/logos/institucional/conta-azul.svg',
      alt: 'conta-azul'
    },
    {
      id: 3,
      logo: './assets/logos/institucional/contabilizei.svg',
      alt: 'contabilizei'
    },
    {
      id: 4,
      logo: './assets/logos/institucional/sympla.svg',
      alt: 'sympla'
    },
    {
      id: 5,
      logo: './assets/logos/institucional/hotmart.svg',
      alt: 'hotmart'
    },
    {
      id: 6,
      logo: './assets/logos/institucional/seculus.svg',
      alt: 'seculus'
    }
  ];

  public clientesTerceiro: any[] = [
    {
      id: 1,
      logo: './assets/logos/institucional/mini-kalzone.svg',
      alt: 'mini-kalzone'
    },
    {
      id: 2,
      logo: './assets/logos/institucional/engelmig-energia.svg',
      alt: 'engelmig-energia'
    },
    {
      id: 3,
      logo: './assets/logos/institucional/crea-rj.svg',
      alt: 'crea-rj'
    },
    {
      id: 4,
      logo: './assets/logos/institucional/canal-rural.svg',
      alt: 'canal-rural'
    },
    {
      id: 5,
      logo: './assets/logos/institucional/master-mapurunga.svg',
      alt: 'master-mapurunga'
    },
    {
      id: 6,
      logo: './assets/logos/institucional/minas-tenis-club.svg',
      alt: 'minas-tenis-club'
    }
  ];

  public casesTESTE: any[] = [
    {
      id: 'auto-avaliar',
      // solucoes: 'infra',
      logo: './assets/logos/institucional/cases-auto-avaliar.svg',
      alt: 'auto-avaliar',
      tipo: 'Case Automatização de Processos',
      descricao: 'Neste case, entenda como se deu a implantação da nova aplicação, os desafios e soluções adotadas.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 'squadra',
      // solucoes: 'infra',
      logo: './assets/logos/institucional/cases-squadra.svg',
      alt: 'squadra',
      tipo: 'Migração para o Google Cloud em 30 dias',
      descricao: 'A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.',
      tempo: '8 min leitura',
      // icone: './assets/icons/institucional/i-play.svg',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 'microworks',
      // solucoes: 'dados',
      logo: './assets/logos/institucional/cases-microworks.svg',
      alt: 'microworks',
      tipo: 'Case GCP - Maior Disponibilidade de Infra',
      descricao: 'Saiba como a Qi Network conseguiu mais disponibilidade e segurança para o sistema da MicroWork.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 'metropoles',
      // solucoes: 'produtividade',
      logo: './assets/logos/institucional/metropoles.svg',
      alt: 'metropoles',
      tipo: 'Case GCP - Migração com segurança',
      descricao: 'Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 'frupo-gr',
      // solucoes: 'infra',
      logo: './assets/logos/institucional/cases-gr.svg',
      alt: 'gr',
      tipo: 'Case Inovação - WorkFlow de Pagamentos',
      descricao: 'Como o GRUPO GR otimizou seu Workflow de Pagamentos em apenas 51 dias.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 'tikal-tech',
      // solucoes: 'produtividade',
      logo: './assets/logos/institucional/cases-tikal-tech.svg',
      alt: 'tikal-tech',
      tipo: 'Case GCP - Migração com segurança e ...',
      descricao: 'Como a Tikal Tech migrou 7 bancos de dados, totalizando mais de 2TB sem interferir em sua operação.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 7,
      logo: './assets/logos/institucional/cases-gr.svg',
      alt: 'gr',
      tipo: 'Case G Suite - Inovação de processos',
      descricao: 'Inovação com G Suite: como o Grupo GR otimizou processos com ferramentas colaborativas.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 8,
      logo: './assets/logos/institucional/cases-seculus.svg',
      alt: 'seculus',
      tipo: 'Case Inovação de Processos',
      descricao: 'Transformação de processos e inovação do ambiente de trabalho com o G Suite.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 9,
      logo: './assets/logos/institucional/cases-carrefour.svg',
      alt: 'carrefour',
      tipo: 'Case de Inovação no RH',
      descricao: 'Inovação no RH: como o Grupo Carrefour otimizou o controle de qualidade de seus fornecedores.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 10,
      logo: './assets/logos/institucional/cases-hospital-felicio-rocho.svg',
      alt: 'hospital-felicio-rocho',
      tipo: 'Case G Suite - Otimização de Processos',
      descricao: 'Saiba como o Hospital Felício Rocho migrou para o G Suite e transformou seus colaboradores.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 11,
      logo: './assets/logos/institucional/cases-materdei.svg',
      alt: 'materdei',
      tipo: 'Case G Suite - Gestão de Mudanças',
      descricao: 'Descubra como a Rede Mater Dei de Saúde transformou sua comunicação e seus processos.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 13,
      logo: './assets/logos/institucional/cases-seculus.svg',
      alt: 'seculus',
      tipo: 'Case Qi Cloud Sprints',
      descricao: 'Como foi feita a automatização do processo de criação de catálogo de produtos com o Qi Cloud Sprints.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 14,
      logo: './assets/logos/institucional/cases-resultados-digitais.svg',
      alt: 'resultados-digitais',
      tipo: 'Case G Suite - Feedback 360º',
      descricao: 'Como a Resultados Digitais criou um modelo de feedback 360° com a ajuda do G Suite.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 15,
      logo: './assets/logos/institucional/cases-cdl.svg',
      alt: 'cdl',
      tipo: 'Case G Suite',
      descricao: 'Saiba como a migração para o G Suite otimizou processos na CDL de Belo Horizonte.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 16,
      logo: './assets/logos/institucional/cases-seculus.svg',
      alt: 'seculus',
      tipo: 'Case GCP',
      descricao: 'Como foi feita a automatização de dados através do Google Cloud Platform.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 17,
      logo: './assets/logos/institucional/cases-carrefour.svg',
      alt: 'carrefour',
      tipo: 'Case G Suite - Gestão de Mudanças',
      descricao: 'Como foi feita a migração das ferramentas no Grupo Carrefour para o G Suite.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 18,
      logo: './assets/logos/institucional/cases-kalzone.svg',
      alt: 'kalzone',
      tipo: 'Case G Suite',
      descricao: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur nam obcaecati.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 19,
      logo: './assets/logos/institucional/cases-araujo.svg',
      alt: 'araujo',
      tipo: 'Case G Suite',
      descricao: 'Como Drogaria Araujo, a maior rede de farmácias de Minas Gerais, migra para G Suite.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 20,
      logo: './assets/logos/institucional/cases-carsystem.svg',
      alt: 'carsystem',
      tipo: 'Case G Suite',
      descricao: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur nam obcaecati.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 21,
      logo: './assets/logos/institucional/cases-jamef.svg',
      alt: 'jamef',
      tipo: 'Case G Suite',
      descricao: 'Transformação da Comunicação Corporativa e Colaboração na Jamef com o G Suite.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 22,
      logo: './assets/logos/institucional/cases-tidetrends.svg',
      alt: 'tidetrends',
      tipo: 'Case G Suite',
      descricao: 'Como a Tide Trends inovou o acompanhamento dos projetos com seu time e com seus clientes.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 23,
      logo: './assets/logos/institucional/cases-uniodonto.svg',
      alt: 'uniodonto',
      tipo: 'Case G Suite',
      descricao: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur nam obcaecati.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    },
    {
      id: 24,
      logo: './assets/logos/institucional/cases-hospital-clinicas-porto-alegre.svg',
      alt: 'hospital-clinicas-porto-alegre',
      tipo: 'Case Qi Cloud Sprints',
      descricao: 'como o Hospital de Clínicas de Porto Alegre, otimizou a visualização dos resultados de sua pesquisa.',
      tempo: '8 min leitura',
      icone: './assets/icons/institucional/i-proximo-preto.svg',
      link: ''
    }
  ];

  public video;

  public activeDepoimentos: any = {};
  depoimentosConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    dots: true,
    cssEase: 'linear'
  }
  public activeClientesPrimeiro: any = {};
  public activeClientesTerceiro: any = {};
  clientesPTConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: ``,
    prevArrow: ``,
    autoplaySpeed: 0,
    speed:  2500,
    infinite: true,
    autoplay: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true
        }
      }
    ]
  };
  public activeClientesSegundo: any = {};
  clientesSConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: ``,
    prevArrow: ``,
    autoplaySpeed: 0,
    speed:  2500,
    infinite: true,
    autoplay: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true
        }
      }
    ]
  };
  public activeCases: any = {};
  casesConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    dots: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false
        }
      }
    ]
  }

  public Slide(slide: any) {
    console.log(slide);
    this.activeDepoimentos = slide;
    this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);
  }

  ngOnInit() {
    this.activeDepoimentos = this.depoimentos[0];
    this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);

    this.activeClientesPrimeiro = this.clientesPrimeiro[0];
    this.activeClientesSegundo = this.clientesSegundo[0];
    this.activeClientesTerceiro = this.clientesTerceiro[0];

    this.cases = CaseData;
    this.casesSucesso = this.cases;
    this.activeCases = this.casesSucesso[0];

    this.helper.ajudaCinza = true;
    this.helper.ajudaAzul = false;

  }

  gerarId(){
    for(let [i, cases] of this.cases.entries()){
     cases.id = ++i;
   }
   console.log(this.cases);
  }

  getLinkVideo(video){
    return this.dom.bypassSecurityTrustResourceUrl(video)
  }

  slickInit(e) {
    console.log('slick initialized');
  }
  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    this.activeDepoimentos = this.depoimentos[e.currentSlide];
   /*  this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);
 */
    this.activeClientesPrimeiro = this.clientesPrimeiro[e.currentSlide];
    this.activeClientesSegundo = this.clientesSegundo[e.currentSlide];
    this.activeClientesTerceiro = this.clientesTerceiro[e.currentSlide];

    this.activeCases = this.casesSucesso[e.currentSlide];
  }
  beforeChange(e) {}




  public filtroCases(filtro) {
    const spanTodos = document.getElementById('todos') as HTMLElement;
    const spanInfra = document.getElementById('infra') as HTMLElement;
    const spanDados = document.getElementById('dados') as HTMLElement;
    const spanProdutividade = document.getElementById('produtividade') as HTMLElement;

    if (filtro === 'todos') {
      this.casesSucesso = this.cases.filter(x => x.solucoes);
      spanTodos.style.color = '#AE1E22';
      spanInfra.style.color = '#707070';
      spanDados.style.color = '#707070';
      spanProdutividade.style.color = '#707070';
    }else {
      this.casesSucesso = this.cases.filter(x => x.solucoes === filtro);
      switch (filtro) {
        case 'infra':
          spanTodos.style.color = '#707070';
          spanInfra.style.color = '#AE1E22';
          spanDados.style.color = '#707070';
          spanProdutividade.style.color = '#707070';
          break;
        case 'dados':
          spanTodos.style.color = '#707070';
          spanInfra.style.color = '#707070';
          spanDados.style.color = '#AE1E22';
          spanProdutividade.style.color = '#707070';
          break;
        case 'produtividade':
          spanTodos.style.color = '#707070';
          spanInfra.style.color = '#707070';
          spanDados.style.color = '#707070';
          spanProdutividade.style.color = '#AE1E22';
          break;
        default:
          break;
      }
    }
  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
