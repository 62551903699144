import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-verticais',
  templateUrl: './verticais.component.html',
  styleUrls: ['./verticais.component.scss']
})
export class VerticaisComponent implements OnInit {

  constructor( public helper: HelperService) { }

  public atuacoes: any[] = [
    {
      id:1,
      img: './assets/icons/home/i-industria.svg',
      alt:'industria',
      titulo: 'Indústria',
      link: 'industria'
    },
    {
      id:2,
      img: './assets/icons/home/i-logistica.svg',
      alt:'logistica',
      titulo: 'Logística',
      link: 'logistica'
    },
    {
      id:3,
      img: './assets/icons/home/i-saude.svg',
      alt:'saude',
      titulo: 'Saúde',
      link: 'saude'
    },
    {
      id:4,
      img: './assets/icons/home/i-tecnologia.svg',
      alt:'tecnologia',
      titulo: 'Tecnologia',
      link: 'tecnologia'
    },
    {
      id:5,
      img: './assets/icons/home/i-varejo.svg',
      alt:'varejo',
      titulo: 'Varejo',
      link: 'varejo'
    }
  ];

  ngOnInit() {

    this.helper.ajudaCinza = true;
    this.helper.ajudaAzul = false;

  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
