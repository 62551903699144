import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  constructor(public helper: HelperService) { }

  ngOnInit() {

    this.helper.ajudaCinza = true;
    this.helper.ajudaAzul = false;

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;

  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
