import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-servicos-inteligencia',
  templateUrl: './servicos-inteligencia.component.html',
  styleUrls: ['../servicos/servicos.component.scss']
})
export class ServicosInteligenciaComponent implements OnInit {

  public analise: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Atendemos o Chamado de Análise e Orientação.',
      descricaoP1: 'Classificamos o Chamado conforme o Especialista.',
      descricaoP2: 'Alocamos Especialista Técnico.',
      descricaoP3: 'Agendamos Atendimento.',
      descricaoP4: 'Enviar o Formulário de Coleta do Desafio.',
      descricaoP5: 'Confirmamos o Atendimento junto ao cliente.',
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Entendimento do desafio.',
      descricaoP1: 'Entendimento do impacto financeiro.',
      descricaoP2: 'Entendimento do impacto estratégico.',
      descricaoP3: 'Entendimento do impacto de risco.',
      descricaoP4: 'Formalizamos o entendimento do Desafio.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Orientação',
      descricao: 'Apresentação de alternativas.',
      descricaoP1: 'Apresentação de cases de sucesso.',
      descricaoP2: 'Apresentação de arquiteturas sugeridas.',
      descricaoP3: 'Apresentação do esforço para as soluções.',
      descricaoP4: 'Orientamos alternativas de esforço para as soluções apresentadas.',
      descricaoP5: 'Formalizamos do fechamento do atendimento.',
    }
  ];
  public mapeamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Mapeamento',
      descricao: 'Apresentação do cronograma do projeto, fases do projeto e o que será entregue para o cliente ao final do projeto.',
      descricaoP2: 'Enviamos formulários de entrevistas técnicas para entendimento dos desafios da empresa.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Nosso especialista técnico conduz uma reunião para validar os dados enviados nos formulários e os desafios apresentados.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Documentação',
      descricao: 'Reunião de apresentação do documento final do mapeamento técnico onde nosso especialista entrega o laudo técnico sobre a solução do desafio.',
    }
  ];
  public treinamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Aqui definimos quem participa do treinamento, data, horário e o convite que será enviado aos participantes.',
      descricaoP2: 'Nesta fase definimos também o nível do conteúdo do treinamento, criação da apresentação do treinamento e alocação do instrutor especialista no conteúdo.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Treinamento',
      descricao: 'No dia do treinamento, nós orientamos nas etapas de organização do evento para que seja o mais produtivo possível.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Avaliação',
      descricao: 'Envio do material e certificação para os participantes. Fazemos a avaliação de aprendizagem e treinamento para garantir que houve o máximo de retenção do conteúdo.',
    }
  ];
  public design: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-a.svg',
      numero: './assets/icons/i-um-a.svg',
      texto: 'Entendimento dos Desafios',
      descricao: 'Vamos por meio de pesquisas coletar dados sobre os desafios, classificar e priorizar.',
      descricaoP2: 'Nesta etapa conduzimos uma dinâmica de duas horas onde vamos aprofundar sobre um tema escolhido, seja de segurança ou melhoria de processo.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-a.svg',
      numero: './assets/icons/i-dois-a.svg',
      texto: 'Ideação da Solução',
      descricao: 'Nesta segunda fase facilitamos uma dinâmica de grupo com o objetivo de mapear as ideias idealizadas por todos os envolvidos no desafio, assim como mapeamos os impactos que essas soluções trariam para o negócio da empresa..',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-a.svg',
      numero: './assets/icons/i-tres-a.svg',
      texto: 'Validação do Escopo Técnico e de Negócio',
      descricao: 'Nesta fase usamos todas as informações coletadas na ideação da solução e o protótipo sugerido, de forma a esboçar um resumo do processo de construção da solução.',
    },
    {
      id: 4,
      imagem:'./assets/icons/i-check-a.svg',
      numero: './assets/icons/i-quatro-a.svg',
      texto: 'Planejamento da Execução | SOW',
      descricao: 'A fase final do processo é produzido um documento ( SOW ) e proposição de todos os itens do processo. Aqui é apresentado o protótipo criado com uma dinâmica de validação.',
    }
  ];
  public solution: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Desenvolvimento',
      descricao: 'Na fase de Desenvolvimento é quando a solução ganha vida e tudo o que havia sido planejado na Fase anterior de Design de Solução é executado pelos especialistas.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Teste',
      descricao: 'Nesta fase de Testes, o Analista de Testes realiza um teste geral na solução desenvolvida, regras de negócios e funcionalidades previstas no SOW. Após os testes e correções, o Product Owner válida a solução aprova a publicação da solução no ambiente de produção.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Nesta última fase do processo, o Gerente de Projetos junto com o Product Owner, apresentam para o Cliente, a solução desenvolvida e formaliza a entrega da solução.',
    }
  ];
  public implantacao: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Preparação',
      descricao: 'Aqui  ambos os times Qi Network e Cliente se alinham para preparar o ambiente para executar a implementação. Nesta fase os times se conhecem através da reunião de Kick-off interna e com o cliente.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Implementação',
      descricao: 'Nesta etapa será executada a implementação das configurações e requisitos de negócio pré-definidas e conferidas por ambas as empresas.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Na última etapa é realizado a homologação por parte do cliente quanto a todos os entregáveis que foram pré-acordados no plano de trabalho.',
    }
  ];
  public care: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-verde.svg',
      numero: './assets/icons/i-um-verde.svg',
      texto: 'Avaliação',
      descricao: 'Nesta etapa recebemos a solicitação do cliente e avaliamos a sua classificação para darmos encaminhamento para o seu atendimento.',
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-verde.svg',
      numero: './assets/icons/i-dois-verde.svg',
      texto: 'Atendimento',
      descricao: 'Executamos o atendimento de suporte técnico conforme a sua classificação e acordo de SLA. Neste atendimento poderá ser simplesmente uma resposta por e-mail como dependendo do tipo de necessidade poderá ser entrado em contato com o cliente para apoiá-lo junto ao problema relatado ou dúvida técnica levantada.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-verde.svg',
      numero: './assets/icons/i-tres-verde.svg',
      texto: 'Monitoramento',
      descricao: 'O monitoramento da solução cloud é realizado através de NOC da Qi Network. O monitoramento é proativo e através dos alertas pré parametrizados conseguimos acompanhar em tempo real a disponibilidade das soluções cloud..',
    }
  ];

  public tiposAnalise: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Migração de Dados para Data Warehouse / Data Lake',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Desenvolvimento de Coleta, classificação, tratamento e transformação de Dados',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Desenvolvimento da Análise de Dados',
    },
    {
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Desenvolvimento de Dashboard de visualização de dados',
    }
  ];
  public tiposMapeamento: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Ingestão de Dados',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Classificação de Dados',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Preparação de Dados',
    },
    {
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Armazenamento de Dados',
    },
    {
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Disponibilidade de dados',
    },
    {
      img: './assets/icons/i-seis-v.svg',
      texto: 'Análise de Dados',
    },
    {
      img: './assets/icons/i-sete-v.svg',
      texto: 'Visualização de Dados',
    },
    {
      img: './assets/icons/i-oito-v.svg',
      texto: 'Automatização de Relatório',
    }
  ];
  public tiposTreinamento: any[] = [
    {
      img: './assets/icons/i-um-v.svg',
      texto: 'Treinamento Usuários Finais',
    },
    {
      img: './assets/icons/i-dois-v.svg',
      texto: 'Treinamentos Especialistas de Ferramenta',
    },
    {
      img: './assets/icons/i-tres-v.svg',
      texto: 'Treinamentos Técnicos para Admins',
    }
  ];
  public tiposDesign: any[] = [
    {
      img: './assets/icons/i-um-a.svg',
      texto: 'Migração de Dados de Email, Agenda e Documentos',
    },
    {
      img: './assets/icons/i-dois-a.svg',
      texto: 'Migração de Dados ERP para o GCP',
    },
    {
      img: './assets/icons/i-tres-a.svg',
      texto: 'Migração de Dados para Data Warehouse',
    },
    {
      img: './assets/icons/i-quatro-a.svg',
      texto: 'Migração de Dados para Data Lake',
    },
    {
      img: './assets/icons/i-cinco-a.svg',
      texto: 'Backup de Dados para o GCP',
    },
    {
      img: './assets/icons/i-seis-a.svg',
      texto: 'Migração de Dados On-premises para o GCP',
    },
    {
      img: './assets/icons/i-sete-a.svg',
      texto: 'Migração de Dados da Nuvem para o GCP',
    },
    {
      img: './assets/icons/i-oito-a.svg',
      texto: 'Migração de Dados do DaaS',
    },
    {
      img: './assets/icons/i-nove-a.svg',
      texto: 'Migração de Dados da Aplicação Web',
    },
    {
      img: './assets/icons/i-dez-a.svg',
      texto: 'Desenvolvimento de Coletores de Dados',
    },
    {
      img: './assets/icons/i-onze-a.svg',
      texto: 'Desenvolvimento de tratamento, classificação e transformação de Dados',
    },
    {
      img: './assets/icons/i-doze-a.svg',
      texto: 'Desenvolvimento da Análise de Dados',
    },
    {
      img: './assets/icons/i-treze-a.svg',
      texto: 'Desenvolvimento de Dashboard de visualização de dados',
    }
  ];
  public tiposSolution: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Análise de Dados',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Coletores de Dados',
    },
    {
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Tratamento, classificação e Transformação de Dados',
    },
    {
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Dashboard de Visualização de Dados',
    }
  ];
  public tiposImplantacao: any[] = [
    {
      img: './assets/icons/i-um-azul.svg',
      texto: 'Migração de Dados para Data Warehouse',
    },
    {
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Migração de Dados para Data Lake',
    },
    {
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Backup de Dados para o GCP',
    },
    {
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Migração de Dados de Email, Agenda e Documentos',
    },
    {
      img: './assets/icons/i-cinco-azul.svg',
      texto: 'Migração de Dados da Aplicação Web',
    },
    {
      img: './assets/icons/i-seis-azul.svg',
      texto: 'Migração de Dados On-premises para o GCP',
    },
    {
      img: './assets/icons/i-sete-azul.svg',
      texto: 'Migração de Dados da Nuvem para o GCP',
    },
    {
      img: './assets/icons/i-oito-azul.svg',
      texto: 'Migração de Dados ERP para o GCP',
    },
    {
      img: './assets/icons/i-nove-azul.svg',
      texto: 'Migração de Dados do DaaS',
    }
  ];

  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public titleMeta: Title
  ) { }
  public tiposModal;
  public title;
  
  public activeAnalise: any = {};
  public activeDesign: any = {};
  public activeSolution: any = {};
  jornadaConfig = {
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: ``,
          prevArrow: ``,       
          dots: true,   
          autoplay: true,
        }
      }
    ]
  };

  public Slide(slide: any) {
    console.log(slide);
    this.activeAnalise = slide;
    this.activeDesign = slide;
    this.activeSolution = slide;
  }
  ngOnInit() {
    this.activeAnalise = this.analise[0];
    this.activeDesign = this.design[0];
    this.activeSolution = this.solution[0];
    this.helper.hasNav = true;
    this.helper.pagina = 'modernizacao';
    // this.titleMeta.setTitle('Qi Network - Serviços de Jornada de Inteligência de Dados');
    // this.meta.updateTag({ name: 'description', content: 'A jornada tem como propósito facilitar a implementação de Soluções Cloud de Inteligência de Dados para que sua empresa.' });
    // this.meta.updateTag({ name: 'keywords', content: 'qinetwork, inteligência, google cloud, qi cloud, análise, soluções'});
    // this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Serviços de Jornada de Inteligência de Dados' });
  }

  public enter(cor?, id?) {
    const vermelho = document.getElementsByClassName('prev-next')[id] as HTMLImageElement;
    const amarelo = document.getElementsByClassName('prev-next-a')[id] as HTMLImageElement;
    const azul = document.getElementsByClassName('prev-next-azul')[id] as HTMLImageElement;
    switch (cor) {
      case 'vermelho':
        if (id === 1 || id === 3 || id === 5) {
          vermelho.src='./assets/icons/i-elipse-seta-D-V.svg';
        } else if (id === 0 || id === 2 || id === 4) {
          vermelho.src='./assets/icons/i-elipse-seta-E-V.svg';
        }
        break;
      case 'amarelo':
        if (id === 1) {
          amarelo.src='./assets/icons/i-elipse-seta-D-A.svg';
        } else if (id === 0) {
          amarelo.src='./assets/icons/i-elipse-seta-E-A.svg';
        }
        break;

      case 'azul':
        if (id === 1) {
          azul.src='./assets/icons/i-elipse-seta-D-AZ.svg';
        } else if (id === 0) {
          azul.src='./assets/icons/i-elipse-seta-E-AZ.svg';
        }
        break;
    
      default:
        break;
    }
  }
  public leave(cor?, id?) {
    const img = document.getElementsByClassName('prev-next')[id] as HTMLImageElement;    
    const amarelo = document.getElementsByClassName('prev-next-a')[id] as HTMLImageElement;
    const azul = document.getElementsByClassName('prev-next-azul')[id] as HTMLImageElement;
    switch (cor) {
      case 'vermelho':
        if (id === 1 || id === 3|| id === 5) {
          img.src='./assets/icons/i-elipse-seta-D.svg';
        } else if (id === 0 || id === 2|| id === 4) {
          img.src='./assets/icons/i-elipse-seta-E.svg';
        }
        break;

      case 'amarelo':
        if (id === 1) {
          amarelo.src='./assets/icons/i-elipse-seta-D.svg';
        } else if (id === 0) {
          amarelo.src='./assets/icons/i-elipse-seta-E.svg';
        }
        break;

    case 'azul':
      if (id === 1) {
        azul.src='./assets/icons/i-elipse-seta-D.svg';
      } else if (id === 0) {
        azul.src='./assets/icons/i-elipse-seta-E.svg';
      }
      break;
    
      default:
        break;
    }
  }
  
  slickInit(e) { console.log('slick initialized'); }
  breakpoint(e) { console.log('breakpoint'); }
  afterChange(e) {
      this.activeAnalise = this.analise[e.currentSlide];
      this.activeDesign = this.design[e.currentSlide];
      this.activeSolution = this.solution[e.currentSlide];
  }
  beforeChange(e) {}

  public openModal(tipo) {
    const h3 = document.getElementById('exampleModalLabel') as HTMLBodyElement;
      switch (tipo) {
        case 'analise':
          this.tiposModal = this.tiposAnalise;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Análise e Orientação Técnica'
          break;
        case 'mapeamento':
            this.tiposModal = this.tiposMapeamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Mapeamento Técnico'
          break;
        case 'treinamento':
            this.tiposModal = this.tiposTreinamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Treinamento Técnico'
          break;
        case 'design':
            this.tiposModal = this.tiposDesign;
          h3.style.color = '#D5A629';
          this.title = 'Tipos de Design de soluções'
          break;
        case 'solution':
            this.tiposModal = this.tiposSolution;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Desenvolvimento de Solução'
          break;
        case 'implementacao':
            this.tiposModal = this.tiposImplantacao;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Implementação de Solução'
          break;
      
        default:
          break;
      }
    }

}
