export const CaseData: any = [
  {
    id: 40,
    logo: "./assets/logos/institucional/allp-fit.webp",
    alt: "allp-fit",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Rede de academias comprometida com a qualidade de vida, se tornou um case do Google com a implementação do Google Workspace pela Qi Network.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Allp Fit impulsiona comunicação e colaboração com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-allp-fit.webp",
    caseAlt: "allp-fit",
    caseDescricao1: "A Allp Fit Academia, uma rede de academias comprometida com a qualidade de vida, se tornou um case do Google com a implementação do Google Workspace pela Qi Network.",
    caseDescricao2: 'A franqueadora utilizava um provedor que apresentava limitações significativas, além dos altos custos para a expansão de armazenamento. Descubra como a parceria da Allp Fit com o Google e a Qi Network superou desafios de comunicação e colaboração entre as equipes, transformando a maneira de interagir e trabalhar juntas.',
    link: "https://sucesso.qinetwork.com.br/case-allp-fit",
  },
    {
    id: 39,
    logo: "./assets/logos/institucional/grupo-abc.png",
    alt: "grupo-abc",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Entenda como a Qi Network pôde auxiliar a colaboração e produtividade no dia-a-dia da empresa!",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Grupo ABC adota Google Workspace em todas as unidades da empresa",
    caseImg: "./assets/images/institucional/img-case-grupo-abc.webp",
    caseAlt: "grupo-abc",
    caseDescricao1: "Em 13 de setembro de 1982, uma simples loja operava sem muita diversidade de produtos, quadro de colaboradores reduzido, ausência de alguns setores como açougue e padaria e, para atendimento aos clientes, havia apenas um caixa.",
    caseDescricao2: 'Mesmo assim, todas essas limitações não atrapalharam o sonho e vontade de crescer do empresário Valdemar Martins do Amaral: era iniciada a história do Grupo ABC. Entenda como a Qi pôde auxiliar a colaboração e produtividade no dia-a-dia da empresa!',
    link: "https://sucesso.qinetwork.com.br/case-super-abc",
  },
  {
    id: 38,
    logo: "./assets/logos/institucional/uniodonto-sc.png",
    alt: "uniodonto-sc",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "A Uniodonto de SC é uma Cooperativa Odontológica sem fins lucrativos, organizada por Cirurgiões Dentistas que se associam segundo os princípios do cooperativismo.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Uniodonto reduz 65% de custos de infra com Google Cloud",
    caseImg: "./assets/images/institucional/img-case-uniodonto-sc.webp",
    caseAlt: "uniodonto-sc",
    caseDescricao1: "A Uniodonto de SC é uma Cooperativa Odontológica sem fins lucrativos, organizada por Cirurgiões Dentistas que se associam segundo os princípios do cooperativismo, com a finalidade de prestar assistência odontológica a um custo acessível e ao alcance da população, dentro da realidade brasileira.",
    caseDescricao2: 'Descubra como a Cooperativa conseguiu reduzir em 65% os custos com o Google Cloud!',
    link: "https://sucesso.qinetwork.com.br/case-uniodonto-sc",
  },
  {
    id: 36,
    logo: "./assets/logos/institucional/cases-seculus.svg",
    alt: "seculus",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "A Seculus da Amazônia S.A. é uma das mais tradicionais empresas do segmento relojoeiro do Brasil e pertence ao Grupo Seculus.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Seculus e a configuração de APIs e uso de Cotas na nuvem",
    caseImg: "./assets/images/institucional/img-case-seculus4.webp",
    caseAlt: "seculus",
    caseDescricao1: "A Seculus da Amazônia S.A. é uma das mais tradicionais empresas do segmento relojoeiro do Brasil e pertence ao Grupo Seculus.",
    caseDescricao2: '“A QI acionou o time da Seculus de forma proativa, demonstrando quais são os parâmetros de configuração na Cloud do Google poderiam ser revisados e ativados através de treinamento e acompanhamento da configuração. O ambiente de APIs da Seculus no Google foi configurado de modo a minimizar os riscos de utilização destes recursos por usuários maliciosos em caso de possíveis invasões.", disse Eder Sacramento, Gestor de TI da empresa, sobre o serviço prestado pela Qi Network.',
    link: "https://sucesso.qinetwork.com.br/case-seculus-da-amazonia-api",
  },
  {
    id: 35,
    logo: "./assets/logos/institucional/jamef.svg",
    alt: "jamef",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "A Jamef é uma das maiores empresas do Brasil no setor de transporte urgente de mercadorias e soluções inteligentes para logística.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Jamef investiu na segurança unificando sistemas de autenticação",
    caseImg: "./assets/images/institucional/img-case-jamef.webp",
    caseAlt: "jamef",
    caseDescricao1: "A Jamef é uma das maiores empresas do Brasil no setor de transporte urgente de mercadorias e soluções inteligentes para logística, com atuação nos modais aéreo e rodoviário em todo o território nacional.",
    caseDescricao2: "Entenda como a Jamef investiu em segurança para sua equipe, através de sistemas de autenticação.",
    link: "https://sucesso.qinetwork.com.br/case-jamef-seguranca",
  },
  {
    id: 34,
    logo: "./assets/logos/institucional/dealer-sites.svg",
    alt: "dealer-sites",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "A DealerSites é uma startup paranaense que atua na criação de soluções voltadas para a digitalização do mercado automotivo.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Dealer Sites melhora o pipeline de entrega de software em Cloud",
    caseImg: "./assets/images/institucional/img-case-dealer-sites.webp",
    caseAlt: "dealer-sites",
    caseDescricao1: "A DealerSites é uma startup paranaense que atua na criação de soluções voltadas para a digitalização do mercado automotivo. Desenvolvem websites, exclusivamente para concessionárias de veículos, com foco em performance. A tecnologia é desenvolvida para maximizar o número de visitantes e intensificar a conversão de leads de seus clientes.",
    link: "https://sucesso.qinetwork.com.br/case-dealer-sites",
  },
  {
    id: 33,
    logo: "./assets/logos/institucional/gower-negocios.png",
    alt: "gower",
    categoria: "Case Google Cloud",
    tipo: "Case GCP",
    descricao:
      "A Gower é uma consultoria de negócios dedicada a ajudar empresas a alcançarem o seu máximo potencial.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "A Gower desenvolve aplicação nativa em nuvem Google Cloud",
    caseImg: "./assets/images/institucional/img-case-gower.webp",
    caseAlt: "gower",
    caseDescricao1: "A Gower é uma consultoria de negócios dedicada a ajudar empresas a alcançarem o seu máximo potencial. Combinam expertise especializada, abordagem personalizada e soluções inovadoras para impulsionar o crescimento, a eficiência e a sustentabilidade de seus clientes.",
    caseDescricao2: "Entenda como a Gower desenvolveu aplicação nativa com Google Cloud!",
    link: "https://sucesso.qinetwork.com.br/case-gower-negocios",
  },
    {
    id: 32,
    logo: "./assets/logos/institucional/diprotec.png",
    alt: "diprotec",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "A empresa contava com um serviço de se-mail que apresentava problemas com indisponibilidade e instabilidade constantes.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Diprotec mais produtiva através dos recursos do Google Workspace",
    caseImg: "./assets/images/institucional/img-case-diprotec.png",
    caseAlt: "diprotec",
    caseDescricao1: "A empresa contava com um serviço de e-mail que apresentava problemas com indisponibilidade e instabilidade constantes.",
    caseDescricao2: "A Qi teve um papel fundamental neste projeto onde ajudou a empresa Diprotec a se tornar mais produtiva através da entrega dos serviços de implementação do Google Workspace, análise e orientação da migração de dados de e-mail, agendas e contatos.",
    link: "https://sucesso.qinetwork.com.br/case-diprotec",
  },
  {
    id: 31,
    logo: "./assets/logos/institucional/bem-protege.png",
    alt: "bem-protege",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Para o projeto da Bem Protege a Qi Network realizou a migração dos serviços de e-mail, chat e arquivos para a nuvem.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Bem Protege e a inovação através do Google Workspace",
    caseImg: "./assets/images/institucional/img-case-bem-protege.png",
    caseAlt: "bem-protege",
    caseDescricao1: "Para o projeto da Bem Protege a Qi Network realizou a migração dos serviços de e-mail, chat e arquivos para a nuvem, junto com o gerenciamento de senha e unificação dos serviços do Google Workspace.",
    link: "https://sucesso.qinetwork.com.br/case-bem-protege",
  },
  {
    id: 30,
    logo: "./assets/logos/institucional/cartorio-xavier.png",
    alt: "cartorio-xavier",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "No projeto do Cartório Xavier, a Qi teve o papel de implementar o Google Workspace e realizar a migração de dados de email.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Cartório Xavier otimiza comunicação com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-cartorio-xavier.png",
    caseAlt: "cartorio-xavier",
    caseDescricao1: "No projeto do Cartório Xavier, a Qi teve o papel de implementar o Google Workspace e realizar a migração de dados de email. Também foram fornecidos mais de 3 treinamentos e onboarding de gestão de assinaturas.",
    link: "https://sucesso.qinetwork.com.br/case-cartorio-xavier",
  },
  {
    id: 29,
    logo: "./assets/logos/institucional/labtest.png",
    alt: "labtest",
    categoria: "work-transformation",
    tipo: "Case Google Cloud",
    descricao:
      "Veja como a Labtest, maior indústria brasileira de diagnóstico in vitro, conseguiu fazer tudo isso com as soluções em nuvem do Google!",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Como a Labtest otimizou a tomada de decisão baseada em dados",
    caseImg: "./assets/images/institucional/img-labtest.png",
    caseAlt: "labtest",
    caseDescricao1: "Na Era dos Dados, um grande volume de informações se tornam relevantes para um negócio, certo? Mas como processá-los, analisá-los e disponibilizá-los rapidamente para os seus tomadores de decisão?",
    caseDescricao2: "Neste case veja como a Labtest, maior indústria brasileira de diagnóstico in vitro, conseguiu fazer tudo isso com as soluções em nuvem do Google!",
    caseDescricao3:
      "No material você vai conferir:",
    caseDescricao4: "• Como era a situação antes da migração para a nuvem;",
    caseDescricao5:
      "• Quais soluções Google foram adotadas;",
    caseDescricao6: "• Principais resultados obtidos",
    link: "https://sucesso.qinetwork.com.br/case-labtest",
  },
  {
    id: 28,
    logo: "./assets/logos/institucional/compliance-solucoes.png",
    alt: "compliance-solucoes",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Melhorar a administração do serviço de e-mails era o objetivo principal da Compliance Soluções ao procurar a Qi Network.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Compliance Soluções: Como gerenciar e-mails com segurança e aproveitar os benefícios do Google Workspace",
    caseImg: "./assets/images/institucional/img-compliance-solucoes.png",
    caseAlt: "compliance-solucoes",
    caseDescricao1:
      "Melhorar a administração do serviço de e-mails era o objetivo principal da Compliance Soluções ao procurar a Qi Network. O apoio do Programa de Inovação na Nuvem, por sua vez, trouxe ganhos que foram além dessa expectativa. ",
    caseDescricao2:
      "A empresa passou a contar com um gerenciamento eficiente de e-mails, e também obteve ganhos como a disponibilidade de diversas aplicações do Google Workspace. A economia e praticidade gerada pelo custo único por usuário para uso de todos os softwares representou uma grande vantagem com a mudança. ",
    caseDescricao3:
      "No eBook “Compliance Soluções: como gerenciar e-mails com segurança e aproveitar os benefícios do Google Workspace”, você vai saber em detalhes:",
    caseDescricao4: "• A atuação e soluções oferecidas pela Compliance;",
    caseDescricao5:
      "• Benefícios da implantação do Google Workspace pela Qi Network;",
    caseDescricao6: "• Detalhes e diferenciais do projeto de migração;",
    caseDescricao7:
      "• Jornada de Produtividade e Colaboração da Qi Network.",
    link: "https://sucesso.qinetwork.com.br/case-compliance-solucoes",
  },

  {
    id: 26,
    logo: "./assets/logos/institucional/logo_3778.svg",
    alt: "grupo-3778",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "O Grupo decidiu então migrar seus dados para o Google Workspace em parceria com a Qi Network e excelentes resultados foram obtidos.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Case Grupo 3778: migração para o Google Workspace em 90 dias",
    caseImg: "./assets/images/institucional/img-grupo-3778.png",
    caseAlt: "grupo-3778",
    caseDescricao1:
      "Manter-se num mercado tão regulamentado e competitivo como o de saúde exige, cada vez mais, a capacidade de inovar. ",
    caseDescricao2:
      "A 3778 Tecnologia, TEG Saúde, Imtep e Implus Care — empresas já reconhecidas pelo fornecimento de soluções de excelência para a saúde — se uniram para fundar o Grupo 3778, que hoje já é um dos maiores players do setor. Mas para que essa fusão se tornasse realidade, a organização precisou promover a integração de todas as suas companhias. ",
    caseDescricao3:
      "O Grupo decidiu então migrar seus dados para o Google Workspace em parceria com a Qi Network e excelentes resultados foram obtidos. Para contar melhor essa experiência, nós elaboramos um material com o qual esperamos incentivá-lo a também a inovar na nuvem.",
    caseDescricao4: "No material você vai conferir:",
    caseDescricao5:
      "• Os motivos que levaram o Grupo 3778 a migrar para o Google Workspace;",
    caseDescricao6: "• Desafios enfrentados antes da implementação;",
    caseDescricao7:
      "• As etapas e os principais resultados da migração e muito mais!",
    link: "https://sucesso.qinetwork.com.br/case-grupo-3778",
  },
  {
    id: 25,
    logo: "./assets/logos/institucional/cases-koerich.jpg",
    alt: "koerich",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Para superar os desafios da LGPD, o Koerich decidiu inovar com as soluções do Google Workspace",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Como o Koerich tem superado os desafios de Segurança de Dados e da LGPD com o Google Workspace",
    caseImg: "./assets/images/institucional/img-koerich.png",
    caseAlt: "koerich",
    caseDescricao1:
      "Para superar os desafios da LGPD , o Koerich — uma das marcas varejistas mais importantes de Santa Catarina —, decidiu inovar com as soluções do Google Workspace. Com isso, a empresa conseguiu ter mais controle de acesso de dados, facilidade em recuperar informações e ainda aumentar o espaço de armazenamento.",
    caseDescricao2:
      "No eBook “Como o Koerich tem superado os desafios da LGPD com o Google Workspace”, apresentamos todos os detalhes da parceria do Koerich com a Qi Network, os principais resultados obtidos e as soluções do Workspace que permitiram alcançar um nível avançado na segurança de informações.",
    caseDescricao3: "Veja o que você vai conferir neste material:",
    caseDescricao4: "• Koerich: desafios de Segurança de Dados e da LGPD.",
    caseDescricao5:
      "• Como o Koerich otimizou a segurança com o Google Workspace.",
    caseDescricao6:
      "• Koerich e Qi Network: parceria de sucesso pela inovação em segurança de dados.",
    link: "https://sucesso.qinetwork.com.br/case-koerich",
  },
  {
    id: 24,
    logo: "./assets/logos/institucional/case-minas-tenis-clube.png",
    alt: "minas-tenis-clube",
    categoria: "work-transformation",
    tipo: "Case Google Cloud",
    descricao:
      "Com custos elevados para manter sistemas na nuvem, o Minas Tênis Clube procurou a Qi Network",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Case Minas Tênis Clube: inovação e produtividade com a nuvem do Google",
    caseImg: "./assets/images/institucional/img-case-minas-tenis-clube.png",
    caseAlt: "minas-tenis-clube",
    caseDescricao1: "Como a tecnologia Google impulsionou a inovação em nuvem",
    caseDescricao2:
      "Com dificuldades de gestão e comunicação em sua plataforma de e-mail e com custos elevados para manter alguns dos seus sistemas na nuvem, o Minas Tênis Clube — tradicional entidade sociodesportiva e cultural brasileira — procurou a Qi Network para iniciar um novo processo de inovação em nuvem com tecnologias Google.",
    caseDescricao3:
      "Nós contamos tudo sobre essa parceria no material “Case Minas Tênis Clube: inovação e produtividade com a nuvem do Google”.",
    caseDescricao4: "Baixe agora mesmo e confira:",
    caseDescricao5: "• Desafios antes da implantação.",
    caseDescricao6:
      "• Soluções implementadas: Google Workspace e Google Cloud Platform.",
    caseDescricao7:
      "• Minas Tênis Clube e Qi Network: uma parceria de negócios.",
    caseDescricao8:
      "• Programa de Inovação na Nuvem da Qi Network: jornadas e etapas.",
    link: "https://sucesso.qinetwork.com.br/case-minas-tenis-clube",
  },
  {
    id: 23,
    logo: "./assets/logos/institucional/case-endeavor.png",
    alt: "case-endeavor",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "A Endeavor percebeu a necessidade de agregar mais segurança aos dados sob sua responsabilidade.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Segurança de Dados: conheça o case de sucesso da Endeavor",
    caseImg: "./assets/images/institucional/img-case-endeavor.png",
    caseAlt: "endeavor",
    caseDescricao1:
      "Presente no Brasil desde o ano 2000, a Endeavor é a rede global formada pelas empreendedoras e empreendedores que mais crescem no mundo, e são grandes exemplos para o nosso país. A organização estimula o crescimento e o impacto daqueles à frente das scale-ups, empresas escaláveis e inovadoras que cresceram mais de 20% durante os últimos três anos consecutivos.",
    caseDescricao2:
      "Por produzir um grande volume de conhecimento e informações, a Endeavor percebeu a necessidade de agregar mais segurança aos dados sob sua responsabilidade. Em uma decisão estratégica, contou com a ajuda dos especialistas da Qi Network para migrar para a versão Business do Google Workspace.",
    caseDescricao3:
      "Entenda neste case como era a situação antes dessa mudança, os desafios enfrentados, soluções adotadas e os principais resultados. ",
    link: "https://sucesso.qinetwork.com.br/case-endeavor",
  },
  {
    id: 22,
    logo: "./assets/logos/institucional/cases-auto-avaliar.svg",
    alt: "auto-avaliar",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Neste case, entenda como se deu a implantação da nova aplicação, os desafios e soluções adotadas.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Como a automatização de processos promove a inovação na Auto Avaliar",
    caseImg: "./assets/images/institucional/img-case-autoavaliar.png",
    caseAlt: "autoavaliar",
    caseDescricao1:
      "A automatização de processos promove a inovação em empresas de qualquer tamanho e setor, e é cada vez mais importante para se manter no mercado. Mas para adotar e desenvolver as soluções ideais para o seu negócio, é necessário buscar apoio de especialistas no assunto, como a Qi Network.",
    caseDescricao2:
      "A Auto Avaliar, empresa brasileira do ramo automotivo, decidiu migrar para o Google Workspace para otimizar suas atividades, reduzir o retrabalho e incentivar a colaboração entre seus funcionários. Com o apoio da Qi Network, a companhia desenvolveu uma nova solução de automatização de processos que lhe permitiu não só superar seus desafios, como criar uma cultura de inovação. ",
    caseDescricao3:
      "Neste case, entenda como se deu a implantação da nova aplicação, entenda como era a situação antes, os desafios enfrentados, soluções adotadas e os principais resultados.",
    link: "https://sucesso.qinetwork.com.br/case-autoavaliar",
  },
  {
    id: 21,
    logo: "./assets/logos/institucional/cases-squadra.svg",
    alt: "squadra",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Migração para o Google Cloud em 30 dias",
    caseImg: "./assets/images/institucional/img-case-squadra.png",
    caseAlt: "squadra",
    caseDescricao1:
      "Você deseja implantar novas ferramentas e tecnologias na sua empresa para se diferenciar no mercado, mas ainda não sabe exatamente no que investir? Talvez a solução seja a migração para a nuvem!",
    caseDescricao2:
      "Na chamada “Era dos Dados”, as organizações devem garantir a segurança e proteção das informações. Caso isso não aconteça, não apenas a sua credibilidade é colocada em xeque, como medidas judiciais podem ser aplicadas quando se tratar de informações de terceiros.",
    caseDescricao3:
      "A cloud computing chega para resolver a preocupação das empresas e suas equipes de TI com tudo o que é relacionado a manutenção de uma infraestrutura de TI.  Ao migrar para a nuvem, o provedor é quem fica responsável por todos os cuidados com suas informações, incluindo disponibilidade e proteção.",
    caseDescricao4:
      "Mas a nuvem não é importante somente para armazenar dados. Ela pode fomentar a colaboração entre equipes através de ferramentas colaborativas, o que tem impacto direto sobre a produtividade e expansão dos negócios.",
    caseDescricao5:
      "Pensando em proteger suas informações e otimizar o trabalho e a comunicação, a Squadra, em parceria com a Qi Network, decidiu adotar as soluções em nuvem do Google Workspace e migrar para o Google Cloud Platform. ",
    link: "https://sucesso.qinetwork.com.br/case-squadra",
  },
  {
    id: 20,
    logo: "./assets/logos/institucional/cases-microworks.svg",
    alt: "microworks",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "Saiba como a Qi Network conseguiu mais disponibilidade e segurança para o sistema da MicroWork.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Como a MicroWork garantiu maior disponibilidade em sua infraestrutura na nuvem",
    caseImg: "./assets/images/institucional/img-case-microwork.png",
    caseAlt: "microwork",
    caseDescricao1:
      "A Qi Network apoiou a Microwork durante a migração de sua infraestrutura de TI para a nuvem, aumentando a disponibilidade de seu ERP para concessionárias de veículos.",
    caseDescricao2:
      "Os problemas de disponibilidade causavam insatisfação nos clientes e estresse na equipe interna.",
    caseDescricao3:
      "A solução veio com a infraestrutura em nuvem do Google Cloud Platform. Todo o processo de migração e capacitação da equipe para o novo ambiente foi realizado pela Qi Network, que hoje é considerada mais que um suporte para a empresa, mas uma verdadeira parceira.",
    caseDescricao4:
      "Saiba mais sobre como a Qi Network conseguiu muito mais disponibilidade e segurança para o sistema da MicroWork, além de abrir as portas para a inovação e a modernização da empresa na nuvem.",
    caseDescricao5: "Sobre a Microwork Softwares",
    caseDescricao6:
      "Fundada em 1995, a MicroWork Softwares é uma empresa nacional, localizada em Americana/SP, que oferece soluções completas em sistema para concessionárias de 2 e 4 rodas. Líder no mercado há mais de 2 décadas, com uma infraestrutura completa, moderna e um suporte técnico especializado.",
    link: "https://sucesso.qinetwork.com.br/case-microwork",
  },
  {
    id: 19,
    logo: "./assets/logos/institucional/metropoles.svg",
    alt: "metropoles",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud",
    caseImg: "./assets/images/institucional/img-case-metropoles.png",
    caseAlt: "metropoles",
    caseDescricao1:
      "O Metrópoles, quinto portal de notícias mais acessado do Brasil, contou com o apoio da Qi Network, que atuou não apenas na migração, mas também na capacitação da equipe para lidar com o Google Cloud no dia a dia.",
    caseDescricao2:
      "A realização de uma migração cloud de um grande portal de notícias pode assustar. Empresas desse porte ficam receosas de migrar a infraestrutura de TI, acreditando que o site pode ficar indisponível e gerar prejuízo.",
    caseDescricao3:
      "Mas o Metrópoles mostrou que é possível fazer uma migração assim sem nenhum problema. Ele é o quinto portal de notícias mais acessado do Brasil e, com o apoio da Qi Network, migrou para o Google Cloud Platform sem que o site ficasse fora do ar.",
    caseDescricao4:
      "Quer saber mais sobre como foi esse processo? Baixe o case completo e entenda como a Qi Network atuou nessa migração cloud ágil e segura.",
    link: "https://sucesso.qinetwork.com.br/case-metropoles",
  },
  {
    id: 18,
    logo: "./assets/logos/institucional/cases-gr.svg",
    alt: "gr",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Como o GRUPO GR otimizou seu Workflow de Pagamentos em apenas 51 dias.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Como o Grupo GR otimizou seu Workflow de Pagamentos em apenas 51 dias",
    caseImg: "./assets/images/institucional/img-case-gr.png",
    caseAlt: "gr",
    caseDescricao1:
      "Você tem controle completo sobre todo o fluxo de pagamentos da sua empresa? Consegue facilmente identificar as solicitações de orçamento, os responsáveis por cada pedido e localizar as notas fiscais? ",
    caseDescricao2:
      "Se você não está muito certo sobre o controle do seu fluxo, saiba que soluções baseadas em ferramentas colaborativas do Google Workspace podem ajudá-lo nesse processo. Trata-se de um investimento inteligente numa ferramenta para unificar sistemas em uma mesma plataforma, mais segura e ágil.",
    caseDescricao3:
      "O GRUPO GR percebeu que seu grande volume de dados exigia o uso de uma plataforma mais robusta e com melhor custo benefício. Com uma cultura de inovação já madura, a empresa optou novamente pela nuvem — e novamente com a Qi Network — para otimizar seus processos com uso de soluções colaborativas.",
    caseDescricao4:
      "Para atender as demandas do GRUPO GR, a Qi Network desenvolveu uma solução de Workflow de Pagamentos baseada no Google Workspace, o que permitiu a superação dos desafios impostos pela antiga ferramenta.",
    link: "https://sucesso.qinetwork.com.br/case-workflow-grupo-gr",
  },
  {
    id: 17,
    logo: "./assets/logos/institucional/cases-tikal-tech.svg",
    alt: "tikal-tech",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "Como a Tikal Tech migrou 7 bancos de dados, totalizando mais de 2TB sem interferir em sua operação.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Como a Tikal Tech migrou com segurança e agilidade para o Google Cloud Platform",
    caseImg: "./assets/images/institucional/img-case-tikal.png",
    caseAlt: "tikal",
    caseDescricao1:
      "A Qi Network trabalhou lado a lado com a Tikal Tech para uma migração tranquila para o Google Cloud Platform, oferecendo todo o suporte e orientação necessários.",
    caseDescricao2:
      "A consultoria e orientação da Qi Network foram fundamentais para que a migração da plataforma de computação em nuvem da Tikal Tech para o Google Cloud Platform ocorresse de forma segura e bem sucedida, aumentando o poder computacional da empresa e potencializando a produtividade da equipe e a inovação na organização.",
    caseDescricao3:
      "Confira como a Tikal Tech migrou 7 bancos de dados, totalizando mais de 2TB sem interferir em sua operação e ganhando ainda mais tração para explorar novas tecnologias e poder de inovação na prática.",
    caseDescricao4: "Sobre a Tikal Tech",
    caseDescricao5:
      "Tikal Tech é uma empresa de tecnologia de Inteligência Artificial aplicada à inovação no segmento jurídico. Desenvolve uma tecnologia chamada ELI - Enhanced Legal Intelligence - que implementa um robô como assistente jurídico de alta performance capaz de automatizar vários processos de trabalho, auxiliando advogados, escritórios de advocacia e empresas em problemas específicos com enormes ganhos de produtividade e qualidade.",
    link: "https://sucesso.qinetwork.com.br/case-tikal",
  },
  {
    id: 16,
    logo: "./assets/logos/institucional/cases-gr.svg",
    alt: "gr",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Inovação com Google Workspace: como o Grupo GR otimizou processos com ferramentas colaborativas.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Inovação com Google Workspace: como o Grupo GR otimizou processos com ferramentas colaborativas",
    caseImg: "./assets/images/institucional/img-case-gr2.png",
    caseAlt: "gr",
    caseDescricao1:
      "Você já deu início a um programa de inovação organizacional na sua empresa?",
    caseDescricao2:
      "Esse é um passo que tem se tornado fundamental num mercado cada vez mais exigente e competitivo. E como envolve a transformação profunda na estrutura da empresa, desde o ambiente até o tipo de relacionamento praticado com clientes e parceiros, essa é uma estratégia que deve ser bem planejada e realizada por etapas.",
    caseDescricao3:
      "Entre os diferentes tipos de inovação organizacional, o de processos é um que pode gerar bons resultados num curto espaço de tempo, desde que a implantação do projeto seja bem conduzida.",
    caseDescricao4:
      "O Grupo GR, por exemplo, viu a necessidade de alterar a maneira de acessar e editar suas informações e dados, por isso optou por adotar ferramentas colaborativas. Nesse contexto, a Qi Network surgiu como a parceira ideal para contribuir na implantação das soluções do Google Workspace na empresa.",
    link: "https://sucesso.qinetwork.com.br/case-grupo-gr",
  },
  {
    id: 15,
    logo: "./assets/logos/institucional/cases-seculus.svg",
    alt: "seculus",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Transformação de processos e inovação do ambiente de trabalho com o Google Workspace.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Seculus da Amazônia: Transformação de processos e inovação com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-seculus.png",
    caseAlt: "seculus",
    caseDescricao1:
      "A Qi Network esteve à frente da inovação de processos que promoveu a transformação do ambiente de trabalho da Seculus da Amazônia.",
    caseDescricao2:
      "A empresa, que possui longa jornada no mercado relojoeiro no Brasil, realizou a migração dos sistemas para a nuvem do Google e gerou vários resultados positivos, como o aumento da produtividade, colaboração e melhor organização das agendas dos funcionários, bem como a maior mobilidade para os funcionários. Além disso, no decorrer do tempo, diversos processos que faziam parte do modus operandi da empresa foram aprimorados com o apoio de especialistas em inovação da Qi Network.",
    caseDescricao3:
      "Reunimos no material a seguir, o case completo da Seculus da Amazônia, contando todas as etapas e mudanças geradas pelo Google Workspace, bem como os resultados apresentados ao longo de dois anos intensos de mudanças e aprendizados.",
    caseDescricao4: "Sobre a Seculus da Amazônia",
    caseDescricao5:
      "A Seculus da Amazônia é uma das maiores empresas do polo relojoeiro nacional, representando marcas importantes como Seculus, Mondaine, Speedo, Guess, Casio entre outras. Completando 30 anos em 2019, faz parte do Grupo Seculus, que atua, além do mercado de relógios, nos segmentos financeiro, imobiliário, TI e reflorestamento. unidades.",
    link: "https://sucesso.qinetwork.com.br/case-seculus-amazonia",
  },
  {
    id: 14,
    logo: "./assets/logos/institucional/cases-carrefour.svg",
    alt: "carrefour",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Inovação no RH: como o Grupo Carrefour otimizou o controle de qualidade de seus fornecedores.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Inovação no RH: como o Grupo Carrefour otimizou o controle de qualidade de seus fornecedores",
    caseImg: "./assets/images/institucional/img-case-carrefour.png",
    caseAlt: "carrefour",
    caseDescricao1: "Como está o movimento de inovação na sua empresa?",
    caseDescricao2:
      "Sabemos que a inovação é um processo longo e contínuo, que envolve várias frentes e processos. Mesmo empresas consolidadas no mercado, podem inovar em diferentes aspectos para promover melhorias no atendimento, controle de dados, serviços realizados, entre outras atividades.",
    caseDescricao3:
      "O Grupo Carrefour Brasil, por exemplo, precisou rever a maneira de coletar, gerenciar e analisar seus dados dos fornecedores. Com a parceria da Qi Network isso foi possível, com o desenvolvimento de uma nova aplicação automatizada.",
    link: "https://sucesso.qinetwork.com.br/case-carrefour-rh",
  },
  {
    id: 13,
    logo: "./assets/logos/institucional/cases-hospital-felicio-rocho.svg",
    alt: "hospital-felicio-rocho",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Saiba como o Hospital Felício Rocho migrou para o Google Workspace e transformou seus colaboradores.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Otimização de processos no Hospital Felício Rocho com o Google Workspace",
    caseImg:
      "./assets/images/institucional/img-case-hospital-felicio-rocho.png",
    caseAlt: "hospital-felicio-rocho",
    caseDescricao1:
      "A Qi Network apoiou o Hospital Felício Rocho em sua evolução digital trazendo inovação para os processos da instituição.",
    caseDescricao2:
      "Saiba como o Hospital Felício Rocho migrou para o Google Workspace e transformou a forma de pensar, planejar e agir dos colaboradores, além de otimizar processos específicos da instituição a partir da personalização de serviços do Google Workspace, garantindo novos fluxos de trabalho com mais eficiência, segurança e agilidade.",
    caseDescricao3: "Sobre o Hospital Felício Rocho",
    caseDescricao4:
      "O Hospital Felício Rocho é um dos maiores e mais importantes hospitais gerais de Belo Horizonte. A área profissional congrega cerca de 700 médicos, muitos dos quais de renome internacional. Nas áreas administrativas e técnicas, atua um corpo funcional de aproximadamente 2.500 empregados.",
    link: "https://sucesso.qinetwork.com.br/case-felicio-rocho",
  },
  {
    id: 12,
    logo: "./assets/logos/institucional/cases-materdei.svg",
    alt: "materdei",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Descubra como a Rede Mater Dei de Saúde transformou sua comunicação e seus processos.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Rede Mater Dei de Saúde promove inovação com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-materdei.png",
    caseAlt: "materdei",
    caseDescricao1:
      "A Qi Network esteve à frente do processo de transformação do trabalho que otimizou processos e promoveu inovação na rede de hospitais.",
    caseDescricao2:
      "Descubra como a Rede Mater Dei de Saúde transformou sua comunicação e tornou seus processos mais ágeis, de forma segura, integrada e ainda oferecendo atendimento humanizado aos pacientes.",
    caseDescricao3: "Sobre a Rede Mater Dei de Saúde",
    caseDescricao4:
      "O Mater Dei é uma instituição de saúde que surgiu há quase 40 anos com objetivo de ser um centro de cultura, ciência e humanismo. A rede é formada por 3 unidades hospitalares e 1 unidade de planejamento, com um quadro de 3 mil colaboradores diretos, mais de 4 mil médicos e mais de mil leitos ativos. São mais de 300 mil interações com pacientes por mês, realizadas entre todas as unidades.",
    link: "https://sucesso.qinetwork.com.br/case-mater-dei",
  },
  {
    id: 10,
    logo: "./assets/logos/institucional/cases-seculus.svg",
    alt: "seculus",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Como foi feita a automatização do processo de criação de catálogo de produtos com o Qi Cloud Sprints.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Seculus da Amazônia, automatização de catálogo de produtos para o processo de vendas com o Qi Cloud Sprints",
    caseImg: "./assets/images/institucional/img-case-seculus2.png",
    caseAlt: "seculus",
    caseDescricao1:
      "Com a ajuda dos especialistas da Qi Network, a Seculus da Amazônia desenvolveu uma solução que permite que seus representantes e varejistas tenham em mãos os catálogos em diferentes modelos e sempre atualizados",
    caseDescricao2:
      "Tendo os catálogos como uma importante ferramenta de vendas, é imprescindível que seus representantes e varejistas tenham em mãos os mesmos apresentados em diferentes modelos e atualizados, bem como promoções especiais e diversas outras características planificadas pela equipe interna de Marketing e Produtos.",
    caseDescricao3:
      "Confira aqui como o serviço Qi Cloud Sprints permitiu automatizar esse processo.",
    caseDescricao4: "Sobre a Seculus da Amazônia",
    caseDescricao5:
      "A Seculus da Amazônia é uma das maiores empresas do polo relojoeiro nacional, representando marcas importantes como Seculus, Mondaine, Speedo, Guess, Casio entre outras. Completando 30 anos em 2019, faz parte do Grupo Seculus, que atua, além do comércio de relógios, nos segmentos financeiro, imobiliário, TI e reflorestamento.",
    caseDescricao6:
      "Baixe agora o material e confira como foi feita a automatização do processo de criação de catálogo de produtos através do Qi Cloud Sprints.",
    link: "https://sucesso.qinetwork.com.br/case-seculus-catalogo",
  },
  {
    id: 9,
    logo: "./assets/logos/institucional/cases-resultados-digitais.svg",
    alt: "resultados-digitais",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Como a Resultados Digitais criou um modelo de feedback 360° com a ajuda do Google Workspace.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Case de Sucesso Resultados Digitais",
    caseImg: "./assets/images/institucional/img-case-resultados-digitais.png",
    caseAlt: "resultados-digitais",
    caseDescricao1: "Sobre a empresa:",
    caseDescricao2: "• Nº de funcionários: + de 500",
    caseDescricao3: "• Segmento: Software",
    caseDescricao4: "• Florianópolis – SC",
    caseDescricao5: "• resultadosdigitais.com.br",
    caseDescricao6: "• Produtos Google utilizados:",
    caseDescricao7: "– Google Workspace",
    link: "https://drive.google.com/file/d/1XTKWeL_cFMBcsVW5SEPCUUrN1FHGVeXh/view?usp=sharing",
  },
  {
    id: 8,
    logo: "./assets/logos/institucional/cases-cdl.svg",
    alt: "cdl",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Saiba como a migração para o Google Workspace otimizou processos na CDL de Belo Horizonte.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Transformação dos processos de trabalho com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-cdl-bh.png",
    caseAlt: "cdl-bh",
    caseDescricao1:
      "Saiba como a migração para o Google Workspace otimizou processos na CDL de Belo Horizonte!",
    caseDescricao2:
      "Confira como era a situação antes do Google Workspace, quais foram os desafios, as ações realizadas para o engajamento dos colaboradores e os resultados obtidos!",
    caseDescricao3:
      "Como representante legítima da classe junto aos poderes públicos, no âmbito municipal, estadual e federal, CDL/BH visa sempre o desenvolvimento e fortalecimento da iniciativa privada. Por isso, firmou com a Qi Network uma parceria que visava avaliar os processos de comunicação e entender como o Google Workspace pode contribuir para melhoria, padronização, centralização das informações e da comunicação interna como um todo.",
    caseDescricao4:
      "Baixe agora o Caso de Sucesso e confira como foi feita a migração da CDL/BH para o Google Workspace.",
    link: "https://sucesso.qinetwork.com.br/case-cdl-bh",
  },
  {
    id: 7,
    logo: "./assets/logos/institucional/cases-seculus.svg",
    alt: "seculus",
    categoria: "infra-e-dados",
    tipo: "Case Google Cloud",
    descricao:
      "Como foi feita a automatização de dados através do Google Cloud Platform.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Seculus da Amazônia, automação de dados através da solução Google Cloud Platform",
    caseImg: "./assets/images/institucional/img-case-seculus3.png",
    caseAlt: "seculus",
    caseDescricao1:
      "Empresa do Grupo Seculus, um dos maiores conglomerados empresariais de Minas Gerais, adota Google Cloud Platform.",
    caseDescricao2:
      "Confira como a Seculus da Amazônia automatizou dados, possibilitando uma visão estratégica, dinâmica e confiável dos principais números da empresa.",
    caseDescricao3: "Sobre a Seculus da Amazônia",
    caseDescricao4:
      "A Seculus da Amazônia é uma das maiores empresas do polo relojoeiro nacional, representando marcas importantes como Seculus, Mondaine, Speedo, Guess, Casio entre outras. Completando 30 anos em 2019, faz parte do Grupo Seculus, que atua, além do comércio de relógios, nos segmentos financeiro, imobiliário, TI e reflorestamento.",
    caseDescricao5:
      "Baixe agora o Caso de Sucesso e confira como foi feita a automatização de dados através do Google Cloud Platform",
    link: "https://sucesso.qinetwork.com.br/case-seculus-dashboard",
  },
  {
    id: 6,
    logo: "./assets/logos/institucional/cases-carrefour.svg",
    alt: "carrefour",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Como foi feita a migração das ferramentas no Grupo Carrefour para o Google Workspace.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo:
      "Colaboração, engajamento e transformação dos processos de trabalho com o Google Workspace",
    caseImg: "./assets/images/institucional/img-case-carrefour2.png",
    caseAlt: "carrefour",
    caseDescricao1:
      "Como a gestão da mudança promoveu o sucesso na migração das ferramentas no Grupo Carrefour",
    caseDescricao2:
      "Descubra como o serviço de gestão da mudança da Qi Network promoveu o sucesso na migração das ferramentas Google Workspace para mais de 15 mil pessoas no Grupo Carrefour.",
    caseDescricao3:
      "No Grupo Carrefour, que tem mais de 70 mil funcionários, o processo de mudança das ferramentas de trabalho despertou dúvidas em alguns colaboradores, que foram superadas com as ações do plano de gestão da mudança executado pela Qi Network. No final do projeto, que envolveu mais de 15 mil pessoas, a aceitação foi de 80% dos colaboradores.",
    caseDescricao4:
      "Baixe agora o Caso de Sucesso e confira como foi feita a migração do Carrefour para o Google Workspace.",
    link: "https://sucesso.qinetwork.com.br/case-de-sucesso-carrefour",
  },
  {
    id: 5,
    logo: "./assets/logos/institucional/cases-kalzone.svg",
    alt: "kalzone",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Saiba como a Qi Network facilitou a comunicação entre todas as áreas de operação e franqueados da Mini Kalzone.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Caso de Sucesso Mini Kalzone",
    caseImg: "./assets/images/institucional/img-case-kalzone.png",
    caseAlt: "kalzone",
    caseDescricao1: "Sobre a empresa:",
    caseDescricao2: "• Licenças: 140",
    caseDescricao3: "• Segmento: Varejo",
    caseDescricao4: "• Sede em Palhoça-SC",
    caseDescricao5: "• lojas franqueadas em todo o Brasil",
    caseDescricao6: "• Produtos Google utilizados:",
    caseDescricao7: "– Google Workspace",
    link: "https://drive.google.com/file/d/1Fm22064e1MopOSa4tmzprpNkVTNTNv-n/view?usp=sharing",
  },
  {
    id: 4,
    logo: "./assets/logos/institucional/cases-carsystem.svg",
    alt: "carsystem",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Precisando de um gerenciador de emails de alta performance, a Carsystem fez a contratação do Google Workspace.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Caso de Sucesso Car System",
    caseImg: "./assets/images/institucional/img-case-carsystem.png",
    caseAlt: "carsystem",
    caseDescricao1: "Sobre a empresa:",
    caseDescricao2: "• 323 licenças",
    caseDescricao3: "• Segmento: Segurança",
    caseDescricao4: "• Sede em São Paulo, atuação em todo Brasil",
    caseDescricao5: "• Produtos Google utilizados:",
    caseDescricao6: "– Google Workspace",
    link: "https://docs.google.com/presentation/d/1nxIgt_DD1eQ2yug1L5E1WKkiZ1Vr-jM-K05RHvrPSIA/present?slide=id.g238c0816de_0_24",
  },
  {
    id: 3,
    logo: "./assets/logos/institucional/cases-jamef.svg",
    alt: "jamef",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Transformação da Comunicação Corporativa e Colaboração na Jamef com o Google Workspace.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Case de Sucesso Jamef",
    caseImg: "./assets/images/institucional/img-case-jamef.png",
    caseAlt: "jamef",
    caseDescricao1: "Sobre a empresa:",
    caseDescricao2:
      "• Especializada em cargas fracionadas de alto valor agregado",
    caseDescricao3: "• Segmento: Logística",
    caseDescricao4: "• Produtos Google utilizados:",
    caseDescricao5: "– Google Workspace",
    link: "https://drive.google.com/file/d/1mapG7X8h5NiKBFe-DAMNVF62XM__kndi/view?usp=sharing",
  },
  {
    id: 2,
    logo: "./assets/logos/institucional/cases-tidetrends.svg",
    alt: "tidetrends",
    categoria: "work-transformation",
    tipo: "Case Google Workspace",
    descricao:
      "Como a Tide Trends inovou o acompanhamento dos projetos com seu time e com seus clientes.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Caso de Sucesso Tide Trends",
    caseImg: "./assets/images/institucional/img-case-tidetrends.png",
    caseAlt: "tidetrends",
    caseDescricao1: "Sobre a empresa:",
    caseDescricao2: "• Segmento: Relações Públicas",
    caseDescricao3: "• Florianópolis",
    caseDescricao4: "• tidetrends.com.br",
    caseDescricao5: "• Produtos Google utilizados:",
    caseDescricao6: "– Google Workspace",
    link: "https://docs.google.com/presentation/d/1jTOmI2fT5PRaL1sZtxfbMOGLCOPcQ2sJA54RC5FS2Lc/preview?slide=id.g23209c697e_3_158",
  },
  {
    id: 1,
    logo: "./assets/logos/institucional/cases-hospital-clinicas-porto-alegre.svg",
    alt: "hospital-clinicas-porto-alegre",
    categoria: "google-workspace",
    tipo: "Case Google Workspace",
    descricao:
      "Como o Hospital de Clínicas de Porto Alegre, otimizou a visualização dos resultados de sua pesquisa.",
    tempo: "8 min leitura",
    icone: "./assets/icons/institucional/i-proximo-preto.svg",
    caseTitulo: "Dashboard de Pesquisa da Experiência do Paciente no HCPA",
    caseImg:
      "./assets/images/institucional/img-case-hospital-clinica-porto-alegre.png",
    caseAlt: "hospital-clinica-porto-alegre",
    caseDescricao1:
      "Confira como a Qi Network, que esteve à frente de todo o processo, ajudou o Hospital de Clínicas de Porto Alegre a integrar soluções na Cloud do Google.",
    caseDescricao2:
      "Descubra como o Hospital de Clínicas de Porto Alegre, instituição pública integrante da rede de hospitais universitários do Ministério da Educação (MEC), otimizou a visualização dos resultados de sua pesquisa de satisfação por meio de um Dashboard, auxiliando na tomada de decisão estratégica.",
    caseDescricao3: "Sobre o Hospital de Clínicas de Porto Alegre",
    caseDescricao4:
      "Instituição pública integrante da rede de hospitais universitários do Ministério da Educação (MEC) e vinculada academicamente à Universidade Federal do Rio Grande do Sul (UFRGS).",
    link: "https://sucesso.qinetwork.com.br/case-hcpa",
  },
];
