import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-jornada-solucoes',
  templateUrl: './jornada-solucoes.component.html',
  styleUrls: ['./jornada-solucoes.component.scss']
})
export class JornadaSolucoesComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {
  }

}
