import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-servicos-modernizacao',
  templateUrl: './servicos-modernizacao.component.html',
  styleUrls: ['../servicos/servicos.component.scss']
})
export class ServicosModernizacaoComponent implements OnInit {

  public analise: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Nesta fase o Suporte irá receber a demanda e entender que é um chamado de Análise e Orientação Técnica, alocando o Gestor do Suporte para verificar o contexto do chamado e internamente ver o especialista no assunto.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Neste momento é realizada uma videoconferência gravada entre a equipe do cliente e nossa equipe de especialistas no tema.',
      descricaoP2: 'Durante o primeiro momento da reunião a equipe do cliente detalha o desafio e nossa equipe tira todas as dúvidas para que antes de iniciar a orientação, todos tenham o mesmo entendimento quanto ao desafio foco na Análise.'
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Orientação',
      descricao: 'Com o entendimento do desafio inicia-se a cocriação das orientações técnicas de alternativas de solução para os desafios.',
      descricaoP2: 'Mostramos exemplos de soluções criadas relacionadas com o desafio apresentado e fazemos a orientação técnica de alternativas de solução do desafio.'
    }
  ];
  public mapeamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Mapeamento',
      descricao: 'Reunião de apresentação do cronograma, fases do projeto e o que será entregue para o cliente ao final do projeto.',
      descricaoP2: 'Enviamos formulários de entrevistas técnicas para entendimento dos desafios da empresa.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Análise',
      descricao: 'Nosso especialista técnico conduz uma reunião para validar os dados enviados nos formulários e os desafios apresentados.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Documentação',
      descricao: 'Reunião de apresentação do documento final do mapeamento técnico onde nosso especialista entrega o laudo técnico sobre a solução do desafio.',
    }
  ];
  public treinamento: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-v.svg',
      numero: './assets/icons/i-um-v.svg',
      texto: 'Preparação',
      descricao: 'Aqui definimos de quem participa do treinamento, data, horário e o convite que será enviado aos participantes.',
      descricaoP2: 'Nesta fase definimos também o nível do conteúdo do treinamento, criação da apresentação do treinamento e alocação do instrutor especialista no conteúdo.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-v.svg',
      numero: './assets/icons/i-dois-v.svg',
      texto: 'Treinamento',
      descricao: 'No dia do treinamento, nós orientamos nas etapas de organização do evento para que seja o mais produtivo possível.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-v.svg',
      numero: './assets/icons/i-tres-v.svg',
      texto: 'Avaliação',
      descricao: 'Envio do material e certificação para os participantes. Fazemos a avaliação de aprendizagem e treinamento para garantir que houve o máximo de retenção do conteúdo.',
    }
  ];
  public design: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-a.svg',
      numero: './assets/icons/i-um-a.svg',
      texto: 'Entendimento dos Desafios',
      descricao: 'Nesta etapa conduzimos uma dinâmica de duas horas onde vamos aprofundar sobre um tema escolhido pelo cliente dentro do universo de Modernização de Infra.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-a.svg',
      numero: './assets/icons/i-dois-a.svg',
      texto: 'Ideação da Solução',
      descricao: 'Nesta segunda fase facilitamos uma dinâmica de grupo com o objetivo de mapear as ideias idealizadas por todos os envolvidos no desafio, assim como mapeamos os impactos que essas soluções trariam para o negócio da empresa.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-a.svg',
      numero: './assets/icons/i-tres-a.svg',
      texto: 'Validação do Escopo Técnico e de Negócio',
      descricao: 'Nesta fase usamos todas as informações coletadas na ideação da solução e o protótipo sugerido, de forma a esboçar um resumo do processo de construção da solução.',
    },
    {
      id: 4,
      imagem:'./assets/icons/i-check-a.svg',
      numero: './assets/icons/i-quatro-a.svg',
      texto: 'Planejamento da Execução | SOW',
      descricao: 'A fase final do processo é produzido um documento ( SOW ) e proposição de todos os itens do processo. Aqui é apresentado o protótipo criado com uma dinâmica de validação.',
    }
  ];
  public solution: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Desenvolvimento',
      descricao: 'Na fase de Desenvolvimento é quando a solução ganha vida e tudo o que havia sido planejado na Fase anterior de Design de Solução é executado pelos especialistas.'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Teste',
      descricao: 'Nesta fase de Testes, o Analista de Testes realiza um teste geral na solução desenvolvida, regras de negócios e funcionalidades previstas no SOW. Após os testes e correções, o Product Owner válida a solução aprova a publicação da solução no ambiente de produção.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Nesta etapa é realizado a homologação por parte do cliente quanto a todos os entregáveis que foram pré-acordados no plano de trabalho.',
    }
  ];
  public implantacao: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-azul.svg',
      numero: './assets/icons/i-um-azul.svg',
      texto: 'Preparação',
      descricao: 'Nesta etapa ambos os times Qi Network e Cliente se alinham para preparar o ambiente para executar a implementação. Nesta fase os times se conhecem através da reunião de Kick-off interna e com o cliente..'
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-azul.svg',
      numero: './assets/icons/i-dois-azul.svg',
      texto: 'Implementação',
      descricao: 'Nesta etapa será executada a implementação das configurações e requisitos de negócio pré-definidas e conferidas por ambas as empresas.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-azul.svg',
      numero: './assets/icons/i-tres-azul.svg',
      texto: 'Homologação',
      descricao: 'Nesta etapa é realizado a homologação por parte do cliente quanto a todos os entregáveis que foram pré-acordados no plano de trabalho.',
    }
  ];
  public care: any[] = [
    {
      id: 1,
      imagem:'./assets/icons/i-mapeamento-verde.svg',
      numero: './assets/icons/i-um-verde.svg',
      texto: 'Avaliação',
      descricao: 'Nesta etapa recebemos a solicitação do cliente e avaliamos a sua classificação para darmos encaminhamento para o seu atendimento.',
    },
    {
      id: 2,
      imagem:'./assets/icons/i-cubo-verde.svg',
      numero: './assets/icons/i-dois-verde.svg',
      texto: 'Atendimento',
      descricao: 'Executamos o atendimento de suporte técnico conforme a sua classificação e acordo de SLA. Neste atendimento poderá ser simplesmente uma resposta por e-mail como dependendo do tipo de necessidade poderá ser entrado em contato com o cliente para apoiá-lo junto ao problema relatado ou dúvida técnica levantada.',
    },
    {
      id: 3,
      imagem:'./assets/icons/i-calendario-verde.svg',
      numero: './assets/icons/i-tres-verde.svg',
      texto: 'Monitoramento',
      descricao: 'O monitoramento da solução cloud é realizado através de NOC da Qi Network. O monitoramento é proativo e através dos alertas pré parametrizados conseguimos acompanhar em tempo real a disponibilidade das soluções cloud..',
    }
  ];

  public tiposAnalise: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-v.svg',
      texto: 'Desenvolvimento da Infra On-premises no GCP(DevOps)'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-v.svg',
      texto: 'Desenvolvimento da Infra de uma nuvem no GCP(DevOps)'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-v.svg',
      texto: 'Desenvolvimento da Infra ERP no GCP(DevOps)'
    },
    {
      id: 4,
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Desenvolvimento do Disaster Recovery no GCP(DevOps)'
    },
    {
      id: 5,
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Desenvolvimento da Automatização de Infra(DevOps)'
    },
    {
      id: 6,
      img: './assets/icons/i-seis-v.svg',
      texto: 'Implementação de DaaS e integração com ambiente legado'
    },
    {
      id: 7,
      img: './assets/icons/i-sete-v.svg',
      texto: 'Implementação da Infra de Aplicações Web no GCP'
    },
  ];
  public tiposMapeamento: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-v.svg',
      texto: 'Segurança de Infra'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-v.svg',
      texto: 'Backup e Disaster Recovery na Nuvem'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-v.svg',
      texto: 'Migração On-Premises para a Nuvem'
    },
    {
      id: 4,
      img: './assets/icons/i-quatro-v.svg',
      texto: 'Migração de Nuvem para o GCP'
    },
    {
      id: 5,
      img: './assets/icons/i-cinco-v.svg',
      texto: 'Migração SAP (SuSe & Red Hat)'
    },
    {
      id: 6,
      img: './assets/icons/i-seis-v.svg',
      texto: 'DaaS e Integração com ambiente legado'
    },
    {
      id: 7,
      img: './assets/icons/i-sete-v.svg',
      texto: 'Infra definida por código(IaC)'
    },
    {
      id: 8,
      img: './assets/icons/i-oito-v.svg',
      texto: 'Automatização de Infra(DevOps)'
    },
    {
      id: 9,
      img: './assets/icons/i-nove-v.svg',
      texto: 'Automatização de CI/CD e deploy de aplicação'
    },
    {
      id: 10,
      img: './assets/icons/i-dez-v.svg',
      texto: 'Conteinerização de aplicações'
    },
  ];
  public tiposTreinamento: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-v.svg',
      texto: 'Treinamento Usuários Finais'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-v.svg',
      texto: 'Treinamentos Especialistas de Ferramenta'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-v.svg',
      texto: 'Treinamento de Administradores'
    }
  ];
  public tiposDesign: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-a.svg',
      texto: 'Desenvolvimento da Infra On-premises no GCP(DevOps)'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-a.svg',
      texto: 'Desenvolvimento da Infra de uma nuvem no GCP(DevOps)'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-a.svg',
      texto: 'Desenvolvimento da Infra ERP no GCP(DevOps)'
    },
    {
      id: 4,
      img: './assets/icons/i-quatro-a.svg',
      texto: 'Desenvolvimento do Disaster Recovery no GCP(DevOps)'
    },
    {
      id: 5,
      img: './assets/icons/i-cinco-a.svg',
      texto: 'Desenvolvimento da Automatização de Infra(DevOps)'
    },
    {
      id: 6,
      img: './assets/icons/i-seis-a.svg',
      texto: 'Implementação de DaaS e integração com ambiente legado'
    },
    {
      id: 7,
      img: './assets/icons/i-sete-a.svg',
      texto: 'Implementação da Infra de Aplicações Web no GCP'
    },
  ];
  public tiposSolution: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-azul.svg',
      texto: 'Desenvolvimento da Infra On-premises no GCP(DevOps)'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Desenvolvimento da Infra de uma nuvem no GCP(DevOps)'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Desenvolvimento da Infra ERP no GCP(DevOps)'
    },
    {
      id: 4,
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Desenvolvimento do Disaster Recovery no GCP(DevOps)'
    },
    {
      id: 5,
      img: './assets/icons/i-cinco-azul.svg',
      texto: 'Desenvolvimento da Automatização de Infra(DevOps)'
    }
  ];
  public tiposImplantacao: any[] = [
    {
      id: 1,
      img: './assets/icons/i-um-azul.svg',
      texto: 'Billing ID & Alertas de Consumo'
    },
    {
      id: 2,
      img: './assets/icons/i-dois-azul.svg',
      texto: 'Migração do Backup de Dados para o GCP'
    },
    {
      id: 3,
      img: './assets/icons/i-tres-azul.svg',
      texto: 'Migração de Dados On-Premises para o GCP'
    },
    {
      id: 4,
      img: './assets/icons/i-quatro-azul.svg',
      texto: 'Migração de Dados de Nuvem para o GCP'
    },
    {
      id: 5,
      img: './assets/icons/i-cinco-azul.svg',
      texto: 'Migração de Dados da Aplicação Web'
    },
    {
      id: 6,
      img: './assets/icons/i-seis-azul.svg',
      texto: 'DaaS e Integração com ambiente legado'
    },
    {
      id: 7,
      img: './assets/icons/i-sete-azul.svg',
      texto: 'Migração de Dados do DaaS'
    },
    {
      id: 8,
      img: './assets/icons/i-oito-azul.svg',
      texto: 'Migração de Dados ERP para o GCP'
    },
    {
      id: 9,
      img: './assets/icons/i-nove-azul.svg',
      texto: 'Infra de Aplicações Web no GCP'
    },

  ];
  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public titleMeta: Title
  ) { }
  public tiposModal;
  public title;

  public activeAnalise: any = {};
  public activeDesign: any = {};
  public activeSolution: any = {};
  jornadaConfig = {
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: ``,
          prevArrow: ``,       
          dots: true,   
          autoplay: true,
        }
      }
    ]
  };

  public Slide(slide: any) {
    console.log(slide);
    this.activeAnalise = slide;
    this.activeDesign = slide;
    this.activeSolution = slide;
  }
  ngOnInit() {
    this.activeAnalise = this.analise[0];
    this.activeDesign = this.design[0];
    this.activeSolution = this.solution[0];
    this.helper.hasNav = true;
    this.helper.pagina = 'modernizacao';
    // this.titleMeta.setTitle('Qi Network - Serviços de Jornada de Modernização de Infra');
    // this.meta.updateTag({ name: 'description', content: 'Sua Infra Escalável, Segura, Econômica e Inovadora' });
    // this.meta.updateTag({ name: 'keywords', content: 'qinetwork, modernização, google cloud, modernização, suporte tecnico, qi cloud'});
    // this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Serviços de Jornada de Modernização de Infra' });
  }
  
  slickInit(e) { console.log('slick initialized'); }
  breakpoint(e) { console.log('breakpoint'); }
  afterChange(e) {
      this.activeAnalise = this.analise[e.currentSlide];
      this.activeDesign = this.design[e.currentSlide];
      this.activeSolution = this.solution[e.currentSlide];
  }
  beforeChange(e) {}

  public openModal(tipo) {
    const h3 = document.getElementById('exampleModalLabel') as HTMLBodyElement;
      switch (tipo) {
        case 'analise':
          this.tiposModal = this.tiposAnalise;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Análise e Orientação Técnica'
          break;
        case 'mapeamento':
            this.tiposModal = this.tiposMapeamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Mapeamento Técnico'
          break;
        case 'treinamento':
            this.tiposModal = this.tiposTreinamento;
          h3.style.color = '#AE1E22';
          this.title = 'Tipos de Treinamento Técnico'
          break;
        case 'design':
            this.tiposModal = this.tiposDesign;
          h3.style.color = '#D5A629';
          this.title = 'Tipos de Design de soluções'
          break;
        case 'solution':
            this.tiposModal = this.tiposSolution;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Desenvolvimento de Solução'
          break;
        case 'implementacao':
            this.tiposModal = this.tiposImplantacao;
          h3.style.color = '#3F9CEF';
          this.title = 'Tipos de Implementação de Solução'
          break;
      
        default:
          break;
      }
    }

}
