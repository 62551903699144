import { Component, OnInit } from "@angular/core";
import { HelperService } from "src/app/service/helper.service";

@Component({
  selector: "app-cloud-services",
  templateUrl: "./cloud-services.component.html",
  styleUrls: ["../solucoes.component.scss"],
})
export class ModernizacaoInfraComponent implements OnInit {
  constructor(public helper: HelperService) {}

  public solucoes: any[] = [
    {
      id: 11,
      img: "./assets/images/solucoes/cloud-services/data analytics.jpeg",
      titulo: "Data & Analytics",
      descricao:
        "Extraia insights valiosos dos seus dados com soluções avançadas de análise.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: '/solucoes/cloud-services/data-analytics'
    },
    {
      id: 2,
      img: "./assets/images/solucoes/cloud-services/AI ML.jpeg",
      titulo: "IA & ML",
      descricao:
        "Potencialize suas operações com a inteligência artificial e machine learning.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/ia-ml",
    },
    {
      id: 3,
      img: "./assets/images/solucoes/cloud-services/migração 2.jpeg",
      titulo: "Migração",
      descricao:
        "Migre seus sistemas para a nuvem de forma segura e eficiente.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/migracao",
    },
    {
      id: 4,
      img: "./assets/images/solucoes/cloud-services/Finops 2.jpeg",
      titulo: "Finops",
      descricao:
        "Otimize seus custos na nuvem com uma gestão financeira eficiente.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/finops",
    },
    {
      id: 5,
      img: "./assets/images/solucoes/cloud-services/CMS 2.jpeg",
      titulo: "Cloud Managed Services (CMS)",
      descricao:
        "Tenha suporte especializado para gerenciar sua infraestrutura na nuvem.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/cms",
    },
    {
      id: 6,
      img: "./assets/images/solucoes/cloud-services/backup DR 2.jpeg",
      titulo: "Backup & DR",
      descricao:
        "Proteja seus dados e garanta a continuidade do seu negócio em caso de desastres.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/backup-dr",
    },
    {
      id: 7,
      img: "./assets/images/solucoes/cloud-services/Devops SRE 2.jpeg",
      titulo: "Devops & SRE",
      descricao:
        "Acelere o desenvolvimento e a entrega de software com práticas modernas.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/devops-sre",
    },
    {
      id: 8,
      img: "./assets/images/solucoes/cloud-services/segurança compliance 2.jpeg",
      titulo: "Posture Review (Segurança & Compliance)",
      descricao:
        "Garanta a conformidade e a segurança dos seus sistemas na nuvem.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/posture-review",
    },
    {
      id: 9,
      img: "./assets/images/solucoes/cloud-services/suporte e monitoramento.jpeg",
      titulo: "Suporte & Monitoramento",
      descricao:
        "Tenha suporte técnico e monitore o desempenho da sua infraestrutura.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/suporte-monitoramento",
    },
    {
      id: 10,
      img: "./assets/images/solucoes/cloud-services/modernização de infra.jpeg",
      titulo: "Modernização de Infra",
      descricao:
        "Atualize sua infraestrutura para suportar as demandas da transformação digital.",
      icone: "./assets/icons/solucoes/i-seta-solucoes-azul.svg",
      link: "/solucoes/cloud-services/modernizacao-infra",
    },
  ];

  public clientes: any[] = [
    {
      id: 1,
      solucoes: "infra",
      logo: "./assets/logos/institucional/squadra.svg",
      alt: "squadra",
      tipo: "Case Study",
      descricao:
        "A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.",
      tempo: "8 min leitura",
      icone: "./assets/icons/institucional/i-proximo-preto.svg",
      link: "",
    },
    {
      id: 2,
      solucoes: "infra",
      logo: "./assets/logos/institucional/metropoles.svg",
      alt: "metropoles",
      tipo: "Video",
      descricao:
        "Migração cloud: como o Metrópoles migrou com segurança para o Google Cloud",
      tempo: "2:52 min",
      icone: "./assets/icons/institucional/i-play.svg",
      link: "",
    },
    {
      id: 3,
      solucoes: "dados",
      logo: "./assets/logos/institucional/squadra.svg",
      alt: "squadra",
      tipo: "Case Study",
      descricao:
        "A Squadra Tecnologia migrou seus servidores para o Google Cloud em 30 dias.",
      tempo: "8 min leitura",
      icone: "./assets/icons/institucional/i-proximo-preto.svg",
      link: "",
    },
  ];

  ngOnInit() {
    this.helper.jInfra = true;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;
    this.helper.ajudaAzul = true;
  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }
}
