import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-trabalhe-conosco',
  templateUrl: './trabalhe-conosco.component.html',
  styleUrls: ['./trabalhe-conosco.component.scss']
})
export class TrabalheConoscoComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  public razoes: any[] = [
    {
      id: 0,
      img: './assets/icons/i-aprendizado.svg',
      descricao: 'Muito aprendizado: temos um time de experts em Google'
    },
    {
      id: 1,
      img: './assets/icons/i-crescimento.svg',
      descricao: 'Estamos em constante crescimento. Você vai crescer com a gente'
    },
    {
      id: 2,
      img: './assets/icons/i-bom.svg',
      descricao: 'Temos um excelente ambiente de trabalho e uma equipe bem integrada'
    },
    {
      id: 3,
      img: './assets/icons/i-inovacao.svg',
      descricao: 'Adoramos novos desafios e estamos focados na inovação'
    },
    {
      id: 4,
      img: './assets/icons/i-compromisso.svg',
      descricao: 'Somos flexíveis e comprometidos para gerar os melhores resultados'
    },
    {
      id: 5,
      img: './assets/icons/i-localizacao.svg',
      descricao: 'Estamos localizados em Florianópolis, capital com alta qualidade de vida'
    },
  ]

  ngOnInit() {
  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }
}
