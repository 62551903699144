import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "src/app/service/helper.service";
import { NavService } from "./nav.service";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  public menu = false;
  public dropSolucoes = false;
  public dropServicos = false;
  public dropVerticais = false;
  public dropInstitucional = false;
  public dropInovacao = false;
  public dropConteudos = false;
  public nav: any = {};
  public transparentMenu = false;

  constructor(
    public helper: HelperService,
    public router: Router,
    private navService: NavService,
    private route: ActivatedRoute
  ) {}

  public servicos: any[] = [
    {
      id: 1,
      imagem: "./assets/icons/nav/i-produtividade.svg",
      alt: "produtividade",
      titulo: "Serviço de Produtividade e Colaboração",
      link: "servicos/servico-produtividade",
    },
    {
      id: 2,
      imagem: "./assets/icons/nav/i-modernizacao.svg",
      alt: "modernizacao",
      titulo: "Serviço de Modernização de Infra",
      link: "servicos/servico-modernizacao",
    },
    {
      id: 3,
      imagem: "./assets/icons/nav/i-inteligencia.svg",
      alt: "inteligencia",
      titulo: "Serviço de Inteligência de Dados",
      link: "servicos/servico-inteligencia",
    },
  ];

  public verticais: any[] = [
    {
      id: 1,
      imagem: "./assets/icons/nav/i-industria.svg",
      alt: "industria",
      titulo: "Indústria",
      link: "verticais/industria",
    },
    {
      id: 2,
      imagem: "./assets/icons/nav/i-logistica.svg",
      alt: "logistica",
      titulo: "Logística",
      link: "verticais/logistica",
    },
    {
      id: 3,
      imagem: "./assets/icons/nav/i-saude.svg",
      alt: "saude",
      titulo: "Saúde",
      link: "verticais/saude",
    },
    {
      id: 4,
      imagem: "./assets/icons/nav/i-tecnologia.svg",
      alt: "tecnologia",
      titulo: "Tecnologia",
      link: "verticais/tecnologia",
    },
    {
      id: 5,
      imagem: "./assets/icons/nav/i-varejo.svg",
      alt: "varejo",
      titulo: "Varejo",
      link: "verticais/varejo",
    },
  ];

  public institucional: any[] = [
    {
      id: 1,
      imagem: "./assets/icons/nav/i-nos.svg",
      alt: "sobre",
      titulo: "Sobre nós",
      descricao:
        "Conheça mais sobre nossa história, de onde viemos e qual é nossa visão de futuro",
      link: "sobre",
    },
    /*     {
      id: 2,
      imagem: './assets/icons/nav/i-cases.svg',
      alt: 'cases',
      titulo: 'Cases de Sucesso',
      descricao: 'Melhor do que falar é mostrar resultados reais que nossos clientes alcançaram',
      link:'cases'
    }, */
    {
      id: 4,
      imagem: "./assets/icons/nav/i-trabalhe-conosco.svg",
      alt: "trabalhe-conosco",
      titulo: "Trabalhe Conosco",
      descricao:
        "Venha trabalhar em uma das principais parceiras Google Cloud do Brasil",
      link: "trabalhe-conosco",
    },
    {
      id: 3,
      imagem: "./assets/icons/nav/i-certificacoes.svg",
      alt: "certificacoes",
      titulo: "Certificações",
      descricao:
        "Descubra as certificações e qualificações que possuímos para fornecer serviços de alta qualidade",
      link: "certificacoes",
    },
  ];

  public inovacao: any[] = [
    {
      id: 1,
      imagem: "./assets/icons/nav/i-produtividade.svg",
      alt: "produtividade",
      titulo: "Jornada de Produtividade e Colaboração",
      descricao:
        "Aumente a produtividade dos colaboradores, melhore processos continuamente e aumente a segurança de seu negócio!",
      link: "jornada-de-produtividade-e-seguranca",
    },
    {
      id: 2,
      imagem: "./assets/icons/nav/i-modernizacao.svg",
      alt: "modernizacao",
      titulo: "Jornada de Modernização de Infra",
      descricao:
        "Facilitamos a migração, modernização e sustentação da infra de sua empresa no Google Cloud",
      link: "jornada-de-modernizacao-de-infra",
    },
    {
      id: 3,
      imagem: "./assets/icons/nav/i-inteligencia.svg",
      alt: "inteligencia",
      titulo: "Jornada de Inteligência de Dados",
      descricao:
        "Tenha acesso a informações para a gestão do negócio e tomada de decisões estratégicas baseada em dados",
      link: "jornada-de-inteligencia-de-dados",
    },
  ];

  public conteudos: any[] = [
    {
      id: 1,
      imagem: "./assets/icons/nav/i-blog.svg",
      alt: "blog",
      titulo: "Blog",
      descricao: "Conteúdos e informações sobre o universo Cloud",
      link: "",
    },
    {
      id: 2,
      imagem: "./assets/icons/nav/i-ebooks.svg",
      alt: "ebooks",
      titulo: "Ebooks",
      descricao: "Materiais ricos para você se aprofundar em Cloud",
      link: "ebooks",
    },
    {
      id: 3,
      imagem: "./assets/icons/nav/i-webnars.svg",
      alt: "webnars",
      titulo: "Webinars",
      descricao:
        "Mantenha-se atualizado com as novidades de inovação na nuvem. Acompanhe nosso canal",
    },
 /*    {
      id: 4,
      imagem: "./assets/icons/nav/i-event.svg",
      alt: "eventos",
      titulo: "Eventos",
      descricao:
        "Participe dos nossos eventos e conheça grandes cases de sucesso.",
    }, */
  ];

  ngOnInit() {
    this.navService.changeMenu.subscribe((res) => {
      this.transparentMenu = res;
    });
  }

  public openNav() {
    if (this.helper.isMobile === true) {
      this.menu = !this.menu;
      const body = document.getElementsByTagName("body")[0] as HTMLElement;
      if (this.menu === true) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }

  getSvgArrow() {
    return this.transparentMenu
      ? "./assets/icons/nav/i-down-white.svg"
      : "./assets/icons/nav/i-down-blue.svg";
  }

  public smooth(id?) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }
  public dropdown(status?, id?) {
    const img = document.getElementById(status) as HTMLImageElement;
    const txt = document.getElementsByClassName("ativo")[id] as HTMLElement;

    console.log(img, txt, document.getElementsByClassName("ativo"));

    const imgSolucoes = document.getElementById("solucoes") as HTMLImageElement;
    const imgVerticais = document.getElementById(
      "verticais"
    ) as HTMLImageElement;
    const imgConteudos = document.getElementById(
      "conteudos"
    ) as HTMLImageElement;
    const imgInstitucional = document.getElementById(
      "institucional"
    ) as HTMLImageElement;

    console.log(imgSolucoes, imgVerticais, imgInstitucional, imgConteudos);
    const txtSolucoes = document.getElementsByClassName(
      "ativo"
    )[0] as HTMLElement;
    const txtVerticais = document.getElementsByClassName(
      "ativo"
    )[1] as HTMLElement;
    const txtConteudos = document.getElementsByClassName(
      "ativo"
    )[2] as HTMLElement;
    const txtInstitucional = document.getElementsByClassName(
      "ativo"
    )[3] as HTMLElement;
    switch (status) {
      case "solucoes":
        // this.nav = this.solucoes;
        this.dropSolucoes = !this.dropSolucoes;
        this.dropServicos = false;
        this.dropVerticais = false;
        this.dropInstitucional = false;
        this.dropInovacao = false;
        this.dropConteudos = false;

        if (this.dropSolucoes === true) {
          img.src = "./assets/icons/nav/i-up-red.svg";
          txt.style.color = "#AE1E22";
        } else {
          img.src = this.getSvgArrow();
          txt.style.color = "black";
        }

        imgVerticais.src = this.getSvgArrow();
        txtVerticais.style.color = "black";

        imgInstitucional.src = this.getSvgArrow();
        txtInstitucional.style.color = "black";

        imgConteudos.src = this.getSvgArrow();
        txtConteudos.style.color = "black";
        break;

      case "verticais":
        // this.nav = this.verticais;
        this.dropServicos = false;
        this.dropSolucoes = false;
        this.dropVerticais = !this.dropVerticais;
        this.dropInstitucional = false;
        this.dropInovacao = false;
        this.dropConteudos = false;

        if (this.dropVerticais === true) {
          img.src = "./assets/icons/nav/i-up-red.svg";
          txt.style.color = "#AE1E22";
        } else {
          img.src = this.getSvgArrow();
          txt.style.color = "black";
        }

        imgSolucoes.src = this.getSvgArrow();
        txtSolucoes.style.color = "black";

        imgInstitucional.src = this.getSvgArrow();
        txtInstitucional.style.color = "black";


        imgConteudos.src = this.getSvgArrow();
        txtConteudos.style.color = "black";
        break;

      case "institucional":
        // this.nav = this.institucional;
        this.dropServicos = false;
        this.dropSolucoes = false;
        this.dropVerticais = false;
        this.dropInstitucional = !this.dropInstitucional;
        this.dropInovacao = false;
        this.dropConteudos = false;

        if (this.dropInstitucional === true) {
          img.src = "./assets/icons/nav/i-up-red.svg";
          txt.style.color = "#AE1E22";
        } else {
          img.src = this.getSvgArrow();
          txt.style.color = "black";
        }

        imgSolucoes.src = this.getSvgArrow();
        txtSolucoes.style.color = "black";


        imgVerticais.src = this.getSvgArrow();
        txtVerticais.style.color = "black";


        imgConteudos.src = this.getSvgArrow();
        txtConteudos.style.color = "black";
        break;

      case "conteudos":
        // this.nav = this.conteudos;
        this.dropServicos = false;
        this.dropSolucoes = false;
        this.dropVerticais = false;
        this.dropInstitucional = false;
        this.dropInovacao = false;
        this.dropConteudos = !this.dropConteudos;

        if (this.dropConteudos === true) {
          img.src = "./assets/icons/nav/i-up-red.svg";
          txt.style.color = "#AE1E22";
        } else {
          img.src = this.getSvgArrow();
          txt.style.color = "black";
        }

        imgSolucoes.src = this.getSvgArrow();
        txtSolucoes.style.color = "black";


        imgVerticais.src = this.getSvgArrow();
        txtVerticais.style.color = "black";

        imgInstitucional.src = this.getSvgArrow();
        txtInstitucional.style.color = "black";

        break;

      case "home":
        this.dropServicos = false;
        imgSolucoes.src = this.getSvgArrow();
        txtSolucoes.style.color = "black";

        this.dropVerticais = false;
        imgVerticais.src = this.getSvgArrow();
        txtVerticais.style.color = "black";

        this.dropInstitucional = false;
        imgInstitucional.src = this.getSvgArrow();
        txtInstitucional.style.color = "black";

        this.dropConteudos = false;
        imgConteudos.src = this.getSvgArrow();
        txtConteudos.style.color = "black";
        break;

      default:
        break;
    }
  }

  public link(link) {
    this.helper.scroll();

    switch (link) {
      case "Eventos":
        window.open("https://www.qicloudexperience.com.br/", "_blank");
        break;
      case "Blog":
        window.open("https://blog.qinetwork.com.br/", "_blank");
        break;
      case "Ebooks":
        this.router.navigate(["/ebooks"]);
        break;
      case "Webinars":
        this.router.navigate(["/webinars"]);
        break;
      default:
        break;
    }
  }
}
