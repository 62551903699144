import { Component, OnInit } from '@angular/core';
import { TecnologiaData } from './dados';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-tecnologia-pillar-page',
  templateUrl: './tecnologia-pillar-page.component.html',
  styleUrls: ['./tecnologia-pillar-page.component.scss']
})
export class TecnologiaPillarPageComponent implements OnInit {


  public topic = TecnologiaData;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Benefícios de cloud computing para empresas de tecnologia');

    this.meta.updateTag({ name: 'description', content: 'Você sabe como as empresas se beneficiam ao investir em cloud computing e como elas podem fazer uma migração segura? Leia nosso artigo e descubra!' });

    this.meta.updateTag({ property: 'og:title', content: 'Benefícios de cloud computing para empresas de tecnologia' });
    this.meta.updateTag({ property: 'og:description', content: 'Você sabe como as empresas se beneficiam ao investir em cloud computing e como elas podem fazer uma migração segura? Leia nosso artigo e descubra!' });


    this.meta.updateTag({ name: 'twitter:title', content: 'Benefícios de cloud computing para empresas de tecnologia' });
    this.meta.updateTag({ name: 'twitter:description', content: 'Você sabe como as empresas se beneficiam ao investir em cloud computing e como elas podem fazer uma migração segura? Leia nosso artigo e descubra!' });
  }

  ngOnDestroy() {
    this.title.setTitle('QI Network');
  }

}
