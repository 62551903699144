import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-google-data-studio',
  templateUrl: './google-data-studio.component.html',
  styleUrls: ['./google-data-studio.component.scss']
})
export class GoogleDataStudioComponent implements OnInit {

  constructor(public helper: HelperService) { }

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = true;
    this.helper.ajudaCinza = false;
    
  }

}
