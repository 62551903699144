export const VarejoData: any = {
  title: "Black Friday para e-commerce: como preparar o seu negócio",
  description: `
  <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A Black Friday &eacute; um evento muito aguardado pelos consumidores e uma grande oportunidade para os neg&oacute;cios que contam com um e-commerce.&nbsp; Quem tem neg&oacute;cios, principalmente de varejo, sabe que a Black Friday para e-commerce consiste nas empresas oferecerem descontos e promo&ccedil;&otilde;es especiais, atraindo muitos compradores em busca de boas ofertas.&nbsp;</span></span></span></p>

  
  <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">No entanto, para aproveitar ao m&aacute;ximo esse per&iacute;odo e garantir o sucesso nas vendas, &eacute; essencial que as empresas se preparem de forma adequada, considerando, inclusive, aspectos como a LGPD e as ferramentas de apoio.</span></span></span></p>
  
  
  <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Neste post, vamos falar mais sobre a Black Friday para e-commerce e como se organizar para ela. Confira!</span></span></span></p>
  

  
  
  `,
  items: [
    {
      title: "Como se preparar para a Black Friday para e-commerce em 12 passos",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A Black Friday &eacute; uma oportunidade incr&iacute;vel para o seu e-commerce aumentar as vendas e conquistar novos clientes.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Mas, para aproveitar ao m&aacute;ximo essa data, &eacute; preciso se planejar com anteced&ecirc;ncia e seguir alguns passos essenciais. A seguir, veja como se preparar a Black Friday para e-commerce em 12 passos pr&aacute;ticos e eficientes.</span></span></span></p>
      
      
      
      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-varejo/conceito-de-compra-e-venda-on-line-de-negocios.jpg"
    },
    {
      title: "1. Defina metas para a empresa",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O primeiro passo para se preparar para a Black Friday para e-commerce &eacute; definir quais s&atilde;o as suas metas com a campanha. Para isso, leve em considera&ccedil;&atilde;o aspectos como o volume de vendas que voc&ecirc; pretende atingir, o aumento percentual de receita esperado, o n&uacute;mero de novos clientes que deseja conquistar e os produtos de destaque que voc&ecirc; deseja promover.</span></span></span></p>


      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Voc&ecirc; quer aumentar o faturamento, o n&uacute;mero de pedidos, o ticket m&eacute;dio, a taxa de convers&atilde;o ou a reten&ccedil;&atilde;o de clientes? </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>Dependendo do seu objetivo, voc&ecirc; vai precisar de estrat&eacute;gias diferentes para alcan&ccedil;&aacute;-lo</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>
      
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Por exemplo, se voc&ecirc; quer aumentar o faturamento, voc&ecirc; pode focar em oferecer descontos atrativos e divulgar massivamente a sua promo&ccedil;&atilde;o. Se voc&ecirc; quer aumentar a reten&ccedil;&atilde;o de clientes, voc&ecirc; pode criar ofertas personalizadas para os seus clientes j&aacute; cadastrados ou oferecer vantagens como frete gr&aacute;tis ou garantia estendida.</span></span></span></p>
      
      
      <p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong><em>Quer saber como a gest&atilde;o da mudan&ccedil;a promoveu o sucesso na migra&ccedil;&atilde;o das ferramentas no </em></strong></span></span></span><a target="_blank" href="https://www.qinetwork.com.br/case/6" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><strong><em><u>Grupo Carrefour</u></em></strong></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong><em>? Veja nosso case!</em></strong></span></span></span></p>
      
      <p>&nbsp;</p>
      
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "2. Reveja os anos anteriores",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Rever os anos anteriores &eacute; uma etapa importante na prepara&ccedil;&atilde;o para a Black Friday no e-commerce. Analisar os resultados, estrat&eacute;gias e li&ccedil;&otilde;es aprendidas nas edi&ccedil;&otilde;es anteriores pode fornecer valiosos insights para aprimorar sua abordagem.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Avalie os dados de vendas, tr&aacute;fego do site, taxas de convers&atilde;o, produtos mais vendidos e feedback dos clientes durante as &uacute;ltimas Black Fridays.</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> Identifique quais foram os pontos fortes e fracos, oportunidades perdidas e os desafios enfrentados</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Isso ajudar&aacute; a entender o que funcionou bem e o que precisa ser aperfei&ccedil;oado para obter melhores resultados &mdash; e isso tamb&eacute;m vale para o marketing,</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Com base nessa an&aacute;lise retrospectiva, voc&ecirc; poder&aacute; fazer ajustes e melhorias em suas t&aacute;ticas, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>desenvolver novas abordagens e evitar repetir erros passados</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">. Aprender com as experi&ecirc;ncias anteriores &eacute; fundamental para maximizar o potencial dos seus esfor&ccedil;os de prepara&ccedil;&atilde;o.</span></span></span></p>
      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-varejo/colegas-de-trabalho-que-reveem-relatorios.jpg"
    },
    {
      title: "3. Faça um bom treinamento com a equipe",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Um </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/treinando-novos-funcionarios/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>treinamento adequado da equipe</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> &eacute; essencial para garantir que todos estejam alinhados e preparados para lidar com o aumento da demanda durante a Black Friday.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>Investir tempo e recursos no desenvolvimento de programas de treinamento abrangentes</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, visando capacitar os membros da equipe em diversas &aacute;reas relevantes &eacute; uma boa pr&aacute;tica nesse sentido.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Certifique-se tamb&eacute;m de disponibilizar recursos adequados, como materiais de apoio, sess&otilde;es pr&aacute;ticas e a oportunidade de esclarecer d&uacute;vidas. Outro aspecto fundamental envolve manter uma comunica&ccedil;&atilde;o constante com a equipe, refor&ccedil;ando a import&acirc;ncia do treinamento para o sucesso da campanha.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Al&eacute;m disso, sempre motive a sua equipe para que ela se sinta engajada e valorizada. Voc&ecirc; pode oferecer incentivos, como bonifica&ccedil;&otilde;es, premia&ccedil;&otilde;es e reconhecimentos. Tudo de acordo com o cumprimento de metas e a qualidade do trabalho.</span></span></span></p>
      
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "4. Tenha um bom processo de frete",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Durante a Black Friday, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>o volume de pedidos e envios pode aumentar significativamente</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">. Ter um bom processo de frete &eacute; fundamental para garantir uma experi&ecirc;ncia de compra positiva para os clientes e evitar problemas log&iacute;sticos.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Para isso, estabele&ccedil;a parcerias com transportadoras confi&aacute;veis e eficientes. Pesquise e avalie diferentes op&ccedil;&otilde;es para encontrar aquelas que oferecem um servi&ccedil;o de qualidade, com prazos de entrega r&aacute;pidos e rastreamento de pedidos. Verifique tamb&eacute;m se as transportadoras possuem capacidade suficiente para lidar com o aumento da demanda durante a Black Friday.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Uma sa&iacute;da &eacute; escolher uma solu&ccedil;&atilde;o de integra&ccedil;&atilde;o log&iacute;stica que fa&ccedil;a a cota&ccedil;&atilde;o de fretes com os Correios e com transportadoras privadas, sem a necessidade de contratos individuais.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Outro ponto essencial nesse assunto &eacute; oferecer frete gr&aacute;tis ou descontos no frete para compras acima de um determinado valor ou para regi&otilde;es espec&iacute;ficas.</span></span></span></p>
      
      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-varejo/processo-de-frete.jpg"
    },
    {
      title: "5. Use um sistema para envio de produtos",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Para otimizar o processo de envio de produtos durante a Black Friday, &eacute; recomendado utilizar um sistema especializado para essa finalidade. Um sistema de envio de produtos automatizado pode simplificar e acelerar as etapas envolvidas, desde a impress&atilde;o de etiquetas de envio at&eacute; a gera&ccedil;&atilde;o de documentos necess&aacute;rios, como notas fiscais e comprovantes de entrega.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A gest&atilde;o centralizada de todos os pedidos tamb&eacute;m pode ser bem interessante, facilitando o acompanhamento do status de cada envio e fornecendo informa&ccedil;&otilde;es atualizadas sobre prazos de entrega para os clientes.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Alguns sistemas de envio de produtos oferecem recursos de otimiza&ccedil;&atilde;o de rotas, ajudando a reduzir os custos de envio e melhorar a efici&ecirc;ncia da log&iacute;stica.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Finalmente, verifique se o sistema de envio de produtos pode ser integrado &agrave; sua plataforma de e-commerce. Isso possibilitar&aacute; uma troca de informa&ccedil;&otilde;es mais f&aacute;cil e automatizada, evitando a necessidade de inser&ccedil;&atilde;o manual de dados e reduzindo erros.</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "6. Planeje bem o estoque",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Sem organiza&ccedil;&atilde;o de estoque, dificilmente uma Black Friday para e-commerce ter&aacute; sucesso.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Armazenar produtos suficientes dispon&iacute;veis para atender &agrave; demanda &eacute; essencial para evitar frustra&ccedil;&otilde;es dos clientes e perda de vendas.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Ao analisar os dados de vendas das edi&ccedil;&otilde;es anteriores da Black Friday &mdash; com base nessa previs&atilde;o&mdash;, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>fa&ccedil;a uma estimativa da demanda esperada para a Black Friday que est&aacute; por vir</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Considere fatores como o crescimento do seu neg&oacute;cio, mudan&ccedil;as nas tend&ecirc;ncias de mercado e poss&iacute;veis impactos do cen&aacute;rio econ&ocirc;mico. Essa previs&atilde;o ajudar&aacute; voc&ecirc; a determinar a quantidade de produtos que deve ter em estoque.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Certifique-se de que eles possam fornecer os produtos necess&aacute;rios em tempo h&aacute;bil. Estabelecer uma boa rela&ccedil;&atilde;o com os fornecedores &eacute; fundamental para garantir um fluxo cont&iacute;nuo de estoque durante esse per&iacute;odo.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">E n&atilde;o menos importante: </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>mantenha um controle regular do seu estoque</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> e reabaste&ccedil;a os produtos conforme necess&aacute;rio.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Fa&ccedil;a pedidos adicionais com anteced&ecirc;ncia para evitar a falta de itens populares durante a Black Friday. Considere tamb&eacute;m a possibilidade de estabelecer acordos de reposi&ccedil;&atilde;o r&aacute;pida com fornecedores para agilizar o processo de reabastecimento</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "7. Prepare o e-commerce para o pico de tráfego",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Para garantir uma experi&ecirc;ncia de compra boa e evitar problemas t&eacute;cnicos durante o pico de tr&aacute;fego no seu site, &eacute; preciso prepar&aacute;-lo para lidar com esse pico. Voc&ecirc; deve, por exemplo, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>verificar se o servidor em que seu site est&aacute; hospedado tem capacidade suficiente </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">para lidar com o aumento no tr&aacute;fego.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Se n&atilde;o for o caso, considere atualizar para um plano de hospedagem mais robusto ou conversar com seu provedor de hospedagem para garantir que o servidor esteja preparado para lidar com uma carga maior.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Tamb&eacute;m otimize seu site para garantir tempos de carregamento r&aacute;pidos e desempenho eficiente. Com o aumento do tr&aacute;fego, um site lento pode levar &agrave; frustra&ccedil;&atilde;o dos visitantes e ao abandono de carrinhos de compras.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Fa&ccedil;a uma an&aacute;lise de desempenho do seu site, comprima imagens, otimize o c&oacute;digo e minimize solicita&ccedil;&otilde;es de servidor para garantir uma experi&ecirc;ncia &aacute;gil de navega&ccedil;&atilde;o.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Outra dica &eacute; realizar testes de carga no seu site antes da Black Friday para verificar sua capacidade de lidar com o aumento de tr&aacute;fego. Simule um grande n&uacute;mero de visitantes e pedidos simult&acirc;neos para identificar poss&iacute;veis gargalos e problemas de desempenho</span></span></span></p>
      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-varejo/graficos-trafego.jpg"
    },
    {
      title: "8. Deixe seu site pronto para coleta de dados (LGPD)",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O oitavo passo para se preparar para a Black Friday &eacute; deixar o seu site pronto para coleta de dados de acordo com a </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/lgpd/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD)</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A LGPD &eacute; uma lei que regula o tratamento de dados pessoais dos usu&aacute;rios na internet, garantindo os seus direitos de privacidade e seguran&ccedil;a. Ela exige que as empresas sejam transparentes e respons&aacute;veis ao coletar, armazenar e usar os dados dos seus clientes</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Portanto, todos os sites precisam ter uma pol&iacute;tica de privacidade clara, completa e acess&iacute;vel no seu site. Essa </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>pol&iacute;tica de LGPD no varejo deve informar os visitantes sobre quais dados s&atilde;o coletados, como s&atilde;o usados, compartilhados e armazenados</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, al&eacute;m dos seus direitos.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Garanta que a coleta de dados pessoais no seu site seja feita com o consentimento expl&iacute;cito do usu&aacute;rio. Isso significa que os visitantes devem ter a op&ccedil;&atilde;o de consentir ou n&atilde;o com a coleta e processamento de seus dados, com uma forma clara e espec&iacute;fica de consentimento.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Tamb&eacute;m ofere&ccedil;a aos usu&aacute;rios controles de privacidade claros e f&aacute;ceis de usar. Isso pode incluir op&ccedil;&otilde;es para gerenciar as prefer&ecirc;ncias de cookies, escolher como os dados ser&atilde;o compartilhados ou solicitar a exclus&atilde;o de informa&ccedil;&otilde;es pessoais.</span></span></span><br />
      <span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Por &uacute;ltimo, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>implemente medidas de seguran&ccedil;a para proteger os dados coletados:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> criptografia de dados, o uso de firewalls, a ado&ccedil;&atilde;o de boas pr&aacute;ticas de seguran&ccedil;a e a limita&ccedil;&atilde;o do acesso aos dados apenas a pessoal autorizado.</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "9. Invista em um bom atendimento",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Durante a Black Friday, mesmo no e-commerce, oferecer um atendimento excepcional ao cliente &eacute; essencial. &Eacute; isso que garante a satisfa&ccedil;&atilde;o dos compradores e diferencia sua empresa da concorr&ecirc;ncia. Considerando isso, lembre-se que o tempo de resposta &eacute; crucial. Procure responder prontamente &agrave;s consultas dos clientes, seja por telefone, e-mail ou nas redes sociais.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>Priorize a efici&ecirc;ncia e a resolu&ccedil;&atilde;o dos problemas de forma &aacute;gil</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, garantindo uma experi&ecirc;ncia positiva para o cliente. Fa&ccedil;a um esfor&ccedil;o tamb&eacute;m para personalizar o atendimento ao cliente. Por exemplo, voc&ecirc; pode usar o hist&oacute;rico de compras e prefer&ecirc;ncias dos clientes para oferecer recomenda&ccedil;&otilde;es personalizadas e promo&ccedil;&otilde;es especiais.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">N&atilde;o esque&ccedil;a de oferecer op&ccedil;&otilde;es de contato variadas para os clientes, como telefone, e-mail, chat ao vivo e redes sociais. Dessa forma, eles podem escolher o canal mais conveniente para eles.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Por &uacute;ltimo: </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>esteja pronto tamb&eacute;m para lidar com reclama&ccedil;&otilde;es de forma profissional e emp&aacute;tica </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">e resolver os problemas dos clientes de maneira justa e transparente. &Eacute; preciso sempre buscar a satisfa&ccedil;&atilde;o do cliente e a resolu&ccedil;&atilde;o efetiva da quest&atilde;o.</span></span></span></p>
      
      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-varejo/atendimento.jpg"
    },
    {
      title: "10. Oferecer ofertas atraentes",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O d&eacute;cimo passo para se preparar para a Black Friday &eacute; oferecer ofertas atraentes para o seu e-commerce.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">As ofertas s&atilde;o um dos principais atrativos para os clientes que buscam aproveitar a Black Friday para comprar produtos com pre&ccedil;os mais baixos. Logo, saber criar ofertas que sejam realmente vantajosas &eacute; uma estrat&eacute;gia eficaz.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">No contexto do e-commerce, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>voc&ecirc; pode oferecer descontos reais e significativos nos produtos ou servi&ccedil;os que voc&ecirc; est&aacute; promovendo</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Quanto maior for o desconto, mais atraente ser&aacute; a oferta para os clientes. Considere redu&ccedil;&otilde;es de pre&ccedil;os percentuais ou descontos fixos que sejam percebidos como vantajosos pelos consumidores.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Outra op&ccedil;&atilde;o &eacute; criar promo&ccedil;&otilde;es exclusivas para a Black Friday, oferecendo vantagens adicionais aos clientes que realizarem compras durante o evento. Isso pode incluir brindes, frete gr&aacute;tis, cupons de desconto adicionais ou acesso antecipado a produtos em lan&ccedil;amento.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Pacotes especiais que oferecem</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> combina&ccedil;&otilde;es de produtos ou servi&ccedil;os complementares a pre&ccedil;os promocionais </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">tamb&eacute;m s&atilde;o uma sugest&atilde;o. Eles podem atrair a aten&ccedil;&atilde;o dos clientes que desejam obter mais valor pelo seu dinheiro</span></span></span></p>
      
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "11. Crie uma estratégia de marketing",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Uma estrat&eacute;gia de marketing bem planejada e executada &eacute; essencial para o sucesso durante a Black Friday para o e-commerce. Ou seja, n&atilde;o pense apenas nas promo&ccedil;&otilde;es, mas tamb&eacute;m na forma que vai divulgar a sua campanha. Voc&ecirc; deve come&ccedil;ar sua estrat&eacute;gia entendendo </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>quem s&atilde;o seus clientes ideais e quais s&atilde;o seus h&aacute;bitos de consumo</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Isso permitir&aacute; que voc&ecirc; direcione suas mensagens de marketing de forma mais precisa e relevante. Tamb&eacute;m &eacute; bom usar ferramentas de ecommerce, ou seja, online, para an&aacute;lise de dados para obter informa&ccedil;&otilde;es sobre seu p&uacute;blico-alvo e segment&aacute;-lo adequadamente.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Al&eacute;m disso, uma forma de driblar a grande concorr&ecirc;ncia &eacute; criando uma identidade visual atraente e consistente em todos os materiais de marketing, incluindo banners, e-mails, posts nas redes sociais e an&uacute;ncios pagos.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">&Eacute; interessante</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> apostar em uma variedade de canais de marketing para ampliar sua mensagem</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">. Isso pode incluir e-mail marketing, an&uacute;ncios pagos nas redes sociais, publicidade em m&iacute;dia online, SMS marketing, entre outros.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Outro ponto crucial &eacute; criar um senso de urg&ecirc;ncia. A Black Friday &eacute; conhecida por suas ofertas por tempo limitado. Utilize frases persuasivas para criar um senso de urg&ecirc;ncia nos consumidores. Isso incentivar&aacute; a a&ccedil;&atilde;o imediata e pode aumentar a convers&atilde;o de vendas</span></span></span></p>
      
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "12. Acompanhar e analisar os resultados",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">No final de tudo, &eacute; preciso acompanhar e analisar os resultados. Essa avalia&ccedil;&atilde;o fornece insights valiosos sobre o desempenho das suas a&ccedil;&otilde;es e permite que voc&ecirc; fa&ccedil;a ajustes e melhorias para obter melhores resultados, da mesma forma, no ano seguinte.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Para isso,</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> identifique as principais m&eacute;tricas que ir&atilde;o medir o desempenho da sua campanha</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">. Isso pode incluir o n&uacute;mero de vendas, o tr&aacute;fego do site, a taxa de convers&atilde;o, o engajamento nas redes sociais ou qualquer outra m&eacute;trica relevante para os seus objetivos</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Use de ferramentas de an&aacute;lise, como o Google Analytics, para acompanhar o desempenho do seu site, como o n&uacute;mero de visitantes, as p&aacute;ginas mais visitadas e as taxas de convers&atilde;o.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Outra dica &eacute;</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> fazer testes A/B para comparar diferentes vers&otilde;es de suas p&aacute;ginas de destino</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, an&uacute;ncios ou e-mails promocionais. Isso permite identificar quais vari&aacute;veis est&atilde;o gerando melhores resultados. Teste elementos como t&iacute;tulos, imagens, chamadas para a&ccedil;&atilde;o e ofertas para otimizar o desempenho da sua campanha.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Em &uacute;ltimo lugar, monitore o feedback dos clientes. Voc&ecirc; pode verificar coment&aacute;rios e avalia&ccedil;&otilde;es nas redes sociais, em sites de avalia&ccedil;&atilde;o e por meio de pesquisas de satisfa&ccedil;&atilde;o. Essas informa&ccedil;&otilde;es podem fornecer insights valiosos sobre a experi&ecirc;ncia do cliente e ajudar a identificar pontos de melhoria.</span></span></span></p>
      
      `,
      imagePosition: "right",
      imageUrl: "../../../../../assets/images/page-varejo/analise-resultados.jpg"
    },
    {
      title: "Qual a importância do e-commerce estar adequado à LGPD para a Black Friday?",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A adequa&ccedil;&atilde;o do e-commerce &agrave; Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD) &eacute; de extrema import&acirc;ncia durante a Black Friday &mdash; assim como em qualquer momento do ano. </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>Durante esse per&iacute;odo, as empresas coletam uma grande quantidade de dados pessoais dos consumidores</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, como nome, endere&ccedil;o, e-mail e informa&ccedil;&otilde;es de pagamento.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Esses dados s&atilde;o usados para processar as compras, fornecer suporte ao cliente e realizar a&ccedil;&otilde;es de marketing. No entanto, &eacute; essencial garantir que essas informa&ccedil;&otilde;es sejam tratadas de forma segura e em conformidade com a legisla&ccedil;&atilde;o vigente.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Vale lembrar que </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>o n&atilde;o cumprimento da LGPD pode resultar em penalidades significativas para as empresas</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, incluindo multas e san&ccedil;&otilde;es. Ou seja, as empresas t&ecirc;m a obriga&ccedil;&atilde;o de estarem em conformidade com a legisla&ccedil;&atilde;o para evitar poss&iacute;veis consequ&ecirc;ncias legais.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Ao mesmo tempo, quando se demonstra um compromisso com a privacidade e a prote&ccedil;&atilde;o dos dados pessoais dos consumidores, as empresas constroem confian&ccedil;a com seus clientes.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Isso acaba sendo especialmente importante durante a Black Friday, quando</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> os consumidores podem estar mais atentos &agrave; </strong></span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/seguranca-da-informacao/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><strong><u>seguran&ccedil;a das informa&ccedil;&otilde;es</u></strong></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> devido ao aumento das transa&ccedil;&otilde;es online.</strong></span></span></span></p>
      
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "4 ferramentas para usar na Black Friday ",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#222222">Pensando ainda na Black Friday para quem atua no com&eacute;rcio eletr&ocirc;nico, existem algumas ferramentas para e-commerce do </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/modernizar-aplicacoes-gcp/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#222222"> que podem potencializar a opera&ccedil;&atilde;o da empresa.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Esse &eacute; um per&iacute;odo movimentado para as empresas e algumas solu&ccedil;&otilde;es podem ajudar a otimizar processos, melhorar a comunica&ccedil;&atilde;o interna e impulsionar o sucesso das vendas. Entenda melhor a seguir.</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Google Sheets para controle de estoque",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/novas-funcionalidades-do-google-planilhas/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Sheets</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> &eacute; uma excelente ferramenta para gerenciar o estoque durante a Black Friday. Com ele, voc&ecirc; pode </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>criar planilhas personalizadas para rastrear o invent&aacute;rio</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, monitorar as vendas e controlar o fluxo de produtos.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O compartilhamento em tempo real facilita o acesso e a colabora&ccedil;&atilde;o entre os membros da equipe, permitindo que todos estejam atualizados sobre a disponibilidade dos produtos.</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Google Workspace para e-mails de promoções",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">J&aacute; o </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/valor-google-workspace-negocio/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Workspace</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> oferece uma su&iacute;te de aplicativos, incluindo o Gmail, que &eacute; uma poderosa ferramenta para enviar e-mails de promo&ccedil;&otilde;es durante a Black Friday.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Com recursos avan&ccedil;ados de personaliza&ccedil;&atilde;o, voc&ecirc; pode </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>criar campanhas de e-mail marketing direcionadas e segmentadas para seus clientes</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, aumentando o engajamento e as chances de convers&atilde;o.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">&nbsp;Al&eacute;m disso, o Google Workspace oferece recursos de produtividade adicionais, como agenda compartilhada e armazenamento na nuvem</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Google Apresentações para treinamento de times",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Durante a Black Friday, como dito, &eacute; fundamental que a equipe esteja bem treinada e alinhada para oferecer um atendimento eficiente e de qualidade. O </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/5-truques-para-fazer-uma-apresentacao-campea-no-google-apresentacoes/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Apresenta&ccedil;&otilde;es</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> &eacute; uma ferramenta ideal para</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> criar materiais de treinamento visualmente atrativos e interativos</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Voc&ecirc; pode compartilhar as apresenta&ccedil;&otilde;es com os membros da equipe, permitindo que eles acessem as informa&ccedil;&otilde;es e participem do treinamento de forma colaborativa. Inclusive, se for remoto, pode fazer isso ao vivo, pelo </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/google-meet-entenda-como-funciona/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Meet</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.</span></span></span></p>
      `,
      imagePosition: "left",
      imageUrl: "../../../../../assets/images/page-varejo/dois-jovens-empresario-tendo-uma-reuniao-bem-sucedida-no-restaurante.jpg"
    },
    {
      title: "Google Chat para alinhamento interno",
      description: `
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">O </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/video-chat-e-video-conferencias-apps-show/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Chat</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> &eacute; uma ferramenta de comunica&ccedil;&atilde;o instant&acirc;nea que facilita o alinhamento interno durante a Black Friday.&nbsp;</span></span></span> <br/><br/>
      <span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Com ele, voc&ecirc; pode </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>criar grupos de chat espec&iacute;ficos para diferentes equipes ou departamentos, agilizando a comunica&ccedil;&atilde;o </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">e a troca de informa&ccedil;&otilde;es importantes. Atrav&eacute;s do chat, os membros da equipe podem compartilhar atualiza&ccedil;&otilde;es, discutir estrat&eacute;gias e resolver quest&otilde;es rapidamente, mantendo todos na mesma p&aacute;gina.</span></span></span></p>
      `,
      imagePosition: "",
      imageUrl: ""
    },
    {
      title: "Como a Qi Network pode ajudar?",
      description: `<p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">A Qi Network &eacute; uma empresa especializada em solu&ccedil;&otilde;es de tecnologia em nuvem que pode oferecer um amplo suporte aos neg&oacute;cios. Empresas do varejo podem inovar com</span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong> solu&ccedil;&otilde;es em nuvem do Google Cloud </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">e aproveitar isso tanto na Black Friday como fora dela.</span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Somos uma </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>parceira Premier do Google Cloud no Brasil</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">, sendo reconhecida por ela como uma </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/especialista-infraestrutura/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>especialista em Servi&ccedil;os de Infraestrutura</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> e em Transforma&ccedil;&atilde;o do Trabalho.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">S&atilde;o servi&ccedil;os de implementa&ccedil;&atilde;o, migra&ccedil;&atilde;o, suporte, treinamento e consultoria para as solu&ccedil;&otilde;es de produtividade, intelig&ecirc;ncia de dados e moderniza&ccedil;&atilde;o da infraestrutura do Google Cloud.</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">No final das contas, contar com um parceiro qualificado para implantar as ferramentas em nuvem &eacute; importante para as empresas do varejo porque garante uma transi&ccedil;&atilde;o mais r&aacute;pida, segura e eficiente, evitando erros, perdas ou interrup&ccedil;&otilde;es nos processos.&nbsp;</span></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">Al&eacute;m disso, </span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"><strong>um parceiro qualificado pode oferecer suporte cont&iacute;nuo, treinamento personalizado e consultoria especializada</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000"> para ajudar as empresas a aproveitar ao m&aacute;ximo os benef&iacute;cios das solu&ccedil;&otilde;es em nuvem do </span></span></span><a target="_blank" href="https://blog.qinetwork.com.br/google-cloud-platform/" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><u>Google Cloud</u></span></span></span></a><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#000000">.</span></span></span></p>
      
      
      <p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#222222"><strong><em>Se voc&ecirc; quer trazer inova&ccedil;&atilde;o para sua empresa com a Qi Network, </em></strong></span></span></span><a target="_blank" href="https://www.qinetwork.com.br/contato" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Lato,sans-serif"><span style="color:#1155cc"><strong><em><u>fale com um dos nossos especialistas!</u></em></strong></span></span></span></a></p>
      `,
      imagePosition: "",
      imageUrl: ""
    }
  ]
}