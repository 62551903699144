import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-banco-dados-googlecloud',
  templateUrl: './banco-dados-googlecloud.component.html',
  styleUrls: ['./banco-dados-googlecloud.component.scss']
})
export class BancoDadosGooglecloudComponent implements OnInit {

  constructor(public helper: HelperService) { }

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = true;
    this.helper.ajudaCinza = false;

  }

}
