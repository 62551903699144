import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-certificacoes',
  templateUrl: './certificacoes.component.html',
  styleUrls: ['./certificacoes.component.scss']
})
export class CertificacoesComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  public certificacoes: any[] = [
    {
      img: './assets/images/solucoes/GC-especializacao.png',
      color: '#4e1da6',
      titulo: 'Somos Especialistas em Transformação do Trabalho',
      descricao: 'Sucesso comprovado com um time treinado, testado e certificado em entregas de Google Workspace com proficiência. A Qi Network é certificada como um parceiro Google Cloud especialista em Transformação do Trabalho, um título que poucas empresas no mundo conquistaram junto à Google.',
      adm: true
    },
    {
      img: './assets/images/solucoes/GC-infraestrutura.png',
      color: '#5a9fff',
      titulo: 'Somos Especialistas em Modernização de Infra',
      descricao: 'A Qi Network agora é certificada pelo Google Cloud como Parceira Especialista em Serviços de Infraestrutura,título que comprova experiência e sucesso em apoiar clientes na construção de arquiteturas inteligentes, projetos de implementação, automação e gestão de infraestrutura e serviços na nuvem do Google. Esse é um selo global, onde apenas parceiros especializados, auditados e com grandes casos de sucesso, conseguem adquirir.',
    }
  ]

  public administrator = {
    img: './assets/images/solucoes/GC-workspace.png',
    color: '#102542',
    titulo: 'Google Workspace Administrator',
    descricao: 'Um administrador profissional do Google Workspace transforma os objetivos de negócios em configurações, políticas e práticas de segurança tangíveis relacionadas a usuários, conteúdo e integrações.',
  }

  ngOnInit() {
  }
  public smooth(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }
}
