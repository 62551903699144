import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-saude',
  templateUrl: './saude.component.html',
  styleUrls: ['../verticais.component.scss']
})
export class SaudeComponent implements OnInit {

  constructor(public helper: HelperService) { }

  public cases: any[] = [
    {
      id:1,
      img: './assets/images/Verticais/img-grupo-3778.png',
      alt: 'grupo-3778',
      link: 'https://sucesso.qinetwork.com.br/case-grupo-3778'
    },
    {
      id:2,
      img: './assets/images/Verticais/img-cases-hospital-felicio-rocho.png',
      alt: 'hospital-felicio-rocho',
      link: 'https://sucesso.qinetwork.com.br/case-felicio-rocho'
    },
    {
      id:3,
      img: './assets/images/Verticais/img-cases-uniodonto.png',
      alt: 'uniodonto',
      link: 'https://sucesso.qinetwork.com.br/case-uniodonto-sc'
    }
  ];

  ngOnInit() {
  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth", block: "start", inline: "end"});
  }

}
