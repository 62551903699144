import { Component, OnInit, SimpleChanges } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.scss']
})
export class AjudaComponent implements OnInit {
  linkSaude: boolean;


  constructor(
    public helper: HelperService,
  ) { }

  ngDoCheck() {
    this.linkSaude = window.location.pathname == '/verticais/saude';
  }

  ngOnInit() {

  }

}
