import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-google-workspace',
  templateUrl: './google-workspace.component.html',
  styleUrls: ['./google-workspace.component.scss']
})
export class GoogleWorkspaceComponent implements OnInit {
public teste:boolean;
  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;

  }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({behavior:"smooth"});
  }

}
