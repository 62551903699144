import { Component, OnInit } from "@angular/core";
import { HelperService } from "src/app/service/helper.service";

@Component({
  selector: "cta-produtividade-colaboracao",
  templateUrl: "./cta-produtividade-colaboracao.component.html",
  styleUrls: ["./cta-produtividade-colaboracao.component.scss"],
})
export class CtaProdutividadeColaboracaoComponent implements OnInit {
  constructor(public helper: HelperService) {}

  ngOnInit() {}
}
