import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-jornada-produtividade',
  templateUrl: './jornada-produtividade.component.html',
  styleUrls: ['./jornada-produtividade.component.scss']
})
export class JornadaProdutividadeComponent implements OnInit {
  public titulo = true;
  public subsidios = [
    "Onboarding da Jornada",
    "Descoberta Técnico do Projeto",
    "Análise e Orientação das alternativas de projetos",
    "Análise e Orientação das Políticas de Segurança",
    "Análise e Orientação do Projeto de Adoção de Solução",
    "Planejamento de Implementação GWS",
    "Planejamento de Implementação Segurança",
    "Orçamento detalhado do Projeto",
    "Desconto DV Google Projeto Completo",
    "Subsídio Qi Apps (Qi Academy) mesmo período de contrato GWS",
    "Subsídio Qi Apps (Gestor de Assinaturas) mesmo período de contrato GWS",
    "Trial Gemini até 30 dias",
    "Treinamento Online 1h Gemini",
    "*Projetos com migração de 30 á 60 dias",
    "Até 20% desconto nas licenças do GWS"
  ];

  constructor( 
    public helper: HelperService,
    public meta: Meta,
    public title: Title
  ) { }
  
  ngOnInit() {
    this.helper.hasNav = true;
    this.helper.pagina = 'novos';
    if (this.helper.isMobile === true) {
      this.titulo = false;
    }
    this.title.setTitle('Qi Network - Jornada de Produtividade e Colaboração');
    this.meta.updateTag({ name: 'description', content: 'A Jornada de Produtividade & Colaboração faz parte do Programa de Inovação na Nuvem.' });
    this.meta.updateTag({ name: 'keywords', content: 'qinetwork, lgdp, google cloud, etapas, qi cloud, produtividade'});
    this.meta.updateTag({ name: 'og:title', content: 'Qi Network - Jornada de Produtividade e Colaboração' });
  }

}
