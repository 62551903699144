import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-selo',
  templateUrl: './selo.component.html',
  styleUrls: ['./selo.component.scss']
})
export class SeloComponent implements OnInit {
  @Input() cor = '#102542';

  constructor() { }

  ngOnInit() {
    
  }

}
