import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-sap-googlecloud',
  templateUrl: './sap-googlecloud.component.html',
  styleUrls: ['./sap-googlecloud.component.scss']
})
export class SapGooglecloudComponent implements OnInit {

  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {

    this.helper.jInfra = true;
    this.helper.jProdutividade = false;
    this.helper.jInteligencia = false;
    this.helper.ajudaCinza = false;

  }

}
