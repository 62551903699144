import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-gestor-assinatura',
  templateUrl: './gestor-assinatura.component.html',
  styleUrls: ['./gestor-assinatura.component.scss']
})
export class GestorAssinaturaComponent implements OnInit {
  fixedPrice: boolean;
  cardPlanoFinalExibindo: boolean;

  constructor(
    private dom: DomSanitizer,
    public helper: HelperService
  ) { }

  public video;

  public designAssinaturas: any[] = [
    {
      id: 1,
      descricao: 'Criação e edição de assinatura',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 2,
      descricao: 'Foto do usuário integrada ao Google Workspace',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 3,
      descricao: 'Informações do domínio (logo, site, redes sociais)',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 4,
      descricao: 'Uso de até 25 tags de informações do email',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 5,
      descricao: 'Pré-visualização da assinatura',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 6,
      descricao: 'Edição de Assinatura em HTML',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    }
  ]

  public gerenciamentoCentralizado: any[] = [
    {
      id: 0,
      descricao: 'Listagem de todas as assinaturas',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 1,
      descricao: 'Lista de todos os emails do domínio',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 2,
      descricao: 'Listagem de todos os grupos/departamentos do domínio',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 3,
      descricao: 'Listagem de todas as unidades organizacionais do domínio',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/unchecked.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 4,
      descricao: 'Atualizar assinatura por usuário do domínio',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 5,
      descricao: 'Atualizar assinaturas por grupo de usuários',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 6,
      descricao: 'Atualizar assinaturas por unidade organizacional',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/unchecked.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 7,
      descricao: 'Atualizar assinatura para os emails selecionados na lista',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 8,
      descricao: 'Pesquisar na lista de emails por nome e email',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 9,
      descricao: 'Pesquisar em grupos/departamentos por nome e email',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 10,
      descricao: 'Pesquisar na lista de unidades organizacionais por nome',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/unchecked.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 11,
      descricao: 'Pesquisar na lista de assinaturas por nome',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 12,
      descricao: 'Remover assinatura para os emails selecionados na lista',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    }
  ]

  public integracao: any[] = [
    {
      id: 1,
      descricao: 'Google Workspace Painel de Controle',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 2,
      descricao: 'Google Directory',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    }
  ]

  public perfilUsuario: any[] = [
    {
      id: 1,
      descricao: 'Perfil de Administrador do Painel Workspace',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/i-checked-vermelho.svg',
      imgBasic: './assets/icons/solucoes/i-checked-laranja.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 2,
      descricao: 'Perfil de Designer de Assinaturas',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/unchecked.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    },
    {
      id: 3,
      descricao: 'Perfil de Financeiro',
      imgTrial: './assets/icons/solucoes/i-checked-azul.svg',
      imgFree: './assets/icons/solucoes/unchecked.svg',
      imgBasic: './assets/icons/solucoes/unchecked.svg',
      imgBusiness: './assets/icons/solucoes/i-checked-roxo.svg'
    }
  ]

  public depoimentos: any[] = [
    {
      id: 1,
      video: this.dom.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Cm21I6EvZmA'),
      descricao: '“A parceria com a Qi Network tem sido de muito sucesso, tem demonstrado um grande potencial e um arcabouço de conhecimento em transformação digital, em design thinking e em como transformar as empresas. Sem dúvidas a Qi pra gente é uma grande referência, um grande parceiro“',
      nome: 'Gabriel Carvalho',
      cargo: 'Executivo Google Cloud'
    },
    {
      id: 2,
      video:  this.dom.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ccclyD4e1yI'),
      descricao: '“A parceria com a Qi Network tem sido de muito sucesso, tem demonstrado um grande potencial e um arcabouço de conhecimento em transformação digital, em design thinking e em como transformar as empresas. Sem dúvidas a Qi pra gente é uma grande referência, um grande parceiro“',
      nome: 'Sidnei Barbosa | Gerente de TI',
      cargo: 'Seculus MG'
    }
  ];

  public activeDepoimentos: any = {};
  depoimentosConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    infinite: true,
    dots: true,
    cssEase: 'linear'
  }

  public Slide(slide: any) {
    console.log(slide);
    this.activeDepoimentos = slide;
    this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);
  }

  ngOnInit() {

    this.activeDepoimentos = this.depoimentos[0];
    this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);

    this.helper.jInfra = false;
    this.helper.jProdutividade = true;
    this.helper.jInteligencia = false;

  }

  @ViewChild('cardPlanoFinal', { static: false }) elementoRef: ElementRef;

  @ViewChild('cardPlanoInicio', { static: false }) elementoRef2: ElementRef;

  ngAfterViewInit() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.cardPlanoFinalExibindo = entry.isIntersecting
        if(entry.isIntersecting){
          this.fixedPrice = false;
        }
      });
    });

    observer.observe(this.elementoRef.nativeElement);
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if(scrollPosition > 4600 && !this.cardPlanoFinalExibindo && scrollPosition < 7300){
      this.fixedPrice = true;
    }else{
      this.fixedPrice = false;
    }
  }


  slickInit(e) {
    console.log('slick initialized');
  }
  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    this.activeDepoimentos = this.depoimentos[e.currentSlide];
    this.video = this.dom.bypassSecurityTrustResourceUrl(this.activeDepoimentos.video);
  }
  beforeChange(e) { }

  public smooth(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }


}
